import React, {
  FC, memo, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';

import {
  ButtonPlus,
  Divider,
  Option,
  TypeSwitch,
} from 'app-wrapper/view/components';
import { ICountryListDTM } from 'app-wrapper/models/dtm/CountryList.dtm';

import {
  ICompanyAddressDTM,
  IContactBookUpdateStateAddressesErrorsDTM,
} from 'user-management/models/dtm';

import { OrganizationStateSelectComponent } from 'user-management/view/components/OrganizationStateSelect';
import {
  ContactsAddressBlock,
  ContactsAddressBlockDeleteButton,
  ContactsAddressBlockFirst,
  ContactsAddressBlockFirstInput,
  ContactsAddressBlockFirstTitle,
  ContactsAddressBlockFourth,
  ContactsAddressBlockName,
  ContactsAddressBlockNameWrapper,
  ContactsAddressBlockSecond,
  ContactsAddressBlockSecondCountry,
  ContactsAddressBlockSecondCountryWrapper,
  ContactsAddressBlockSecondInputDiv,
  ContactsAddressBlockSecondStateWrapper,
  ContactsAddressBlockSecondTitle,
  ContactsAddressBlockSecondTitleDiv,
  ContactsAddressBlockSecondTitleSecond,
  ContactsAddressBlockThird,
  ContactsAddressBlockThirdCity,
  ContactsAddressBlockThirdCityWrapper,
  ContactsAddressBlockThirdInputDiv,
  ContactsAddressBlockThirdPostalCode,
  ContactsAddressBlockThirdPostalCodeWrapper,
  ContactsAddressBlockThirdTitle,
  CBContentBodyFormPrimary,
  CBDividerMargins,
} from './ContactsAddressBlock.styled';

interface IContactsAddressBlockProps {
  largeInput: boolean
  countriesList: ICountryListDTM[]
  address?: ICompanyAddressDTM
  addressIndex: number
  companyListStateIndex: string
  isFirst: boolean
  isLast: boolean
  hideDivider?: boolean
  errors?: IContactBookUpdateStateAddressesErrorsDTM
  primaryComponent?: React.ReactNode
  onChangeAddressLineFirst: (indexAddresses: string) => (addressLineFirst: string) => void
  onBlurAddressLineFirst: (indexAddresses: string) => () => void
  onFocusAddressLineFirst: (indexAddresses: string) => () => void
  onChangeAddressLineSecond: (indexAddresses: string) => (addressLineSecond: string) => void
  onBlurAddressLineSecond: (indexAddresses: string) => () => void
  onFocusAddressLineSecond: (indexAddresses: string) => () => void
  onChangeCountry: (indexAddresses: string) => (country: string) => void
  onBlurCountry: (indexAddresses: string) => () => void
  onFocusCountry: (indexAddresses: string) => () => void
  onClearCountry: (indexAddresses: string) => () => void
  onChangeState: (indexAddresses: string) => (state: string) => void
  onChangeIsPrimary?: (indexAddresses: string) => (isPrimary: boolean) => void
  onBlurState: (indexAddresses: string) => () => void
  onClearState: (indexAddresses: string) => () => void
  onFocusState: (indexAddresses: string) => () => void
  onChangeCity: (indexAddresses: string) => (city: string) => void
  onBlurCity: (indexAddresses: string) => () => void
  onFocusCity: (indexAddresses: string) => () => void
  onChangePostalCode: (indexAddresses: string) => (postalCode: string) => void
  onBlurPostalCode: (indexAddresses: string) => () => void
  onFocusPostalCode: (indexAddresses: string) => () => void
  addAddressItem: (indexAddresses: string) => () => void
  onRemoveAddress: (indexList: string, indexValue: string) => () => void
  showPrimaryAsSwitch?: boolean;
  isAddressBlocked?: boolean;
}

const ContactsAddressBlockComponent: FC<IContactsAddressBlockProps> = (props) => {
  const {
    largeInput,
    countriesList,
    address,
    addressIndex,
    companyListStateIndex,
    isFirst,
    isLast,
    hideDivider,
    errors,
    primaryComponent,
    onChangeAddressLineFirst,
    onBlurAddressLineFirst,
    onFocusAddressLineFirst,
    onChangeAddressLineSecond,
    onBlurAddressLineSecond,
    onFocusAddressLineSecond,
    onChangeIsPrimary,
    onChangeCountry,
    onBlurCountry,
    onClearCountry,
    onFocusCountry,
    onChangeState,
    onClearState,
    onBlurState,
    onFocusState,
    onChangeCity,
    onBlurCity,
    onFocusCity,
    onChangePostalCode,
    onBlurPostalCode,
    onFocusPostalCode,
    addAddressItem,
    onRemoveAddress,
    showPrimaryAsSwitch,
    isAddressBlocked,
  } = props;
  const { t } = useTranslation();

  const addressId = address?.customId || '';

  const onChangeHandlerAddressLineFirst = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeAddressLineFirst(addressId)(event.target.value);
    },
    [onChangeAddressLineFirst, addressId],
  );

  const hasErrorAddressLineFirst = useMemo(() => {
    const errDocument = errors?.addressLineFirst;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [errors]);

  const onChangeHandlerAddressLineSecond = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeAddressLineSecond(addressId)(event.target.value);
    },
    [onChangeAddressLineSecond, addressId],
  );

  const hasErrorAddressLineSecond = useMemo(() => {
    const errDocument = errors?.addressLineSecond;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [errors]);

  const onChangeHandlerCountry = useCallback(
    (value: string) => {
      onChangeCountry(addressId)(value);
    },
    [onChangeCountry, addressId],
  );

  const handleIsPrimaryChange = useCallback((isPrimary: boolean) => {
    if (onChangeIsPrimary) {
      onChangeIsPrimary(addressId)(isPrimary);
    }
  }, [onChangeIsPrimary, addressId]);

  const hasErrorCountry = useMemo(() => {
    const errDocument = errors?.country;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [errors]);

  const onChangeHandlerCity = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeCity(addressId)(event.target.value);
    },
    [onChangeCity, addressId],
  );

  const hasErrorCity = useMemo(() => {
    const errDocument = errors?.city;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [errors]);

  const onChangeHandlerState = useCallback(
    (value: string) => {
      onChangeState(addressId)(value);
    },
    [onChangeState, addressId],
  );

  const hasErrorState = useMemo(() => {
    const errDocument = errors?.state;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [errors]);

  const onChangeHandlerPostalCode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangePostalCode(addressId)(event.target.value);
    },
    [onChangePostalCode, addressId],
  );

  const hasErrorPostalCode = useMemo(() => {
    const errDocument = errors?.postalCode;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [errors]);

  const optionsDataCountry = useMemo(() => countriesList.map((itemList) => ({
    code: itemList.code,
    description: itemList.name,
  })), [countriesList]);

  return (
    <ContactsAddressBlock
      key={`ContactsAddressBlock_${addressId}`}
    >
      <ContactsAddressBlockNameWrapper>
        <ContactsAddressBlockName>
          {`${t('Address')}`}

          {showPrimaryAsSwitch ? (
            <TypeSwitch
              onChange={handleIsPrimaryChange}
              rightText={t('Primary')}
              checkedChildren="On"
              unCheckedChildren="Off"
              value={!!address?.isPrimary}
              disabledbg="true"
            />
          ) : (
            <>
              {addressIndex > 0
                ? ` #${addressIndex + 1}`
                : (primaryComponent || <CBContentBodyFormPrimary>{t('Primary')}</CBContentBodyFormPrimary>)}
            </>
          )}
        </ContactsAddressBlockName>
        {!isFirst && (
          <ContactsAddressBlockDeleteButton
            size="small"
            type="dashed"
            icon={<DeleteOutlined />}
            onClick={onRemoveAddress(companyListStateIndex, addressId)}
          >
            {t('Remove')}
          </ContactsAddressBlockDeleteButton>
        )}
      </ContactsAddressBlockNameWrapper>

      <ContactsAddressBlockFirst>
        <ContactsAddressBlockFirstTitle>
          {`${t('AddressLine')} ${t('requiredSymbol')}`}
        </ContactsAddressBlockFirstTitle>
        <ContactsAddressBlockFirstInput
          data-class="ContactsAddress1Input"
          largeInput={largeInput}
          value={address?.address1 || ''}
          hasError={hasErrorAddressLineFirst}
          onChange={onChangeHandlerAddressLineFirst}
          onBlur={onBlurAddressLineFirst(addressId)}
          onFocus={onFocusAddressLineFirst(addressId)}
          disabled={isAddressBlocked}
        />
      </ContactsAddressBlockFirst>

      <ContactsAddressBlockFirst>
        <ContactsAddressBlockFirstTitle>
          {`${t('AddressLine')} 2`}
        </ContactsAddressBlockFirstTitle>
        <ContactsAddressBlockFirstInput
          data-class="ContactsAddress2Input"
          largeInput={largeInput}
          value={address?.address2 || ''}
          hasError={hasErrorAddressLineSecond}
          onChange={onChangeHandlerAddressLineSecond}
          onBlur={onBlurAddressLineSecond(addressId)}
          onFocus={onFocusAddressLineSecond(addressId)}
        />
      </ContactsAddressBlockFirst>

      <ContactsAddressBlockSecond>
        <ContactsAddressBlockSecondInputDiv>
          <ContactsAddressBlockSecondCountryWrapper>
            <ContactsAddressBlockSecondTitle>
              {`${t('Country')} ${t('requiredSymbol')}`}
            </ContactsAddressBlockSecondTitle>
            <ContactsAddressBlockSecondCountry
              largeInput={largeInput}
              value={address?.country || null}
              hasError={hasErrorCountry}
              showSearch
              allowClear={!!address?.country}
              onChange={onChangeHandlerCountry}
              onClear={onClearCountry(addressId)}
              onBlur={onBlurCountry(addressId)}
              onFocus={onFocusCountry(addressId)}
              optionFilterProp="children"
              disabled={isAddressBlocked}
            >
              {optionsDataCountry.map((item) => (
                <Option value={item.code} key={`CInfCompanySelect_${item.code}`}>
                  {item.description}
                </Option>
              ))}
            </ContactsAddressBlockSecondCountry>
          </ContactsAddressBlockSecondCountryWrapper>
          <ContactsAddressBlockSecondStateWrapper>
            <ContactsAddressBlockSecondTitleDiv>
              <ContactsAddressBlockSecondTitle>
                {`${t('State')}`}
              </ContactsAddressBlockSecondTitle>
              <ContactsAddressBlockSecondTitleSecond>
                {`${t('US')} ${t('Required')}`}
              </ContactsAddressBlockSecondTitleSecond>
            </ContactsAddressBlockSecondTitleDiv>
            <OrganizationStateSelectComponent
              largeInput={largeInput}
              value={address?.state}
              allowClear={!!address?.state}
              hasError={hasErrorState}
              showSearch
              placeholder={t('Please select')}
              country={address?.country}
              onChange={onChangeHandlerState}
              onClear={onClearState(addressId)}
              onBlur={onBlurState(addressId)}
              onFocus={onFocusState(addressId)}
              disabled={isAddressBlocked}
            />
          </ContactsAddressBlockSecondStateWrapper>
        </ContactsAddressBlockSecondInputDiv>
      </ContactsAddressBlockSecond>

      <ContactsAddressBlockThird>
        <ContactsAddressBlockThirdInputDiv>
          <ContactsAddressBlockThirdCityWrapper>
            <ContactsAddressBlockThirdTitle>
              {`${t('City')} ${t('requiredSymbol')}`}
            </ContactsAddressBlockThirdTitle>
            <ContactsAddressBlockThirdCity
              largeInput={largeInput}
              value={address?.city || ''}
              hasError={hasErrorCity}
              onChange={onChangeHandlerCity}
              onBlur={onBlurCity(addressId)}
              onFocus={onFocusCity(addressId)}
              disabled={isAddressBlocked}
            />
          </ContactsAddressBlockThirdCityWrapper>
          <ContactsAddressBlockThirdPostalCodeWrapper>
            <ContactsAddressBlockThirdTitle>
              {`${t('Post Code')} ${t('requiredSymbol')}`}
            </ContactsAddressBlockThirdTitle>
            <ContactsAddressBlockThirdPostalCode
              largeInput={largeInput}
              value={address?.postalCode || ''}
              hasError={hasErrorPostalCode}
              onChange={onChangeHandlerPostalCode}
              onBlur={onBlurPostalCode(addressId)}
              onFocus={onFocusPostalCode(addressId)}
              disabled={isAddressBlocked}
            />
          </ContactsAddressBlockThirdPostalCodeWrapper>
        </ContactsAddressBlockThirdInputDiv>
      </ContactsAddressBlockThird>

      {isLast && (
        <ContactsAddressBlockFourth>
          <ButtonPlus
            name={t('AddAddress')}
            onClick={addAddressItem(companyListStateIndex)}
            disabled={isAddressBlocked}
          />
        </ContactsAddressBlockFourth>
      )}

      {hideDivider
        ? null
        : <Divider dashed style={CBDividerMargins} />}

    </ContactsAddressBlock>
  );
};

const ContactsAddressBlockComponentCached = memo(ContactsAddressBlockComponent);

export { ContactsAddressBlockComponentCached as ContactsAddressBlockComponent };
