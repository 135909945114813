import { REQUEST_STATUS, SkypaceInformationDTM } from 'app-wrapper/constants';
import { createSelector } from 'reselect';
import countBy from 'lodash/fp/countBy';
import uniqBy from 'lodash/fp/uniqBy';
import partition from 'lodash/fp/partition';
import groupBy from 'lodash/fp/groupBy';
import uniq from 'lodash/fp/uniq';
import moment from 'moment';

import i18n from 'app-wrapper/i18n/i18n';
import { IMOClassTypeNames, IMOClassType } from 'app-wrapper/types/IMOClassType';
import {
  measureByConst,
  occurrenceAdditional, typeFee, priceByBol, priceByContainer,
  ChargeCodeLoadTypeEnum,
} from 'shipment-operations/constants';
import { dateFormatETAmin, parseDurationFromHoursToDays } from 'app-wrapper/utils';
import { RootState } from 'app-wrapper/store';
import {
  EFreightPaymentTerms,
  IFreightQuotaContentSchedulesChargesDTM,
} from 'monetary/models/dtm';
import {
  chargeCodeMeasureBy,
  EFreightIncotermsByExport, EFreightIncotermsByImport, EFreightIncotermsTrade, freightTypeLocation, getCarrierSCACNamesRFQ,
} from 'monetary/constants';

export const getPaymentTerms = (name: string, incoterm?: string, incotermTrade?: string) => {
  switch (name) {
    case 'origin':
      if (incotermTrade === EFreightIncotermsTrade.EXPORT) {
        if (incoterm === EFreightIncotermsByExport.DAP
          || incoterm === EFreightIncotermsByExport.DPU
          || incoterm === EFreightIncotermsByExport.CIF
          || incoterm === EFreightIncotermsByExport.CFR) {
          return EFreightPaymentTerms.PREPAID;
        }
      }

      if (incotermTrade === EFreightIncotermsTrade.IMPORT) {
        if (incoterm === EFreightIncotermsByImport.EXW) {
          return EFreightPaymentTerms.COLLECT;
        }
        if (incoterm === EFreightIncotermsByImport.FCA || incoterm === EFreightIncotermsByImport.FAS || incoterm === EFreightIncotermsByImport.FOB) {
          return EFreightPaymentTerms.PREPAID;
        }
      }
      return EFreightPaymentTerms.DEFAULT;

    case 'freight':
      if (incotermTrade === EFreightIncotermsTrade.EXPORT) {
        if (incoterm === EFreightIncotermsByExport.DAP
          || incoterm === EFreightIncotermsByExport.DPU
          || incoterm === EFreightIncotermsByExport.CIF
          || incoterm === EFreightIncotermsByExport.CFR) {
          return EFreightPaymentTerms.PREPAID;
        }
      }

      if (incotermTrade === EFreightIncotermsTrade.IMPORT) {
        if (incoterm === EFreightIncotermsByImport.EXW
          || incoterm === EFreightIncotermsByImport.FCA
          || incoterm === EFreightIncotermsByImport.FAS
          || incoterm === EFreightIncotermsByImport.FOB) {
          return EFreightPaymentTerms.COLLECT;
        }
      }

      return EFreightPaymentTerms.DEFAULT;

    case 'destination':
      if (incotermTrade === EFreightIncotermsTrade.EXPORT) {
        if (incoterm === EFreightIncotermsByExport.DAP || incoterm === EFreightIncotermsByExport.DPU) {
          return EFreightPaymentTerms.PREPAID;
        }
        if (incoterm === EFreightIncotermsByExport.CIF || incoterm === EFreightIncotermsByExport.CFR) {
          return EFreightPaymentTerms.COLLECT;
        }
      }

      if (incotermTrade === EFreightIncotermsTrade.IMPORT) {
        if (incoterm === EFreightIncotermsByImport.EXW
          || incoterm === EFreightIncotermsByImport.FCA
          || incoterm === EFreightIncotermsByImport.FAS
          || incoterm === EFreightIncotermsByImport.FOB) {
          return EFreightPaymentTerms.COLLECT;
        }
      }

      return EFreightPaymentTerms.DEFAULT;

    default:
      return EFreightPaymentTerms.DEFAULT;
  }
};

const getFreightQuote = (state: RootState) => (
  state.FreightQuota
);

const getUserOrganizationData = (state: RootState) => (
  state.userOrganizationData
);

const getFreightQuoteCurrent = (state: RootState) => (
  state.FreightQuota.currentState
);

const getFreightQuoteOriginDoorStatusCurrent = (state: RootState) => (
  state.FreightQuota.currentState?.doorsAutocomplete?.origin?.status
);

const getFreightQuoteDestinationDoorStatusCurrent = (state: RootState) => (
  state.FreightQuota.currentState?.doorsAutocomplete?.destination?.status
);

const getOriginDoorAddress = createSelector(
  getFreightQuoteCurrent,
  (data) => data?.doorsAutocomplete?.origin?.options?.map((item) => ({
    label: item.description,
    value: item.value,
    key: item.key,
  })) || [],
);

const getDestinationDoorAddress = createSelector(
  getFreightQuoteCurrent,
  (data) => data?.doorsAutocomplete?.destination?.options?.map((item) => ({
    label: item.description,
    value: item.value,
    key: item.key,
  })) || [],
);

const localState = getFreightQuoteCurrent;

const allQuotes = createSelector(
  localState,
  (state) => state.quotas?.allQuotas,
);

const printedQuotaId = createSelector(
  localState,
  (state) => state.form?.services?.printedQuotaId,
);

const getIsLoadingPrint = createSelector(
  localState,
  (state) => state.form?.services?.status === REQUEST_STATUS.pending,
);

const getCustomerInfo = createSelector(
  localState,
  (state) => state?.customer?.info,
);

const matchedQuota = createSelector(
  allQuotes,
  printedQuotaId,
  (quotas, id) => quotas?.find((item) => item.id === id),
);

const getCost = createSelector(
  matchedQuota,
  (quota) => ({
    ...quota?.cost,
    freightPaymentTerms: getPaymentTerms('freight', quota?.incoterm, quota?.tradeType).toLowerCase(),
    originPaymentTerms: getPaymentTerms('origin', quota?.incoterm, quota?.tradeType).toLowerCase(),
    destinationPaymentTerms: getPaymentTerms('destination', quota?.incoterm, quota?.tradeType).toLowerCase(),
  }),
);

const getIncoterm = createSelector(
  matchedQuota,
  (quota) => quota?.incoterm,
);

const getTradeType = createSelector(
  matchedQuota,
  (quota) => quota?.tradeType?.toLowerCase(),
);

const getTotalCost = createSelector(
  matchedQuota,
  getCost,
  (quota, cost) => {
    if (!quota?.incoterm) return 0;

    const incoterm = quota?.incoterm as string;

    switch (quota?.tradeType) {
      case EFreightIncotermsTrade.EXPORT:
        if (incoterm === EFreightIncotermsByExport.DAP || incoterm === EFreightIncotermsByExport.DPU) {
          return (cost?.originTotalCost || 0) + (cost?.freightTotalCost || 0) + (cost?.destinationTotalCost || 0);
        }
        if (incoterm === EFreightIncotermsByExport.CIF || incoterm === EFreightIncotermsByExport.CFR) {
          return (cost?.originTotalCost || 0) + (cost?.freightTotalCost || 0);
        }

        return 0;

      case EFreightIncotermsTrade.IMPORT:
        if (incoterm === EFreightIncotermsByImport.EXW) {
          return (cost?.originTotalCost || 0) + (cost?.freightTotalCost || 0) + (cost?.destinationTotalCost || 0);
        }
        if (incoterm === EFreightIncotermsByImport.FCA || incoterm === EFreightIncotermsByImport.FAS || incoterm === EFreightIncotermsByImport.FOB) {
          return (cost?.freightTotalCost || 0) + (cost?.destinationTotalCost || 0);
        }

        return 0;

      default:
        return 0;
    }
  },
);

const getIsExcludedOriginPhase = createSelector(
  matchedQuota,
  (quota) => {
    if (!quota?.incoterm) return false;

    const incoterm = quota?.incoterm as string;

    return quota?.tradeType === EFreightIncotermsTrade.IMPORT
      && (incoterm === EFreightIncotermsByImport.FCA
        || incoterm === EFreightIncotermsByImport.FAS
        || incoterm === EFreightIncotermsByImport.FOB);
  },
);

const getIsExcludedDestinationPhase = createSelector(
  matchedQuota,
  (quota) => {
    if (!quota?.incoterm) return false;

    const incoterm = quota?.incoterm as string;

    return quota?.tradeType === EFreightIncotermsTrade.EXPORT
      && (incoterm === EFreightIncotermsByExport.CIF
        || incoterm === EFreightIncotermsByExport.CFR);
  },
);

const getValidity = createSelector(
  matchedQuota,
  (quota) => (quota?.validPeriod && quota?.validPeriod.to && quota?.validPeriod.from ? {
    from: quota?.validPeriod.from.getDateMMMDYYYYWithOffset(),
    to: quota?.validPeriod.to.getDateMMMDYYYYWithOffset(),
  } : { from: '', to: '' }),
);

const getQuantityOfContainers = createSelector(
  matchedQuota,
  (quota) => countBy((item) => item.type, quota?.containers),
);

const getUniqTypeContainers = createSelector(
  matchedQuota,
  (quota) => uniqBy((item) => item.type, quota?.containers),
);

const getDictionaryOfContainers = createSelector(
  matchedQuota,
  (quota) => quota?.containers?.reduce((acc, cur) => ({ ...acc, [cur?.id || '']: cur.type }), {}),
);

const getContainers = createSelector(
  matchedQuota,
  getQuantityOfContainers,
  getUniqTypeContainers,
  (quota, quantity, containers) => containers.map((item) => ({
    type: item.type,
    qty: quantity[item?.type || ''],
    weight: quota?.containers?.reduce((acc, cur) => (cur.type === item.type ? (acc + (cur.weight || 0)) : acc), 0),
    volume: quota?.containers?.reduce((acc, cur) => (cur.type === item.type ? (acc + (cur.volume || 0)) : acc), 0),
  })),
);

const getCommodity = createSelector(
  matchedQuota,
  (quota) => {
    if (quota?.containers?.[0]?.commodities?.length) {
      return quota?.containers[0].commodities.map((item) => ({
        description: item.description,
        hsCode: item.code,
        un: item.unNumber,
        imoClass: IMOClassTypeNames[item.imoClass as IMOClassType],
        value: item.value,
      }));
    }
    return [];
  },
);

const isFullColumnCommodity = createSelector(
  getCommodity,
  (commodity) => !!commodity.find((item) => item.un || item.imoClass),
);

const getRoutingFrom = createSelector(
  matchedQuota,
  (quota) => {
    const route = quota?.routes?.[0].origin;
    const isDoor = route?.type === freightTypeLocation.DOOR;
    let portName = '';
    let doorName = '';
    const type = route?.type;

    if (origin && isDoor) {
      doorName = `(${type}) ${route?.city || ''}`;

      const postalCode = route.postalCode ? `, ${route.postalCode || ''}` : '';
      doorName += doorName ? `${postalCode}` : `${route.postalCode || ''}`;

      doorName += doorName ? `, ${route?.country?.name || ''}` : `${route?.country?.name || ''}`;
    }

    const name = route?.name;
    const country = route?.country?.code;

    portName = `(${type}) ${name}, ${country}`;

    return isDoor ? doorName : portName;
  },
);

const getRoutingTo = createSelector(
  matchedQuota,
  (quota) => {
    const route = quota?.routes?.[0].destination;
    const isDoor = route?.type === freightTypeLocation.DOOR;
    let portName = '';
    let doorName = '';
    const type = route?.type;

    if (origin && isDoor) {
      doorName = `(${type}) ${route?.city || ''}`;

      const postalCode = route.postalCode ? `, ${route.postalCode || ''}` : '';
      doorName += doorName ? `${postalCode}` : `${route.postalCode || ''}`;

      doorName += doorName ? `, ${route?.country?.name || ''}` : `${route?.country?.name || ''}`;
    }

    const name = route?.name;
    const country = route?.country?.code;

    portName = `(${type}) ${name}, ${country}`;

    return isDoor ? doorName : portName;
  },
);

const getCarrierName = createSelector(
  matchedQuota,
  (quota) => getCarrierSCACNamesRFQ(quota?.contracts?.[0]?.scac || ''),
);

const getCarrierTable = createSelector(
  matchedQuota,
  (quota) => quota?.schedules?.map((item) => {
    const destination = quota?.routes?.[0].destination;
    const isDoorDestination = destination?.type === freightTypeLocation.DOOR;

    const filtered = item?.transportations?.filter((elem) => (elem?.transport?.type === 'SEA' || elem?.transport?.type === 'WATER'))[0];
    const filteredDrayage = item?.transportations?.filter((elem) => (elem?.schedule?.type === ChargeCodeLoadTypeEnum.DRAYAGE))[0];

    return ({
      voyage: `${filtered?.transport?.number} / ${filtered?.transport?.name}`,
      departure: filteredDrayage ? `${isDoorDestination ? dateFormatETAmin(filteredDrayage?.schedule?.fullPickupTime || '') : `${dateFormatETAmin(filteredDrayage?.schedule?.pickupFromTime || '')} - ${dateFormatETAmin(filteredDrayage?.schedule?.pickupToTime || '')}`}` : moment.parseZone(item.departureTime).format('D MMM YYYY, HH:mm'),
      arrival: moment.parseZone(item.arrivalTime).format('D MMM YYYY, HH:mm'),
      cutoff: moment.parseZone(item.terminalCutOff).format('D MMM YYYY, HH:mm'),
      days: parseDurationFromHoursToDays(item?.totalDuration || 0),
    });
  }),
);

export const generateUnitType = (priceBy: string, measureBy: string) => {
  if (priceBy === 'CONTAINER' && (measureBy === chargeCodeMeasureBy.FLAT || measureBy === chargeCodeMeasureBy.CONTAINER_TYPE || measureBy === chargeCodeMeasureBy.MILE)) {
    return i18n.t('createInvoiceTable.CONTAINER_TYPE');
  }
  if ((priceBy === 'CONTAINER' && !['FLAT', 'CONTAINER_TYPE'].includes(measureBy)) || priceBy === 'BOL') {
    return measureByConst?.[measureBy as keyof typeof measureByConst] ? i18n.t(`createInvoiceTable.${measureByConst[measureBy as keyof typeof measureByConst]}`) : '';
  }
  return '';
};

const getPrintedChargesApplied = createSelector(
  matchedQuota,
  getDictionaryOfContainers,
  (quota, containers) => {
    const charges = quota?.schedules?.[0]?.charges;
    const filteredCharges = charges && charges.filter((item) => item?.applied);
    return filteredCharges?.map((item) => ({
      ...item,
      chargeCode: {
        ...item.chargeCode,
        description: item.chargeCode?.code === 'MSC' ? (item.chargeCode?.originalDescription?.toLowerCase() || item.chargeCode.description) : item.chargeCode?.description,
      },
      customContainerType: containers && item.containerId ? containers[item.containerId as unknown as keyof typeof containers] as string : '',
      customUnitType: generateUnitType(item?.priceBy || '', item?.measureBy || ''),
    })) || [];
  },
);

const getSumAppliedCharges = createSelector(
  getPrintedChargesApplied,
  (charges) => charges?.reduce((acc, cur) => acc + (cur?.costPerUnit || 0), 0),
);

export const getPrintedServicesCharges = createSelector(
  getPrintedChargesApplied,
  (charges) => partition((item) => item?.chargeCode?.occurrence === occurrenceAdditional, charges),
);

export const getPrintedFeesCharges = createSelector(
  getPrintedServicesCharges,
  ([, charges]) => partition((item) => (item?.chargeCode?.type === typeFee) || (item?.priceBy === priceByBol), charges),
);

export const getPrintedTransportationCharges = createSelector(
  getPrintedFeesCharges,
  ([, charges]) => {
    const filtered = charges && charges.filter((item) => item?.priceBy === priceByContainer);
    const topRated = ['PKP', 'FRT', 'DLV'];
    return filtered.sort((elem) => (topRated.includes(elem?.chargeCode?.code || '') ? 1 : 0));
  },
);

export const getPrintedServicesChargesTotal = createSelector(
  getPrintedServicesCharges,
  getIsExcludedOriginPhase,
  getIsExcludedDestinationPhase,
  ([charges], excludedOrigin, excludedDestination) => {
    if (excludedOrigin && excludedDestination) {
      const data = charges.filter((item) => item.designation !== 'ORIGIN' && item.designation !== 'DESTINATION');
      return data.reduce((acc, cur) => acc + (cur?.totalCost || 0), 0);
    }
    if (excludedOrigin) {
      const data = charges.filter((item) => item.designation !== 'ORIGIN');
      return data.reduce((acc, cur) => acc + (cur?.totalCost || 0), 0);
    }
    if (excludedDestination) {
      const data = charges.filter((item) => item.designation !== 'DESTINATION');
      return data.reduce((acc, cur) => acc + (cur?.totalCost || 0), 0);
    }
    return charges.reduce((acc, cur) => acc + (cur?.totalCost || 0), 0);
  },
);

export const getPrintedFeesChargesTotal = createSelector(
  getPrintedFeesCharges,
  getIsExcludedOriginPhase,
  getIsExcludedDestinationPhase,
  ([charges], excludedOrigin, excludedDestination) => {
    if (excludedOrigin && excludedDestination) {
      const data = charges.filter((item) => item.designation !== 'ORIGIN' && item.designation !== 'DESTINATION');
      return data.reduce((acc, cur) => acc + (cur?.totalCost || 0), 0);
    }
    if (excludedOrigin) {
      const data = charges.filter((item) => item.designation !== 'ORIGIN');
      return data.reduce((acc, cur) => acc + (cur?.totalCost || 0), 0);
    }
    if (excludedDestination) {
      const data = charges.filter((item) => item.designation !== 'DESTINATION');
      return data.reduce((acc, cur) => acc + (cur?.totalCost || 0), 0);
    }
    return charges.reduce((acc, cur) => acc + (cur?.totalCost || 0), 0);
  },
);

export const getPrintedTransportationChargesTotal = createSelector(
  getPrintedTransportationCharges,
  getIsExcludedOriginPhase,
  getIsExcludedDestinationPhase,
  (charges, excludedOrigin, excludedDestination) => {
    if (excludedOrigin && excludedDestination) {
      const data = charges.filter((item) => item.designation !== 'ORIGIN' && item.designation !== 'DESTINATION');
      return data.reduce((acc, cur) => acc + (cur?.totalCost || 0), 0);
    }
    if (excludedOrigin) {
      const data = charges.filter((item) => item.designation !== 'ORIGIN');
      return data.reduce((acc, cur) => acc + (cur?.totalCost || 0), 0);
    }
    if (excludedDestination) {
      const data = charges.filter((item) => item.designation !== 'DESTINATION');
      return data.reduce((acc, cur) => acc + (cur?.totalCost || 0), 0);
    }
    return charges.reduce((acc, cur) => acc + (cur?.totalCost || 0), 0);
  },
);

export const getUniqContainersTypes = createSelector(
  matchedQuota,
  (quota) => {
    const types = quota?.containers?.map((item) => item?.type || '') || [];
    return uniq(types);
  },
);

const findUniq = (charges: IFreightQuotaContentSchedulesChargesDTM[]) => charges.reduce((acc: IFreightQuotaContentSchedulesChargesDTM[], cur) => {
  if (acc.find((item) => (item.chargeCode?.code === cur.chargeCode?.code) && (item.designation === cur.designation) && (item.chargeCode?.description === cur.chargeCode?.description))) {
    return acc;
  }
  return [...acc, cur];
}, []);

export const getPrintedTransportationGrouped = createSelector(
  getPrintedTransportationCharges,
  getUniqContainersTypes,
  (charges, types) => {
    const grouped = groupBy((item) => item.customContainerType, charges);
    return types.reduce((acc, cur) => {
      const uniqCharges = grouped[cur] ? findUniq(grouped[cur]) : [];
      const withCount = uniqCharges.map((item) => {
        const qty = grouped[cur].filter((elem) => (elem.chargeCode?.code === item.chargeCode?.code) && (elem.chargeCode?.description === item.chargeCode?.description) && (elem.designation === item.designation)).length;

        return ({
          ...item,
          customQuantity: qty,
          customTotal: qty * (item?.costPerUnit || 1) * (item?.numberOfUnits || 1),
        });
      });
      return withCount.length ? ({ ...acc, [cur || '']: withCount }) : acc;
    }, {});
  },
);

export const getPrintedServicesGrouped = createSelector(
  getPrintedServicesCharges,
  getUniqContainersTypes,
  ([charges], types) => {
    const filteredCharges = charges.filter((item) => item.containerId);
    const grouped = groupBy((item) => item.customContainerType, filteredCharges);

    return types.reduce((acc, cur) => {
      const uniqCharges = grouped[cur] ? findUniq(grouped[cur]) : [];
      const withCount = uniqCharges.map((item) => {
        const qty = grouped[cur].filter((elem) => (elem.chargeCode?.code === item.chargeCode?.code) && (elem.designation === item.designation)).length;
        return ({
          ...item,
          customQuantity: qty,
          customTotal: qty * (item?.costPerUnit || 1),
        });
      });
      return withCount.length ? ({ ...acc, [cur || '']: withCount }) : acc;
    }, {});
  },
);

export const getPrintedServicesList = createSelector(
  getPrintedServicesCharges,
  ([charges]) => charges?.filter((item) => !item?.containerId),
);

export const getPrintedFeesGrouped = createSelector(
  getPrintedFeesCharges,
  getUniqContainersTypes,
  ([charges], types) => {
    const filteredCharges = charges.filter((item) => item.containerId);
    const grouped = groupBy((item) => item.customContainerType, filteredCharges);

    return types.reduce((acc, cur) => {
      const uniqCharges = grouped[cur] ? findUniq(grouped[cur]) : [];
      const withCount = uniqCharges.map((item) => {
        const qty = grouped[cur].filter((elem) => (elem.chargeCode?.code === item.chargeCode?.code) && (elem.designation === item.designation)).length;

        return ({
          ...item,
          customQuantity: qty,
          customTotal: qty * (item?.costPerUnit || 1),
        });
      });
      return withCount.length ? ({ ...acc, [cur || '']: withCount }) : acc;
    }, {});
  },
);

export const getPrintedFeesList = createSelector(
  getPrintedFeesCharges,
  ([charges]) => charges.filter((item) => !item.containerId),
);

export const getNotAppliedCharges = createSelector(
  matchedQuota,
  getDictionaryOfContainers,
  (quota, containers) => {
    const charges = quota?.schedules?.[0]?.charges;
    const filteredCharges = charges && charges?.filter((item) => !item.applied && ['ACCESSORIAL', 'EXCEPTION', 'FREE_TIME'].includes(item?.chargeCode?.subType || '') && item.subjectTo !== 'INCLUDED');
    return filteredCharges?.map((item) => ({
      ...item,
      chargeCode: {
        ...item.chargeCode,
        description: item.chargeCode?.code === 'MSC' ? (item.chargeCode?.originalDescription?.toLowerCase() || item.chargeCode.description) : item.chargeCode?.description,
      },
      customContainerType: containers && item?.containerId ? containers[item.containerId as unknown as keyof typeof containers] as string : '',
      customUnitType: generateUnitType(item?.priceBy || '', item?.measureBy || ''),
    }));
  },
);

export const getAccessorial = createSelector(
  getNotAppliedCharges,
  (charges) => charges?.filter((item) => item?.chargeCode?.subType === 'ACCESSORIAL'),
);

export const getException = createSelector(
  getNotAppliedCharges,
  (charges) => charges?.filter((item) => item?.chargeCode?.subType === 'EXCEPTION'),
);

export const getAccessorialGrouped = createSelector(
  getAccessorial,
  getUniqContainersTypes,
  (charges, types) => {
    const filteredCharges = charges?.filter((item) => item.containerId);
    const grouped = groupBy((item) => item.customContainerType, filteredCharges);

    return types.reduce((acc, cur) => {
      const uniqCharges = grouped[cur] ? findUniq(grouped[cur]) : [];
      const withCount = uniqCharges.map((item) => {
        const qty = grouped[cur].filter((elem) => (elem.chargeCode?.code === item.chargeCode?.code) && (elem.designation === item.designation)).length;

        return ({
          ...item,
          customQuantity: qty,
          customTotal: qty * (item?.costPerUnit || 1),
        });
      });
      return withCount.length ? ({ ...acc, [cur || '']: withCount }) : acc;
    }, {});
  },
);

export const getAccessorialList = createSelector(
  getAccessorial,
  (charges) => charges?.filter((item) => !item.containerId),
);

export const getExceptionGrouped = createSelector(
  getException,
  getUniqContainersTypes,
  (charges, types) => {
    const filteredCharges = charges?.filter((item) => item.containerId);
    const grouped = groupBy((item) => item.customContainerType, filteredCharges);

    return types.reduce((acc, cur) => {
      const uniqCharges = grouped[cur] ? findUniq(grouped[cur]) : [];
      const withCount = uniqCharges.map((item) => {
        const qty = grouped[cur].filter((elem) => (elem.chargeCode?.code === item.chargeCode?.code) && (elem.designation === item.designation)).length;

        return ({
          ...item,
          customQuantity: qty,
          customTotal: qty * (item?.costPerUnit || 1),
        });
      });
      return withCount.length ? ({ ...acc, [cur || '']: withCount }) : acc;
    }, {});
  },
);

export const getExceptionList = createSelector(
  getException,
  (charges) => charges?.filter((item) => !item.containerId),
);

export const calculateRange = (maxValue?: number, minValue?: number) => {
  if (maxValue && minValue) {
    return `min${minValue}-max${maxValue}`;
  }
  if (minValue && !maxValue) {
    return `min${minValue}+`;
  }
  if (maxValue && !minValue) {
    return `0-max${maxValue}`;
  }
  return '';
};

export const getFreeTime = createSelector(
  getNotAppliedCharges,
  (charges) => {
    const filtered = charges?.filter((item) => item.chargeCode?.subType === 'FREE_TIME');

    return filtered?.map((item) => ({ ...item, customAppliance: calculateRange(item.applianceRange?.maxValue, item.applianceRange?.minValue) }));
  },
);

export const getCurrentOrgRelatedAdmin = createSelector(
  getFreightQuote,
  (state) => state.currentOrgRelatedAdmin,
);

export const getCurrentOrgRelatedAdminAddress = createSelector(
  getFreightQuote,
  (state) => state.currentOrgRelatedAdmin?.address,
);

export const getAccountHolderPricingDepartment = createSelector(
  getFreightQuote,
  (state) => state.accountHolderPricingDepartment,
);

const getHeaderSkypaceInformation = createSelector(
  getCurrentOrgRelatedAdminAddress,
  getCurrentOrgRelatedAdmin,
  getAccountHolderPricingDepartment,
  (adminAddress, admin, department) => {
    const isAdminFromUS = adminAddress?.country === 'US';
    const isAdminFromUK = adminAddress?.country === 'GB';
    const skypaceUS = SkypaceInformationDTM.getCompanyUS();
    const skypaceUK = SkypaceInformationDTM.getCompanyUK();

    if (!isAdminFromUS && !isAdminFromUK) {
      return undefined;
    }

    return SkypaceInformationDTM.fromPlain({
      ...(isAdminFromUS ? skypaceUS : {}),
      ...(isAdminFromUK ? skypaceUK : {}),
      companyName: admin?.name || '',
      companyAddress1: adminAddress?.address1 || '',
      companyAddress2: `${adminAddress?.city}, ${adminAddress?.state || ''} ${adminAddress?.country || ''}`,
      companyDepartment: i18n.t('Pricing'),
      companyPhone: department?.phone || '',
      companyEmail: department?.email || '',
    });
  },
);

export const FreightQuotePrintSelectors = {
  allQuotes,
  getAccessorialGrouped,
  getAccessorialList,
  getCarrierName,
  getCarrierTable,
  getCommodity,
  getContainers,
  getCost,
  getCustomerInfo,
  getDestinationDoorAddress,
  getExceptionGrouped,
  getExceptionList,
  getFreeTime,
  getFreightQuote,
  getFreightQuoteCurrent,
  getFreightQuoteDestinationDoorStatusCurrent,
  getFreightQuoteOriginDoorStatusCurrent,
  getIsLoadingPrint,
  getUserOrganizationData,
  getHeaderSkypaceInformation,
  getOriginDoorAddress,
  getPrintedFeesChargesTotal,
  getPrintedFeesGrouped,
  getPrintedFeesList,
  getPrintedServicesChargesTotal,
  getPrintedServicesGrouped,
  getPrintedServicesList,
  getPrintedTransportationCharges,
  getPrintedTransportationChargesTotal,
  getPrintedTransportationGrouped,
  getRoutingFrom,
  getRoutingTo,
  getSumAppliedCharges,
  getUniqContainersTypes,
  getValidity,
  isFullColumnCommodity,
  printedQuotaId,
  getTotalCost,
  getIncoterm,
  getTradeType,
  getIsExcludedOriginPhase,
  getIsExcludedDestinationPhase,
  getCurrentOrgRelatedAdmin,
};
