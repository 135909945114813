import React, {
  FC, memo, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from 'app-wrapper/hooks';

import {

  ICompanyAddressDTM, ICompanyContactDTM, IContactBookDefaultStateCompanyListStateDTM, IContactBookUpdateStateCompanyListStateErrorsDTM,
} from 'user-management/models/dtm';
import { ContactBookAddressItem, ContactBookContactPersonItem } from 'user-management/view/pages/ContactBook/components';
import { TooltipError } from 'user-management/view/components';

import {
  BookingACContentBodyForm,
  BookingACContentBodyFormCompanyFirstBlock,
  BookingACContentBodyFormCompanyName,
  BookingACContentBodyFormCompanyNameInput,
  BookingACContentBodyFormCompanyNameTitle,
  BookingACContentBodyFormCompanyNameTitleWrapper,
  BookingACContentBodyFormCompanyTaxId,
  BookingACContentBodyFormCompanyTaxIdInput,
  BookingACContentBodyFormCompanyTaxIdTitle,
  BookingACContentBodyFormCompanyTaxIdTitleWrapper,
  BookingACContentBodyFormFooter,
  BookingACContentBodyFormWrap,
  BookingACContentBodyFormWrapper,
} from './BookingAddCompany.styled';

interface IBookingAddCompanyComponentProps {
  companyListState: IContactBookDefaultStateCompanyListStateDTM[]
  companyListAddresses?: ICompanyAddressDTM[]
  companyListContactPersons?: ICompanyContactDTM[]
  companyListStateIndex: string
  isUpdating: boolean
  errors: IContactBookUpdateStateCompanyListStateErrorsDTM
  updateForm: () => void
  onDiscard: () => void
  onChangeListName: (index: string) => (name: string) => void
  onBlurListName: () => void
  onFocusListName: () => void
  onChangeTaxId: (index: string) => (name: string) => void
  onBlurTaxId: () => void
  onFocusTaxId: () => void
  isAddressBlocked?: boolean;
}

const BookingAddCompanyComponent: FC<IBookingAddCompanyComponentProps> = (props) => {
  const {
    companyListState,
    companyListAddresses,
    companyListContactPersons,
    companyListStateIndex,
    isUpdating,
    errors,
    updateForm,
    onDiscard,
    onChangeListName,
    onBlurListName,
    onFocusListName,
    onChangeTaxId,
    onBlurTaxId,
    onFocusTaxId,
    isAddressBlocked,
  } = props;
  const { t } = useTranslation();
  const largeInput = useWindowSize().isFullMediaWidth;

  const onChangeListNameHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeListName(companyListStateIndex)(event.target.value);
    },
    [companyListStateIndex],
  );

  const onChangeTaxIdHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeTaxId(companyListStateIndex)(event.target.value);
    },
    [companyListStateIndex],
  );

  const hasErrorName = useMemo(() => {
    const errDocument = errors?.name;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [companyListStateIndex, errors]);

  const hasErrorTaxId = useMemo(() => {
    const errDocument = errors?.taxId;

    return !!(errDocument
      && errDocument?.message
      && (errDocument?.isBlur || errDocument?.isVisited));
  }, [companyListStateIndex, errors]);

  const tooltipMessage = errors?.name?.message || '';
  const tooltipVisible = errors?.name?.isTooltipVisible || false;

  return (
    <BookingACContentBodyFormWrap>
      <BookingACContentBodyForm>
        <BookingACContentBodyFormWrapper>
          <BookingACContentBodyFormCompanyFirstBlock>
            <BookingACContentBodyFormCompanyName>
              <BookingACContentBodyFormCompanyNameTitleWrapper>
                <BookingACContentBodyFormCompanyNameTitle>
                  {`${t('CompanyName')} ${t('requiredSymbol')}`}
                </BookingACContentBodyFormCompanyNameTitle>
              </BookingACContentBodyFormCompanyNameTitleWrapper>
              <TooltipError
                message={tooltipMessage}
                visible={tooltipVisible}
              >
                <BookingACContentBodyFormCompanyNameInput
                  largeInput={largeInput}
                  value={companyListState.filter((item) => item.customId === companyListStateIndex)?.[0]?.name}
                  hasError={hasErrorName}
                  onChange={onChangeListNameHandler}
                  onBlur={onBlurListName}
                  onFocus={onFocusListName}
                />
              </TooltipError>
            </BookingACContentBodyFormCompanyName>

            <BookingACContentBodyFormCompanyTaxId>
              <BookingACContentBodyFormCompanyTaxIdTitleWrapper>
                <BookingACContentBodyFormCompanyTaxIdTitle>
                  {t('Tax ID')}
                </BookingACContentBodyFormCompanyTaxIdTitle>
              </BookingACContentBodyFormCompanyTaxIdTitleWrapper>
              <BookingACContentBodyFormCompanyTaxIdInput
                largeInput={largeInput}
                value={companyListState.filter((item) => item.customId === companyListStateIndex)?.[0]?.taxId || ''}
                hasError={hasErrorTaxId}
                onChange={onChangeTaxIdHandler}
                onBlur={onBlurTaxId}
                onFocus={onFocusTaxId}
              />
            </BookingACContentBodyFormCompanyTaxId>
          </BookingACContentBodyFormCompanyFirstBlock>

          {companyListAddresses?.map((addressItem, addressIndex) => (
            <ContactBookAddressItem
              key={`ContactBookAddressItem-${addressItem.customId}`}
              largeInput={largeInput}
              isFirst
              isLast={false}
              addressIndex={addressIndex}
              companyListStateIndex={companyListStateIndex}
              address={addressItem}
              isAddressBlocked={isAddressBlocked}
            />
          ))}

          {companyListContactPersons?.map((personItem, personIndex) => (
            <ContactBookContactPersonItem
              key={`ContactBookContactPersonItem-${personItem.customId}`}
              largeInput={largeInput}
              isFirst
              isLast={false}
              contactIndex={personIndex}
              personItem={personItem}
              companyListStateIndex={companyListStateIndex}
            />
          ))}

        </BookingACContentBodyFormWrapper>

      </BookingACContentBodyForm>

      <BookingACContentBodyFormFooter
        saveName={t('Add')}
        discardName={t('cancel')}
        discardChanges={onDiscard}
        updateForm={updateForm}
        disabledUpdate={isUpdating}
      />
    </BookingACContentBodyFormWrap>
  );
};

const BookingAddCompanyComponentCache = memo(BookingAddCompanyComponent);

export { BookingAddCompanyComponentCache as BookingAddCompanyComponent };
