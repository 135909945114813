import React from 'react';
import { useCurrentController } from 'app-wrapper/view/routes/components';
import {
  ShipmentTransportationExportInlandPickupOverviewController,
} from 'shipment-operations/controllers/ShipmentTransportationExportInlandPickupOverview/ShipmentTransportationOverview.controller';
import { observer } from 'mobx-react-lite';
import {
  DepartureContainersComponent,
} from 'shipment-operations/view/pages/TransportationExportInlandPickup/components/Overview/components/DepartureContainers/DepartureContainers.component';
import { useMobxStore } from 'app-wrapper/mobxStores';

export const DepartureContainersContainer = observer(() => {
  const controller = useCurrentController<ShipmentTransportationExportInlandPickupOverviewController>();
  const { shipmentContainersStore } = useMobxStore();

  return (
    <DepartureContainersComponent
      containers={shipmentContainersStore.items}
      openEditDrawer={controller.openEditContainers}
    />
  );
});
