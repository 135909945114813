import message from 'antd/es/message';
import { shipmentContainerNumberValidate } from 'shipment-operations/controllers/ShipmentContainersController/ShipmentContainers.validate';
import { v4 as uuidv4 } from 'uuid';
import i18n from 'app-wrapper/i18n/i18n';
import { DateDtm } from 'app-wrapper/models/dtm';
import { ReferenceDTM } from 'app-wrapper/types';
import { DatePickerMobxUseCase } from 'app-wrapper/usecases/DatePickerMobx.useCase';
import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';
import { InputMobxUseCase } from 'app-wrapper/usecases/InputMobx.useCase';
import { SelectMobxUseCase } from 'app-wrapper/usecases/SelectMobx.useCase';
import { instanceToPlain } from 'app-wrapper/utils';
import { BaseController, controller } from 'proto/BaseController';
import { InputStore } from 'proto/BaseMobxStore/InputStore';
import { DepartureDetailsStatusEnum, EContainerReferenceType } from 'shipment-operations/constants';
import { EDrayageSide } from 'shipment-operations/constants/DrayageSide.enum';
import { ContainerDepartureDetailsDTM, ContainerDTM, IContainerInputDTM } from 'shipment-operations/models/dtm';
import { ShipmentEditContainerStore } from 'shipment-operations/repository/mobxStores/ShipmentEditContainerStore';
import { R } from 'shipment-operations/repository';

@controller
export class ShipmentEditContainerController extends BaseController<ShipmentEditContainerStore> {
  onLoadedPage() {
    super.onLoadedPage();
    this.currentStore?.setLoading(true);

    const container = this.mobxStore.shipmentContainersStore.items.find((_container) => _container.id === String(this.currentStore?.containerId));
    this.currentStore?.setContainer(container);

    this.currentStore?.setForm({
      number: new InputStore(container?.number),
      // carrier: new InputStore('container.number'),
      emptyArrivalDate: new InputStore(container?.exportEmptyArrivalDate() || undefined),
      fullDepartureDate: new InputStore(container?.exportFullDepartureDate() || undefined),
      pickupReference: new InputStore(container?.pickupReferenceValue()),
      dispatchOrder: new InputStore(container?.dispatchOrderValue()),
      status: new InputStore(container?.departureDetails?.[0]?.status || DepartureDetailsStatusEnum.DISPATCHED),
    });

    this.currentStore?.setLoading(false);
  }

  number() {
    return new InputMobxUseCase(
      this,
      this.currentStore?.state.form.number as InputStore<string>,
      this.numberValidation,
    );
  }

  numberValidation(useCase: InputMobxUseCase<InputStore<string>>) {
    const error = shipmentContainerNumberValidate(useCase.storeMobx.state.value);
    if (error) {
      useCase.setError(error.message);
      return;
    }

    useCase.setError(null);
  }

  emptyArrivalDate() {
    return new DatePickerMobxUseCase(this, this.currentStore?.state.form.emptyArrivalDate as InputStore<DateDtm>,
      this.emptyArrivalDateValidation);
  }

  emptyArrivalDateValidation(useCase: DatePickerMobxUseCase<InputStore<DateDtm>>) {
    if (this.currentStore?.state?.form?.emptyArrivalDate?.value
      && this.currentStore?.state?.form?.fullDepartureDate?.value
      && DateDtm.getDifBetweenDates(this.currentStore?.state?.form?.emptyArrivalDate?.value, this.currentStore?.state?.form?.fullDepartureDate?.value).asMinutes() < 0) {
      useCase.setError(i18n.t('Empty arrival date must not be later then Full departure date'));
      return;
    }

    this.currentStore?.state.form.emptyArrivalDate?.setError(null);
    this.currentStore?.state.form.fullDepartureDate?.setError(null);
  }

  fullDepartureDate() {
    return new DatePickerMobxUseCase(this, this.currentStore?.state.form.fullDepartureDate as InputStore<DateDtm>,
      this.emptyArrivalDateValidation);
  }

  pickupReference() {
    return new InputMobxUseCase(this, this.currentStore?.state.form.pickupReference as InputStore<string>);
  }

  dispatchOrder() {
    return new InputMobxUseCase(this, this.currentStore?.state.form.dispatchOrder as InputStore<string>);
  }

  status() {
    return new SelectMobxUseCase(this, this.currentStore?.state.form.status as InputStore<DepartureDetailsStatusEnum>);
  }

  closeDrawer() {
    new DrawersUseCase(this).closeDrawer();
  }

  async updateContainer() {
    if (this.currentStore?.hasError) {
      return;
    }

    const container = ContainerDTM.fromPlain(instanceToPlain(this.container()) as IContainerInputDTM);

    let pickupReference = container?.references?.find((reference) => reference.type === EContainerReferenceType.OTHER);
    if (!pickupReference) {
      pickupReference = ReferenceDTM.fromPlain({
        id: uuidv4(),
        type: EContainerReferenceType.OTHER,
        value: '',
      });
      container?.references?.push(pickupReference);
    }

    let dispatchOrder = container?.references?.find((reference) => reference.type === EContainerReferenceType.DO);
    if (!dispatchOrder) {
      dispatchOrder = ReferenceDTM.fromPlain({
        id: uuidv4(),
        type: EContainerReferenceType.DO,
        value: '',
      });
      container?.references?.push(dispatchOrder);
    }

    let departureDetail = container?.departureDetails?.find((detail) => detail.drayageSide === EDrayageSide.DESTITATION_DRAYAGE);
    if (!departureDetail) {
      departureDetail = ContainerDepartureDetailsDTM.fromPlain({
        id: undefined,
        drayageSide: EDrayageSide.DESTITATION_DRAYAGE,
        emptyArrivalDate: undefined,
        fullDepartureDate: undefined,
        status: undefined,
      });
      container?.departureDetails?.push(departureDetail);
    }

    container.number = this.currentStore?.state.form?.number?.value;
    departureDetail.emptyArrivalDate = this.currentStore?.state.form?.emptyArrivalDate?.value;
    departureDetail.fullDepartureDate = this.currentStore?.state.form?.fullDepartureDate?.value;
    departureDetail.status = this.currentStore?.state.form?.status?.value as DepartureDetailsStatusEnum;
    pickupReference.value = this.currentStore?.state.form?.pickupReference.value || '';
    dispatchOrder.value = this.currentStore?.state.form?.dispatchOrder.value || '';
    container.references = container.references.filter((reference) => reference.value);

    this.currentStore?.setSubmitting(true);

    let newContainer: ContainerDTM;

    try {
      newContainer = await R.services.shipmentContainers.putContainer(
        this.shipmentId(),
        container,
      );
    } finally {
      this.currentStore?.setSubmitting(false);
    }

    this.mobxStore.shipmentContainersStore.updateItem(newContainer);

    message.success(i18n.t('Saved successfully'));
    this.closeDrawer();
  }

  private shipmentId() {
    return (this.params as { shipmentId: string }).shipmentId;
  }

  private container() {
    return this.mobxStore.shipmentContainersStore.items.find((_container) => _container.id === String(this.currentStore?.containerId));
  }
}
