import {
  IsEnum,
  IsOptional,
} from 'class-validator';
import { BaseDTM } from 'proto/BaseDTM';

import { PermissionAttributePolicy } from 'shipment-operations/constants';

export interface ITransporationDrayagePermissionsDTM {
  editLoadingControls?: PermissionAttributePolicy;
  editExportSchedule?: PermissionAttributePolicy;
  editImportSchedule?: PermissionAttributePolicy;
}

export class TransporationDrayagePermissionsDTM extends BaseDTM<ITransporationDrayagePermissionsDTM> {
  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  editLoadingControls?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  editExportSchedule?: PermissionAttributePolicy;

  @IsOptional()
  @IsEnum(PermissionAttributePolicy)
  editImportSchedule?: PermissionAttributePolicy;
}
