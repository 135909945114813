import React from 'react';
import { observer } from 'mobx-react-lite';
import { useSelector } from 'react-redux';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { R } from 'shipment-operations/repository';
import { EShippingPartyTypes } from 'shipment-operations/constants';
import { useCurrentController, useCurrentControllerStore } from 'app-wrapper/view/routes/components';
import {
  ShipmentTransportationExportInlandPickupOverviewController,
} from 'shipment-operations/controllers/ShipmentTransportationExportInlandPickupOverview/ShipmentTransportationOverview.controller';
import { ShipmentTransportationExportInlandPickupOverviewStore } from 'shipment-operations/repository/mobxStores';
import {
  OverviewComponent,
} from './Overview.component';

export const OverviewContainer = observer(() => {
  const { shippingPartiesStore } = useMobxStore();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const controller = useCurrentController<ShipmentTransportationExportInlandPickupOverviewController>();
  const store = useCurrentControllerStore<ShipmentTransportationExportInlandPickupOverviewStore>();
  const countriesList = useSelector(R.selectors.shipmentTransportationLocationData.getCountriesList);

  return (
    <OverviewComponent
      supplier={shippingPartiesStore.getShippingPartyByRole(EShippingPartyTypes.CARGO_SUPPLIER)}
      isLoading={store.state.isLoading}
      countriesList={countriesList}
    />
  );
});
