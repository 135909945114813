import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanyDTM, ShippingPartyAddressDTM } from 'shipment-operations/models/dtm';
import { Option } from 'app-wrapper/view/components';
import { PlusSvg } from 'app-wrapper/view/icons';
import { Label } from 'shipment-operations/view/components/BookingDrayageDrawer/components/PickupDeliveryStep/components/SupplierLayout/SupplierLayout.styled';
import { SelectStyledFooter, SelectStyledFooterText } from 'shipment-operations/view/components/BookingDrawer/components/ShipperInformation/components/ShipperSelect/ShipperSelect.styled';

import { Select } from './CompanySelect.styled';

interface ICompanySelectProps {
  companyId?: number;
  isRequiredErrorVisible?: boolean;
  setCompanyId: (id: number) => void;
  companiesList: CompanyDTM[];
  prefilledAddress?: ShippingPartyAddressDTM;
  openAddCompanyDrawer: (prefilledAddress: ShippingPartyAddressDTM) => void;
}

export const CompanySelect: FC<ICompanySelectProps> = ({
  companyId,
  setCompanyId,
  isRequiredErrorVisible,
  companiesList,
  prefilledAddress,
  openAddCompanyDrawer,
}) => {
  const { t } = useTranslation();

  const handleOpenCreateCompanyDrawer = useCallback(() => {
    if (prefilledAddress) {
      openAddCompanyDrawer(prefilledAddress);
    }
  }, [prefilledAddress, openAddCompanyDrawer]);

  const dropdownRender = useCallback((menu) => (
    <>
      {menu}
      <SelectStyledFooter onClick={handleOpenCreateCompanyDrawer}>
        <PlusSvg
          style={{ color: '#6C79DE' }}
          height={12}
          width={12}
        />
        <SelectStyledFooterText>{t('Add Company')}</SelectStyledFooterText>
      </SelectStyledFooter>
    </>
  ), [openAddCompanyDrawer, prefilledAddress]);

  return (
    <>
      <Label>
        {t('Company Name *')}
      </Label>

      <Select
        value={companyId}
        onChange={setCompanyId}
        error={isRequiredErrorVisible && !companyId}
        dropdownRender={dropdownRender}
      >
        {companiesList.map((item) => (
          <Option key={item.id} value={item.id}>{item.name}</Option>
        ))}
      </Select>
    </>
  );
};
