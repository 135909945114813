import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'antd/es/row';

import { validationEmail } from 'app-wrapper/utils';
import { Col, Input } from 'app-wrapper/view/components';
import { TooltipError } from 'user-management/view/components';
import { MAX_LENGTH_20 } from 'app-wrapper/constants';
import { Label, SectionTitle } from 'shipment-operations/view/components/BookingDrayageDrawer/components/PickupDeliveryStep/components/SupplierLayout/SupplierLayout.styled';

interface IContactSectionProps {
  contactPerson?: string;
  setContactPerson: (person: string) => void;
  email?: string;
  setEmail: (email: string) => void;
  phone?: string;
  setPhone: (phone: string) => void;
  additionalPhone?: string;
  setAdditionalPhone: (phone: string) => void;
  isRequiredErrorVisible?: boolean;
}

export const ContactSectionComponent: FC<IContactSectionProps> = ({
  contactPerson,
  setContactPerson,
  email,
  setEmail,
  phone,
  setPhone,
  additionalPhone,
  setAdditionalPhone,
  isRequiredErrorVisible,
}) => {
  const { t } = useTranslation();

  const { errorMessage } = validationEmail(email || '');

  return (
    <>
      <SectionTitle>
        {t('Contact')}
      </SectionTitle>

      <Row
        style={{
          marginTop: 16,
        }}
        gutter={[8, 16]}
      >
        <Col span={12}>
          <Label>{t('Contact Person')} *</Label>

          <Input
            value={contactPerson}
            onChange={({ target }) => setContactPerson(target.value)}
            error={isRequiredErrorVisible && !contactPerson?.length}
          />
        </Col>

        <Col span={12}>
          <Label>
            {t('Email')} *
          </Label>

          <TooltipError
            visible={isRequiredErrorVisible && !!errorMessage.length}
            message={errorMessage}
          >
            <Input
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              error={isRequiredErrorVisible && !!errorMessage.length}
            />
          </TooltipError>
        </Col>

        <Col span={12}>
          <Label>{t('Phone')} *</Label>

          <Input
            maxLength={MAX_LENGTH_20}
            type="number"
            value={phone}
            onChange={({ target }) => setPhone(target.value)}
            error={isRequiredErrorVisible && !phone?.length}
          />
        </Col>

        <Col span={12}>
          <Label>{t('Additional Phone')}</Label>

          <Input
            maxLength={MAX_LENGTH_20}
            type="number"
            value={additionalPhone}
            onChange={({ target }) => setAdditionalPhone(target.value)}
          />
        </Col>
      </Row>
    </>
  );
};
