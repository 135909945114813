import {
  IsNumber, IsString, IsOptional, ValidateNested, IsDefined, IsEnum, IsBoolean,
} from 'class-validator';
import { Type } from 'class-transformer';
import { BaseDTM } from 'proto/BaseDTM';

import {
  ShipmentStatusEnum,
  ShipmentBookingStatusEnum,
  ShippingInstructionsStatusEnum,
  ShipmentTransactionStatusEnum, EDeliveryType,
} from 'shipment-operations/constants';
import { DateDtm, IDateDTM } from 'app-wrapper/models/dtm';
import {
  IDocumentDTM, DocumentDTM, IShipmentAllStatsDTM, ShipmentAllStatsDTM,
} from 'shipment-operations/models/dtm';

import { ICustomerDTM, CustomerDTM } from './Customer.dtm';
import { IWaypointDTM, WaypointDTM } from './Waypoint.dtm';

export interface IShipmentPreviewTransactionStatusDTM {
  displayValue?: string;
  enumValue?: ShipmentTransactionStatusEnum;
}

export class ShipmentPreviewTransactionStatusDTM extends BaseDTM<IShipmentPreviewTransactionStatusDTM> {
  @IsOptional()
  @IsString()
  displayValue?: string

  @IsOptional()
  @IsEnum(ShipmentTransactionStatusEnum)
  enumValue?: ShipmentTransactionStatusEnum
}

export interface IShipmentPreviewCancelReasonDtoDTM {
  type?: string
  message?: string
}

export class ShipmentPreviewCancelReasonDtoDTM extends BaseDTM<IShipmentPreviewCancelReasonDtoDTM> {
  @IsOptional()
  @IsString()
  type?: string

  @IsOptional()
  @IsString()
  message?: string
}

export interface IShipmentPreviewDTM {
  id: number
  status: ShipmentStatusEnum
  transactionStatus?: IShipmentPreviewTransactionStatusDTM
  bookingStatus: ShipmentBookingStatusEnum
  siStatus: ShippingInstructionsStatusEnum
  mblDraftId: number | null
  mblFinalId: number | null
  siSentAt: IDateDTM | null
  createdAt: IDateDTM | null
  confirmedAt: IDateDTM | null
  isRequestTheSameWithShipment: boolean
  siRequestTheSameWithShipment?: boolean
  manualConfirmationDocument: IDocumentDTM | null
  shipmentName: string
  mainVesselName?: string
  reference: string | null
  carrierReferenceNumber?: string | null
  carrier: string
  deliveryType?: EDeliveryType
  scac: string
  oceanBookingId: string | null
  containerCount: number
  origin: IWaypointDTM // TZ shift here
  destination: IWaypointDTM // TZ shift and here
  customer: ICustomerDTM | null
  isSelfService?: boolean
  organizationId?: number
  customerOrgId?: number
  contractOwnerOrgId?: number
  accountHolderOrgId?: number
  originPartnerOrgId?: number
  destinationPartnerOrgId?: number
  originPartnerAgentOrgId?: number
  destinationPartnerAgentOrgId?: number
  bookingAgentOrgId?: number
  rollPlanId: number | null
  earliestEmptyReleaseDate: string | null
  hasReeferContainer: boolean
  hasSelfOwnedContainer: boolean
  shipmentStats?: IShipmentAllStatsDTM
  shipmentAllTasks?: string
  shipmentAllAlerts?: string
  cancelReasonDto?: IShipmentPreviewCancelReasonDtoDTM
  key?: number
}

export class ShipmentPreviewDTM extends BaseDTM<IShipmentPreviewDTM> {
  @IsDefined()
  @IsNumber()
  id: number

  @IsOptional()
  @IsNumber()
  key?: number

  @IsOptional()
  @IsNumber()
  rollPlanId: number | null

  @IsOptional()
  @IsEnum(EDeliveryType)
  deliveryType?: EDeliveryType

  @IsDefined()
  @IsEnum(ShipmentStatusEnum)
  status: ShipmentStatusEnum

  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentPreviewTransactionStatusDTM)
  transactionStatus?: IShipmentPreviewTransactionStatusDTM

  @IsDefined()
  @IsEnum(ShipmentBookingStatusEnum)
  bookingStatus: ShipmentBookingStatusEnum

  @IsDefined()
  @IsEnum(ShippingInstructionsStatusEnum)
  siStatus: ShippingInstructionsStatusEnum

  @IsOptional()
  @ValidateNested()
  @Type(() => DocumentDTM)
  manualConfirmationDocument: DocumentDTM | null;

  @IsOptional()
  @IsNumber()
  mblDraftId: number | null;

  @IsOptional()
  @IsNumber()
  mblFinalId: number | null;

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  siSentAt: DateDtm | null

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  createdAt: DateDtm | null

  @IsOptional()
  @ValidateNested()
  @Type(() => DateDtm)
  confirmedAt: DateDtm | null

  @IsDefined()
  @IsBoolean()
  isRequestTheSameWithShipment: boolean

  @IsOptional()
  @IsBoolean()
  siRequestTheSameWithShipment: boolean

  @IsDefined()
  @IsString()
  shipmentName: string;

  @IsOptional()
  @IsString()
  mainVesselName?: string;

  @IsOptional()
  @IsBoolean()
  isSelfService?: boolean;

  @IsOptional()
  @IsString()
  reference: string | null;

  @IsOptional()
  @IsString()
  carrierReferenceNumber?: string;

  @IsString()
  @IsDefined()
  carrier: string;

  @IsString()
  @IsDefined()
  scac: string;

  @IsOptional()
  @IsString()
  oceanBookingId: string | null;

  @IsDefined()
  @IsNumber()
  containerCount: number;

  @IsDefined()
  @ValidateNested()
  @Type(() => WaypointDTM)
  origin: WaypointDTM;

  @IsDefined()
  @ValidateNested()
  @Type(() => WaypointDTM)
  destination: WaypointDTM;

  @IsOptional()
  @ValidateNested()
  @Type(() => CustomerDTM)
  customer: CustomerDTM | null;

  @IsOptional()
  @IsNumber()
  contractOwnerOrgId: number

  @IsOptional()
  @IsNumber()
  accountHolderOrgId: number

  @IsOptional()
  @IsNumber()
  originPartnerOrgId: number

  @IsOptional()
  @IsNumber()
  destinationPartnerAgentOrgId?: number

  @IsOptional()
  @IsNumber()
  originPartnerAgentOrgId?: number

  @IsOptional()
  @IsNumber()
  bookingAgentOrgId?: number

  @IsOptional()
  @IsNumber()
  customerOrgId: number

  @IsOptional()
  @IsNumber()
  organizationId: number

  @IsOptional()
  @IsNumber()
  destinationPartnerOrgId: number

  @IsOptional()
  @IsString()
  earliestEmptyReleaseDate: string | null;

  @IsBoolean()
  hasReeferContainer: boolean;

  @IsBoolean()
  hasSelfOwnedContainer: boolean;

  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentAllStatsDTM)
  shipmentStats?: ShipmentAllStatsDTM;

  @IsOptional()
  @IsString()
  shipmentAllTasks: string;

  @IsOptional()
  @IsString()
  shipmentAllAlerts: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => ShipmentPreviewCancelReasonDtoDTM)
  cancelReasonDto?: ShipmentPreviewCancelReasonDtoDTM;

  public isUsShipmentOriginOrDestination() {
    return Boolean(this.origin.countryCode === 'US' || this.destination.countryCode === 'US');
  }

  public isContractOwnerOrigin() {
    return this.originPartnerOrgId === this.contractOwnerOrgId;
  }

  public isContractOwnerDestination() {
    return this.destinationPartnerOrgId === this.contractOwnerOrgId;
  }
}

export interface IShipmentPreviewGetServiceDTM {
  isEnd: boolean
  shipmentList?: IShipmentPreviewDTM[]
}

export class ShipmentPreviewGetServiceDTM extends BaseDTM<IShipmentPreviewGetServiceDTM> {
  @IsDefined()
  @IsBoolean()
  isEnd: boolean

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => ShipmentPreviewDTM)
  shipmentList?: ShipmentPreviewDTM[];
}
