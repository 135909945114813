import React from 'react';

import { FlexCol } from 'app-wrapper/view/guideline';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';

export const SkeletonView = () => (
  <FlexCol
    style={{
      gap: 8,
    }}
  >
    <SkeletonSection height="240px" />
    <SkeletonSection height="180px" />
    <SkeletonSection height="297px" />
    <SkeletonSection height="201px" />
  </FlexCol>
);
