import { css } from 'styled-components';

export const ButtonCss = css`
  width: 100%;
  height: 100%;
  color: #ffffff;
  background: #333333;
  border-radius: 4px;
  border-color: #00000000;
  position: relative;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: #697585;
  }
  &:active {
    background-color: #202c3c;
  }
  &:disabled {
    background-color: #f4f5f8;
    color: #d1d7e2;
    cursor: not-allowed;
    border: 1px solid #DEE1E9;
  }
`;

export const TabsCss = css`
  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    width: 100%;
    text-align: center;
  }

  .ant-tabs-tab-btn {
    width: 100%;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
`;

export const RadioGroupCss = css`
  width: 100%;
  display: flex;
  justify-content: center;

  .ant-radio-button-wrapper {
    width: 100%;
    text-align: center;
  }
`;

export const CustomScrollbar = css`
  scrollbar-width: thin;
  scrollbar-color: #d1d7e2 #f0f2fc;
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f2fc;
    border-radius: 5px;
    border-left: 3px solid #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d1d7e2;
    border-radius: 5px;
    border-left: 3px solid #ffffff;
  }

  overflow-y: hidden !important;
  overflow-x: hidden !important;

  &:hover {
    overflow-y: auto !important;
  }
`;

export const HiddenScrollbar = css`
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ShadowHeaderTop = css`
  box-shadow: 0px -3px 10px 1px rgba(0, 0, 0, 0.04);
`;

export const AlertBlockCss = css`
  .ant-alert-message {
    color: #202c3c;
  }
`;

export const InputCss = css`
  min-height: 32px;

  @media screen and (min-width: 1600px) {
    min-height: 40px;
  }
`;

export const SelectCss = css`
  min-height: 32px;

  @media screen and (min-width: 1600px) {
    min-height: 40px;
  }
`;

export const TooltipLocCss = css`
  font-size: 12px;
  border-radius: 2px;

  .ant-tooltip-inner {
    background-color: ${({ theme }) => theme.themesColors.neutralBranding9};
    font-size: 12px;
    font-weight: 400;
  }
  .ant-tooltip-arrow {
    bottom: -13px;
  }
  .ant-tooltip-content {
    position: relative;
    max-height: 195px;
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  .ant-tooltip-inner {
    ${CustomScrollbar}

    overflow-y: auto;
    scrollbar-gutter: auto;
    max-height: 200px;
    scrollbar-color: #d1d7e2 ${({ theme }) => theme.themesColors.neutralBranding9};

    &::-webkit-scrollbar {
      width: 8px;
    }
  }
  &.ant-tooltip-placement-bottom {
    .ant-tooltip-inner {
      margin-top: 8px;
    }
  }

  .ant-tooltip-placement-top .ant-tooltip-arrow, .ant-tooltip-placement-topLeft .ant-tooltip-arrow {
    bottom: -13px;
  }
`;

export const DataPickerHoverRangeCss = css`
  // Select DataRange bg
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background-color: #f0f2fc;
  }
  .ant-picker-cell-range-hover::before,
  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after,
  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
  .ant-picker-cell-range-hover-end::before {
    background: #D3D7F5 !important;
  }
`;
