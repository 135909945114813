import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { ContactSectionComponent } from 'shipment-operations/view/components/BookingDrayageDrawer/components/PickupDeliveryStep/components';

export const ContactSection = React.memo(() => {
  const contact = useSelector(R.selectors.bookingWizard.getCargoSupplierContact);
  const isRequiredErrorVisible = useSelector(R.selectors.bookingWizard.getIsCargoSupplierRequiredErrorVisible);

  return (
    <ContactSectionComponent
      contactPerson={contact?.fullName}
      setContactPerson={UC.bookingWizard.setCargoSupplierContactField('fullName')}
      email={contact?.email}
      setEmail={UC.bookingWizard.setCargoSupplierContactField('email')}
      phone={contact?.phone}
      setPhone={UC.bookingWizard.setCargoSupplierContactField('phone')}
      additionalPhone={contact?.phone2}
      setAdditionalPhone={UC.bookingWizard.setCargoSupplierContactField('phone2')}
      isRequiredErrorVisible={isRequiredErrorVisible}
    />
  );
});
