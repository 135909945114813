import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import EditOutlined from '@ant-design/icons/EditOutlined';

import { hasAccess } from 'app-wrapper/utils';
import { ShipmentScheduleDTM } from 'shipment-operations/models/dtm';
import {
  ContentGap,
  ContentSection,
  GapVerticalContainerStyled,
  InfoCardComponent,
  MissingTextValue,
} from 'app-wrapper/view/guideline';
import { EDrayageLoadingSide, PermissionAttributePolicy } from 'shipment-operations/constants';
import { ContentSectionContainer } from 'app-wrapper/view/guideline/ContentSectionContainer';
import { ServicesDivider, Button } from 'app-wrapper/view/components';

interface ISupplierScheduleComponentProps {
  schedule?: ShipmentScheduleDTM;
  drayageSide: EDrayageLoadingSide;
  cargoReadyDatePermission?: PermissionAttributePolicy;
  openEditDrawer: (drayageSide: EDrayageLoadingSide, scheduleId?: number) => void;
}

export const SupplierScheduleComponent: FC<ISupplierScheduleComponentProps> = ({
  schedule,
  drayageSide,
  cargoReadyDatePermission,
  openEditDrawer,
}) => {
  const { t } = useTranslation();

  const isAbleToEdit = hasAccess(cargoReadyDatePermission, PermissionAttributePolicy.WRITE);

  const handleEdit = useCallback(() => {
    openEditDrawer(drayageSide, schedule?.id);
  }, [openEditDrawer, schedule, drayageSide]);

  return (
    <ContentSection>
      <GapVerticalContainerStyled width="100%">
        <ContentGap justifyContent="space-between">
          <ContentSectionContainer>
            {t('Supplier’s Schedule')}
          </ContentSectionContainer>

          {isAbleToEdit ? (
            <Button
              onClick={handleEdit}
              type="dashed"
              size="small"
              icon={<EditOutlined />}
            >
              {t('Edit')}
            </Button>
          ) : null}
        </ContentGap>
        <ContentSectionContainer>
          <ServicesDivider noMargin />
        </ContentSectionContainer>
        <ContentSectionContainer>
          <ContentGap>
            <ContentSectionContainer>
              <InfoCardComponent
                title={t('Cargo Ready Date')}
                value={schedule?.cargoReadyDate ? schedule.cargoReadyDate.getDateDMMMYYYY() : <MissingTextValue />}
              />
            </ContentSectionContainer>
            <ContentSectionContainer>
              <InfoCardComponent
                title={t('Earliest Pickup')}
                value={schedule?.earliestPickupDate ? schedule.earliestPickupDate.getDateDMMMYYYY() : <MissingTextValue />}
              />
            </ContentSectionContainer>
            <ContentSectionContainer>
              <InfoCardComponent
                title={t('Latest Pickup')}
                value={schedule?.latestPickupDate ? schedule.latestPickupDate.getDateDMMMYYYY() : <MissingTextValue />}
              />
            </ContentSectionContainer>
          </ContentGap>
        </ContentSectionContainer>
      </GapVerticalContainerStyled>
    </ContentSection>
  );
};
