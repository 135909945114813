import React, { FC, useCallback } from 'react';

import { BookingWizardContainersErrorsDTM, ContainerDTM } from 'shipment-operations/models/dtm';
import { commonContainersTypesLong } from 'shipment-operations/constants';
import { ContainerIcon } from 'app-wrapper/view/icons';

import {
  ContainerWrap,
  ContainerInformation,
  Divider,
  Number,
  Name,
  Input,
} from './Container.styled';

interface IContainerProps {
  container: ContainerDTM;
  setContainerWeightById: (id: string, weight: number) => void;
  setContainerVolumeById: (id: string, volume: number) => void;
  setContainerReferenceById: (id: string, reference: string) => void;
  errors?: BookingWizardContainersErrorsDTM;
}

export const Container: FC<IContainerProps> = ({
  container,
  setContainerVolumeById,
  setContainerWeightById,
  setContainerReferenceById,
  errors,
}) => {
  const { references, estimatedVolume, estimatedWeight } = container;

  const handleWeightChange = useCallback((e) => {
    setContainerWeightById(container.id, +e.target.value);
  }, [setContainerWeightById, container]);

  const handleVolumeChange = useCallback((e) => {
    setContainerVolumeById(container.id, +e.target.value);
  }, [setContainerVolumeById, container]);

  const handleReferenceChange = useCallback((e) => {
    setContainerReferenceById(container.id, e.target.value);
  }, [setContainerReferenceById, container]);

  const referenceValue = references.length ? references[0].value : '';

  return (
    <ContainerWrap>
      <ContainerInformation>
        <ContainerIcon />

        <Number>
          × 1
        </Number>

        <Divider type="vertical" dashed />

        <Name>
          {commonContainersTypesLong[container.type]}
        </Name>
      </ContainerInformation>

      <Input
        value={referenceValue}
        onChange={handleReferenceChange}
      />

      <Input
        data-class="container-input-weight"
        type="number"
        value={estimatedWeight}
        onChange={handleWeightChange}
        error={!!errors?.estimatedWeight?.message}
      />

      <Input
        data-class="container-input-volume"
        type="number"
        value={estimatedVolume}
        onChange={handleVolumeChange}
        error={!!errors?.estimatedVolume?.message}
      />
    </ContainerWrap>
  );
};
