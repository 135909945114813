import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from 'app-wrapper/i18n/i18n';
import {
  Select,
  Option,
  Tooltip,
} from 'app-wrapper/view/components';
import { EShipmentLoadingMethod } from 'shipment-operations/constants';
import { Wrap, Title } from 'app-wrapper/view/guideline/DoubleRadioSelect/DoubleRadioSelect.styled';

import { InfoIcon, Row } from './LoadingTime.styled';

const OPTIONS = [
  {
    value: 1,
    label: i18n.t('1 hour'),
  },
  {
    value: 2,
    label: i18n.t('2 hour'),
  },
  {
    value: 3,
    label: i18n.t('3 hour'),
  },
  {
    value: 4,
    label: i18n.t('4 hour'),
  },
];

interface ILoadingTimeProps {
  loadingTime?: number;
  loadingMethod?: EShipmentLoadingMethod;
  setLoadingTime: (value: number) => void;
  error: boolean;
}

export const LoadingTimeComponent: FC<ILoadingTimeProps> = ({
  loadingTime,
  setLoadingTime,
  loadingMethod,
  error,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback((value) => {
    setLoadingTime(value);
  }, [setLoadingTime]);

  if (loadingMethod !== EShipmentLoadingMethod.LIVE_LOAD) {
    return null;
  }

  return (
    <Wrap>
      <Row>
        <Title>
          {t('Loading Time')}
        </Title>

        <Tooltip
          placement="top"
          title={t('If loading time exceeds 4 hours, please select drop and pick loading method')}
        >
          <InfoIcon />
        </Tooltip>
      </Row>

      <Select
        style={{
          width: 306,
        }}
        onChange={handleChange}
        value={loadingTime}
        error={error}
      >
        {OPTIONS.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Wrap>
  );
};
