import React, { useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { useCurrentController, useCurrentControllerStore } from 'app-wrapper/view/routes/components';
import {
  ShipmentEditContainerController,
} from 'shipment-operations/controllers/ShipmentEditContainerController/ShipmentEditContainer.controller';
import { ShipmentEditContainerStore } from 'shipment-operations/repository/mobxStores/ShipmentEditContainerStore';
import { DatePicker, Tooltip } from 'app-wrapper/view/components';
import { Moment } from 'moment';

export const FullDepartureDateContainer = observer(() => {
  const controller = useCurrentController<ShipmentEditContainerController>();
  const store = useCurrentControllerStore<ShipmentEditContainerStore>();
  const { fullDepartureDate } = store.state.form;

  const handleOnChange = useCallback((value: Moment | null) => {
    controller.fullDepartureDate().onChange(value);
  }, []);

  const handleOnBlur = useCallback(() => {
    controller.fullDepartureDate().onBlur();
  }, []);

  return (
    <Tooltip mode="danger" title={fullDepartureDate?.errorMessage} visible={Boolean(fullDepartureDate?.hasError)}>
      <DatePicker
        showTime
        format="YYYY-MM-DD HH:mm"
        value={fullDepartureDate?.value?.getDateAsMomentLocalOffset()}
        onChange={handleOnChange}
        onOk={handleOnChange}
        onBlur={handleOnBlur}
      />
    </Tooltip>
  );
});
