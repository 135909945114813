import styled from 'styled-components';
import Radio from 'antd/es/radio';

import { RadioGroupCss } from 'app-wrapper/view/styles/mixins';

export const RadioGroup = styled(Radio.Group).attrs((props: {
  hasError?: boolean
  'data-class'?: string
}) => ({
  hasError: props?.hasError,
  'data-class': props?.['data-class'] || 'radio-group',
}))`
  ${RadioGroupCss}
  label.ant-radio-button-wrapper {
    padding: 0px 17px;

    &:hover {
      border-color: ${({ theme }) => theme?.themesColors?.primaryBranding6} !important;
      ::before {
        border-color: ${({ theme }) => theme?.themesColors?.primaryBranding6} !important;
      }
    }
    span {
      ${({ theme }) => theme?.fonts?.SmallTextFootnoteDescription};
      color: ${({ theme }) => theme?.themesColors?.secondaryDot45};
    }
  }
  label.ant-radio-button-wrapper-checked {
    border-right-color: inherit !important;
    span {
      ${({ theme }) => theme?.fonts?.SmallTextFootnoteDescription};
      color: ${({ theme }) => theme?.themesColors?.neutralBranding10};
    }
  }

  label.ant-radio-button-wrapper:nth-child(1) {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  label.ant-radio-button-wrapper:nth-child(2) {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  label.ant-radio-button-wrapper {
    ${({ theme, hasError }) => hasError && `border-color: ${theme?.themesColors?.characterBrandingDanger} !important`};
    &:not(:first-child)::before {
      ${({ theme, hasError }) => hasError && `background-color: ${theme?.themesColors?.characterBrandingDanger} !important`};
    }
  }
`;
