import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BorderlessTableOutlined from '@ant-design/icons/BorderlessTableOutlined';
import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';

import {
  ContentSection,
  ContentSectionTitle,
  GapVerticalContainerStyled,
  SubPageContent,
  ContentGap,
  InfoCardComponent,
  CompanyLogoComponent,
  GapHorizontalContainerStyled,
  MissingTextValue,
} from 'app-wrapper/view/guideline';
import { ECarrierSCAC } from 'monetary/constants';
import { ContentSectionContainer } from 'app-wrapper/view/guideline/ContentSectionContainer';
import { RoadSvg } from 'app-wrapper/view/icons';
import { Button, ServicesDivider } from 'app-wrapper/view/components';
import { SkeletonSection } from 'app-wrapper/view/components/Skeleton/Skeleton.component';
import { ShippingPartyDTM, CountryDTM } from 'shipment-operations/models/dtm';

import { LoadingControls, SuppliersSchedule } from './components';
import {
  DepartureContainersContainer,
} from './components/DepartureContainers';

interface OverviewComponentProps {
  isLoading: boolean;
  supplier?: ShippingPartyDTM;
  countriesList: CountryDTM[];
}

export const OverviewComponent = ({
  isLoading,
  supplier,
  countriesList,
}: OverviewComponentProps) => {
  const { t } = useTranslation();

  const countryName = useMemo(() => {
    if (!supplier?.address?.country) {
      return '';
    }

    const country = countriesList.find(({ code }) => code === supplier?.address?.country);

    return country?.name || '';
  }, [countriesList, supplier]);

  if (isLoading) {
    return (
      <GapVerticalContainerStyled>
        <SkeletonSection height="238px" />
        <SkeletonSection height="182px" />
        <SkeletonSection height="295px" />
        <SkeletonSection height="199px" />
      </GapVerticalContainerStyled>
    );
  }

  return (
    <GapVerticalContainerStyled>
      <SubPageContent>
        <ContentGap justifyContent="space-between">
          <ContentSectionTitle>
            {t('Supplier’s Details')}
          </ContentSectionTitle>
          <Button
            type="dashed"
            size="small"
            icon={<EditOutlined />}
          >
            {t('Edit')}
          </Button>
        </ContentGap>
        <GapHorizontalContainerStyled>
          <ContentSection style={{ width: '70%' }}>
            <GapVerticalContainerStyled
              style={{
                width: '100%',
                justifyContent: 'flex-start',
              }}
            >
              <ContentSectionContainer>
                {supplier?.company?.name}
              </ContentSectionContainer>
              <ContentSectionContainer>
                <ServicesDivider noMargin />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <ContentGap>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      icon={<EnvironmentOutlined />}
                      title={t('Supplier’s Address')}
                      value={supplier?.address ? (
                        <>
                          {supplier.address.address1} {supplier.address.address2 ? `, ${supplier.address.address2}` : ''}<br />
                          {`${supplier.address.city}, ${supplier.address.state}, ${supplier.address.postalCode}`} <br />
                          {countryName}
                        </>
                      ) : <MissingTextValue />}
                    />
                  </ContentSectionContainer>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      icon={<UserOutlined />}
                      title={t('Contact Person')}
                      value={supplier?.contact ? (
                        <>
                          {supplier?.contact?.fullName}<br />
                          {supplier?.contact?.email}<br />
                          {supplier?.contact?.phone}<br />
                          {supplier?.contact?.phone2 || ''}
                        </>
                      ) : <MissingTextValue />}
                    />
                  </ContentSectionContainer>
                </ContentGap>
              </ContentSectionContainer>
            </GapVerticalContainerStyled>
          </ContentSection>
          <ContentSection style={{ width: '30%' }}>
            <GapVerticalContainerStyled width="100%">
              <ContentSectionContainer>
                {t('References')}
              </ContentSectionContainer>
              <ContentSectionContainer>
                <ServicesDivider noMargin />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('Shipper\'s Reference')}
                  value="ASDAD 516551"
                />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <ServicesDivider noMargin />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('On Behalf Of')}
                  value="ASDAD 516551"
                />
              </ContentSectionContainer>
            </GapVerticalContainerStyled>
          </ContentSection>
        </GapHorizontalContainerStyled>
      </SubPageContent>
      <SubPageContent>
        <ContentSectionTitle>
          {t('Loading')}
        </ContentSectionTitle>
        <GapHorizontalContainerStyled>
          <LoadingControls />
          <SuppliersSchedule />
        </GapHorizontalContainerStyled>
      </SubPageContent>
      <SubPageContent>
        <ContentSectionTitle>
          {t('Carrier\'s Details')}
        </ContentSectionTitle>
        <GapVerticalContainerStyled>
          <ContentSection>
            <ContentGap>
              <ContentSectionContainer>
                <CompanyLogoComponent carrierSCAC={ECarrierSCAC.CMDU} />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('Carrier')}
                  value="Maersk"
                  icon={<RoadSvg />}
                />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <InfoCardComponent
                  title={t('Booking Reference')}
                  value="ASDAD 516551"
                  icon={<BorderlessTableOutlined />}
                />
              </ContentSectionContainer>
            </ContentGap>
          </ContentSection>
          <ContentSection>
            <GapVerticalContainerStyled width="100%">
              <ContentSectionContainer>
                {t('Cutoff')}
              </ContentSectionContainer>
              <ContentSectionContainer>
                <ServicesDivider noMargin />
              </ContentSectionContainer>
              <ContentSectionContainer>
                <ContentGap>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      title={t('Latest Return (Port Cutoff)')}
                      value="10 Nov 2025, 18:00"
                    />
                  </ContentSectionContainer>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      title={t('VGM Cutoff')}
                      value="10 Nov 2025, 18:00"
                    />
                  </ContentSectionContainer>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      title={t('Documentation Cutoff')}
                      value="10 Nov 2025, 18:00"
                    />
                  </ContentSectionContainer>
                  <ContentSectionContainer>
                    <InfoCardComponent
                      title={t('Dangerous Goods Cutoff')}
                      value="10 Nov 2025, 18:00"
                    />
                  </ContentSectionContainer>
                </ContentGap>
              </ContentSectionContainer>
            </GapVerticalContainerStyled>
          </ContentSection>
        </GapVerticalContainerStyled>
      </SubPageContent>
      <DepartureContainersContainer />
    </GapVerticalContainerStyled>
  );
};
