import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FlagSvg, RectangleRhombusSvg, RoadShipSvg, RoadFlySvg, RoadSvg, TrainSvg,
} from 'app-wrapper/view/icons';
import { dateFormatETA, dateFormatETAmin, parseDurationFromHoursToDays } from 'app-wrapper/utils';
import { Tooltip, InfoCircleOutlinedComponent } from 'app-wrapper/view/components';

import { ChargeCodeLoadTypeEnum } from 'shipment-operations/constants';

import { DetailRoutingDTM } from 'monetary/models/dtm';

import {
  RFQItemDetailDivRoutingContentOrigin,
  RFQItemDetailDivRoutingContentOriginHeader,
  RFQItemDetailDivRoutingContentOriginHeaderIcon,
  RFQItemDetailDivRoutingContentOriginHeaderText,
  RFQItemDetailDivRoutingContentOriginItem,
  RFQItemDetailDivRoutingContentOriginItemFirstDays,
  RFQItemDetailDivRoutingContentOriginItemFirstDaysIcon,
  RFQItemDetailDivRoutingContentOriginItemFirstDaysInfo,
  RFQItemDetailDivRoutingContentOriginItemFirstDaysText,
  RFQItemDetailDivRoutingContentOriginItemFirstFrom,
  RFQItemDetailDivRoutingContentOriginItemFirstName,
  RFQItemDetailDivRoutingContentOriginItemFirstRow,
  RFQItemDetailDivRoutingContentOriginItemFirstSecondName,
  RFQItemDetailDivRoutingContentOriginItemFirstTo,
  RFQItemDetailDivRoutingContentOriginItemFirstToName,
  RFQItemDetailDivRoutingContentOriginItemFirstToSecondName,
  RFQItemDetailDivRoutingContentOriginItemIcon,
  RFQItemDetailDivRoutingContentOriginItems,
  RFQItemDetailDivRoutingContentOriginItemSecondDays,
  RFQItemDetailDivRoutingContentOriginItemSecondEtd,
  RFQItemDetailDivRoutingContentOriginItemSecondEtdName,
  RFQItemDetailDivRoutingContentOriginItemSecondRow,
  RFQItemDetailDivRoutingContentOriginItemSecondTo,
  RFQItemDetailDivRoutingContentOriginItemSecondToName,
} from './RFQItemDetailRouting.styled';

export interface IRFQItemDetailRoutingComponentProps {
  origin?: DetailRoutingDTM[]
  freight?: DetailRoutingDTM[]
  destination?: DetailRoutingDTM[]
}

interface IRenderItemDetailItemData {
  item: DetailRoutingDTM,
  index: number,
  data: DetailRoutingDTM[],
  isLast?: boolean
  isDestination?: boolean
}

export const RFQItemDetailRoutingComponent: FC<IRFQItemDetailRoutingComponentProps> = ({
  origin,
  freight,
  destination,
}) => {
  const { t } = useTranslation();

  const transportTypeIcon = useCallback((transportName) => {
    let icon = null;
    switch (transportName.toLowerCase()) {
      case 'road':
        icon = <RoadSvg />;
        break;
      case 'sea':
        icon = <RoadShipSvg />;
        break;
      case 'water':
        icon = <RoadShipSvg />;
        break;
      case 'fly':
        icon = <RoadFlySvg />;
        break;
      case 'rail':
        icon = <TrainSvg />;
        break;
      default:
        break;
    }
    return icon;
  }, []);
  const renderItemDetailItemData = useCallback((propsItem: IRenderItemDetailItemData) => {
    const {
      item, index, data, isLast,
      isDestination,
    } = propsItem;
    return (
      <RFQItemDetailDivRoutingContentOriginItem
        key={`RFQItemDetailDivRoutingContentOriginItem_${item.from}_${item.from}_${index + 0}`}
      >
        <RFQItemDetailDivRoutingContentOriginItemIcon
          isLast={isLast && index === data.length - 1}
        >
          <RectangleRhombusSvg />
        </RFQItemDetailDivRoutingContentOriginItemIcon>

        <RFQItemDetailDivRoutingContentOriginItems>
          <RFQItemDetailDivRoutingContentOriginItemFirstRow>
            <RFQItemDetailDivRoutingContentOriginItemFirstFrom>
              {t('From')}
            </RFQItemDetailDivRoutingContentOriginItemFirstFrom>
            <RFQItemDetailDivRoutingContentOriginItemFirstName>
              {item.from}
              {item.departureTerminal && (
                <RFQItemDetailDivRoutingContentOriginItemFirstSecondName>
                  {item.departureTerminal}
                </RFQItemDetailDivRoutingContentOriginItemFirstSecondName>
              )}
            </RFQItemDetailDivRoutingContentOriginItemFirstName>
            <RFQItemDetailDivRoutingContentOriginItemFirstDays>
              <RFQItemDetailDivRoutingContentOriginItemFirstDaysIcon>
                {transportTypeIcon(item.transportType)}
              </RFQItemDetailDivRoutingContentOriginItemFirstDaysIcon>
              <RFQItemDetailDivRoutingContentOriginItemFirstDaysText>
                {item?.type === ChargeCodeLoadTypeEnum.DRAYAGE ? `1 ${t('Day(s)')}` : parseDurationFromHoursToDays(item.duration, true)}
              </RFQItemDetailDivRoutingContentOriginItemFirstDaysText>
              <RFQItemDetailDivRoutingContentOriginItemFirstDaysInfo>
                {item.transportType.toLowerCase() === 'sea'
                  && (item?.VesselName || item.VoyageNumber)
                  ? (
                    <Tooltip
                      placement="top"
                      title={(
                        <>
                          {item.VesselName
                            ? (<>{`${t('VesselName')}: ${item.VesselName}`}<br /></>)
                            : ''}
                          {item.VoyageNumber ? `${t('VoyageNumber')}: ${item.VoyageNumber}` : ''}
                        </>
                      )}
                    >
                      {InfoCircleOutlinedComponent}
                    </Tooltip>
                  )
                  : null}
              </RFQItemDetailDivRoutingContentOriginItemFirstDaysInfo>
            </RFQItemDetailDivRoutingContentOriginItemFirstDays>
            <RFQItemDetailDivRoutingContentOriginItemFirstTo>
              {t('To')}
            </RFQItemDetailDivRoutingContentOriginItemFirstTo>
            <RFQItemDetailDivRoutingContentOriginItemFirstToName>
              {item.to}
              {item.arrivalTerminal && (
                <RFQItemDetailDivRoutingContentOriginItemFirstToSecondName>
                  {item.arrivalTerminal}
                </RFQItemDetailDivRoutingContentOriginItemFirstToSecondName>
              )}
            </RFQItemDetailDivRoutingContentOriginItemFirstToName>
          </RFQItemDetailDivRoutingContentOriginItemFirstRow>

          <RFQItemDetailDivRoutingContentOriginItemSecondRow>
            <RFQItemDetailDivRoutingContentOriginItemSecondEtd>
              {t('ETD')}
            </RFQItemDetailDivRoutingContentOriginItemSecondEtd>
            <RFQItemDetailDivRoutingContentOriginItemSecondEtdName>
              <RFQItemDetailDivRoutingContentOriginItemSecondEtd $width="auto">
                {item?.type === ChargeCodeLoadTypeEnum.DRAYAGE ? t('Pickup Date Range') : null}
              </RFQItemDetailDivRoutingContentOriginItemSecondEtd>
              {item?.type === ChargeCodeLoadTypeEnum.DRAYAGE
                ? `${isDestination ? dateFormatETAmin(item.fullPickupTime) : `${dateFormatETAmin(item.pickupFromTime)} - ${dateFormatETAmin(item.pickupToTime)}`}`
                : dateFormatETA(item.etd)}
            </RFQItemDetailDivRoutingContentOriginItemSecondEtdName>
            <RFQItemDetailDivRoutingContentOriginItemSecondDays />
            <RFQItemDetailDivRoutingContentOriginItemSecondTo>
              {t('ETA')}
            </RFQItemDetailDivRoutingContentOriginItemSecondTo>
            <RFQItemDetailDivRoutingContentOriginItemSecondToName>
              <RFQItemDetailDivRoutingContentOriginItemSecondEtd $width="auto">
                {item?.type === ChargeCodeLoadTypeEnum.DRAYAGE ? t('Drop Date Range') : null}
              </RFQItemDetailDivRoutingContentOriginItemSecondEtd>
              <span>
                {item?.type === ChargeCodeLoadTypeEnum.DRAYAGE
                  ? `${isDestination ? dateFormatETAmin(item.deliveryTime) : `${dateFormatETAmin(item.fullReturnFromTime)} - ${dateFormatETAmin(item.fullReturnToTime)}`}`
                  : dateFormatETA(item.eta)}
              </span>
            </RFQItemDetailDivRoutingContentOriginItemSecondToName>
          </RFQItemDetailDivRoutingContentOriginItemSecondRow>
        </RFQItemDetailDivRoutingContentOriginItems>
      </RFQItemDetailDivRoutingContentOriginItem>
    );
  }, [t, transportTypeIcon]);

  const renderItemDetail = useCallback(
    (data: DetailRoutingDTM[], name: string, isLast?: boolean) => (
      <RFQItemDetailDivRoutingContentOrigin>

        <RFQItemDetailDivRoutingContentOriginHeader>
          <RFQItemDetailDivRoutingContentOriginHeaderIcon>
            <FlagSvg />
          </RFQItemDetailDivRoutingContentOriginHeaderIcon>
          <RFQItemDetailDivRoutingContentOriginHeaderText>
            {name}
          </RFQItemDetailDivRoutingContentOriginHeaderText>
        </RFQItemDetailDivRoutingContentOriginHeader>

        {data.map((item, index) => (
          renderItemDetailItemData({
            item,
            index,
            data,
            isLast,
            isDestination: isLast,
          })
        ))}

      </RFQItemDetailDivRoutingContentOrigin>
    ),
    [renderItemDetailItemData],
  );

  return (
    <div>
      {origin?.length ? renderItemDetail(origin, t('Origin')) : ''}
      {freight?.length ? renderItemDetail(freight, t('Freight'), !destination?.length) : ''}
      {destination?.length ? renderItemDetail(destination, t('Destination'), true) : ''}
    </div>
  );
};
