import React, { memo } from 'react';

import { PageComponent } from 'app-wrapper/view/routes/components';
import {
  ShipmentEditContainerController,
} from 'shipment-operations/controllers/ShipmentEditContainerController/ShipmentEditContainer.controller';
import {
  createShipmentEditContainerStore,
} from 'shipment-operations/repository/mobxStores/ShipmentEditContainerStore';
import {
  ShipmentEditContainerContainer,
} from 'shipment-operations/view/drawers/ShipmentEditContainer/ShipmentEditContainer.container';

interface ShipmentEditContainerPageProps {
  id: number
}

export const ShipmentEditContainerPage = memo(({ id }: ShipmentEditContainerPageProps) => (
  <PageComponent
    ControllerClass={ShipmentEditContainerController}
    StoreFactory={() => {
      const store = createShipmentEditContainerStore();
      store.setContainerId(id);
      return store;
    }}
  >
    <ShipmentEditContainerContainer />
  </PageComponent>
));
