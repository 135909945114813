import message from 'antd/es/message';
import { ReferenceDTM } from 'app-wrapper/types';
import i18n from 'i18next';
import { BaseController, controller } from 'proto/BaseController';
import { EShipmentContainerReferenceType } from 'shipment-operations/constants/ShipmentContainerReferenceType.enum';
import { ShipmentContainersUseCase } from 'shipment-operations/usecases';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { apiWorker } from 'app-wrapper/repository/utilsServices';
import { validateShipmentContainerNumber } from 'app-wrapper/utils';
import {
  CargoBaseDTM,
  ContainerCargoDTM,
  ContainerCargoShortItemDTM,
  ContainerDocumentDTM,
  ContainerDTM,
  ContainerNumberDTM,
  ShipmentDeclarationFileDTM,
} from 'shipment-operations/models/dtm';
import {
  CommonContainerTypes,
  ContainerUsualTypes,
  ContainerReeferTypes,
  DocumentType,
} from 'shipment-operations/constants';

import { R } from 'shipment-operations/repository';

import { RouteNames } from 'app-wrapper/constants';

import {
  shipmentContainerNumberValidate, shipmentContainerReferenceValidate,
} from './ShipmentContainers.validate';

const regexContainerNumber = /[a-zA-Z]{4}\d{7}$/;

@controller
export class ShipmentContainersController extends BaseController {
  // add container functionality
  public addContainer = async (shipmentId: string | undefined, type: ContainerReeferTypes | ContainerUsualTypes) => {
    if (!shipmentId) {
      return;
    }

    const containerList = R.selectors.shipmentContainers.getContainerList(this.store.getState());

    const tempId = uuidv4();

    const newContainer = ContainerDTM.fromPlain({
      type,
      id: tempId,
      isInDraft: false,
      isVirtual: true,
      ownContainer: containerList[0].ownContainer,
      cargoItems: [],
      references: [],
      estimatedVolume: 0,
      estimatedWeight: 0,
    });

    if (!newContainer.isValidWithMissingPropSkip()) {
      console.error(newContainer.validateWithoutMissingProp());
    }

    this.dispatch(R.actions.shipmentContainers.addListItem(newContainer));

    this.navigate(RouteNames.SHIPMENT_CONTAINER(shipmentId, tempId));
  }

  public toggleAddContainerModal = () => {
    const isOpen = this.store.getState().shipmentNewContainer.isAddContainerModalOpen;

    this.dispatch(R.actions.shipmentNewContainer.clear());

    this.dispatch(R.actions.shipmentNewContainer.setContainerAddModalState(!isOpen));
  }

  public selectNewContainerType = (type: ContainerReeferTypes | ContainerUsualTypes) => {
    this.dispatch(R.actions.shipmentNewContainer.setContainerType(type));
  }

  public touchNewContainerType = () => {
    this.dispatch(R.actions.shipmentNewContainer.setContainerTypeError(undefined));
  }

  public selectNewContainerQTY = (qty: string) => {
    this.dispatch(R.actions.shipmentNewContainer.setContainerQTY(qty));
  }

  public touchNewContainerQTY = () => {
    this.dispatch(R.actions.shipmentNewContainer.setContainerQTYError(undefined));
  }

  // container list functionality
  public selectContainer = (shipmentId?: string, containerId?: string) => {
    if (!shipmentId) {
      console.error('ShipmentContainersController.selectContainer has got no shipmentId');

      return;
    }

    if (!containerId) {
      console.error('ShipmentContainersController.selectContainer has got no containerId');

      return;
    }

    this.navigate(RouteNames.SHIPMENT_CONTAINER(shipmentId, containerId));
  }

  public setContainerSeaworthyCertificate = (document: ContainerDocumentDTM | null) => {
    this.dispatch(R.actions.shipmentContainers.setSeaworthyCertificate(document));
    this.dispatch(R.actions.shipmentContainers.setSeaworthyCertificateError(false));
  }

  public downloadDocument = (shipmentId: string) => {
    const container = R.selectors.shipmentContainers.getSelectedContainer(this.store.getState());

    if (container && container.seaworthyCertificate) {
      const { seaworthyCertificate } = container;

      R.services.shipmentDocument.getShipmentDocument(+shipmentId, seaworthyCertificate.response.id, seaworthyCertificate.response.name);
    }
  }

  // container header functionality
  public selectActiveTabItem = (type: CommonContainerTypes, shipmentId?: string) => {
    if (!shipmentId) {
      console.error('ShipmentContainersController.selectActiveTabItem has got no shipmentId');

      return;
    }

    const containersLists = R.selectors.shipmentContainers.getLists(this.store.getState());
    if (!containersLists[type].length) {
      console.error('ShipmentContainersController.selectActiveTabItem has no containers in containersLists');

      return;
    }

    const selectedContainer = containersLists[type][0];

    this.navigate(RouteNames.SHIPMENT_CONTAINER(shipmentId, selectedContainer.id));
  }

  // container form functionality
  public uploadContainerData = async (shipmentId?: string, containerId?: string) => {
    if (!shipmentId) {
      console.error('ShipmentContainersController.uploadContainerData has got no shipmentId');

      return;
    }

    if (!containerId) {
      console.error('ShipmentContainersController.uploadContainerData has got no containerId');

      return;
    }

    this.dispatch(R.actions.shipmentContainers.clearFormData());

    this.dispatch(R.actions.shipmentContainers.setSelectedContainer(containerId));

    const selectedContainer = R.selectors.shipmentContainers.getSelectedContainer(this.store.getState());
    if (!selectedContainer) {
      console.error('ShipmentContainersController.uploadContainerData has no selectedContainer - unexpected behavior');

      return;
    }

    this.dispatch(R.actions.shipmentContainers.setSelectedContainersType(selectedContainer.type));

    const containerNumbersList = R.selectors.shipmentContainers.getContainerNumberListForSelectedContainer(this.store.getState());

    if (selectedContainer.isVirtual) {
      if (containerNumbersList?.length === 1) {
        this.dispatch(R.actions.shipmentContainers.setSelectedContainerNumber(containerNumbersList[0].number));
      }

      new ShipmentContainersUseCase(this).updateChangedCargoList();

      return;
    }

    this.dispatch(R.actions.shipmentContainers.setSelectedContainersType(selectedContainer.type));

    if (selectedContainer?.number) {
      this.dispatch(R.actions.shipmentContainers.setSelectedContainerNumber(selectedContainer.number));
    }

    this.dispatch(R.actions.shipmentContainers.setSeaworthyCertificate(selectedContainer?.seaworthyCertificate));

    this.dispatch(R.actions.shipmentContainers.setSelectedSealNumber(selectedContainer?.sealNumber || ''));

    this.dispatch(R.actions.shipmentContainers.setSelectedReference(selectedContainer?.references[0]?.value || ''));

    const declaration = R.selectors.shipmentContainers.getSelectedContainerIMODeclaration(this.store.getState());

    this.dispatch(R.actions.shipmentContainers.setIMODeclaration(declaration));

    new ShipmentContainersUseCase(this).updateChangedCargoList();
  }

  public setIMODeclaration = (document: ShipmentDeclarationFileDTM | null) => {
    this.dispatch(R.actions.shipmentContainers.setIMODeclaration(document));
    this.dispatch(R.actions.shipmentContainers.setIMODeclarationError(false));
  }

  public containerRemove = async (shipmentId?: string) => {
    const container = R.selectors.shipmentContainers.getSelectedContainer(this.store.getState());

    if (!shipmentId) {
      console.error('ShipmentContainersController.deleteContainerState error: no shipmentId');

      return;
    }

    if (!container) {
      console.error('ShipmentContainersController.deleteContainerState error: no containerId');

      return;
    }

    this.dispatch(R.actions.shipmentContainers.setLoading(true));
    this.dispatch(R.actions.shipmentContainers.setRemoveLoading(true));

    if (!container.isVirtual) {
      try {
        await R.services.shipmentContainers.deleteContainer(shipmentId, container.id);
      } catch (e) {
        this.dispatch(R.actions.shipmentContainers.setLoading(false));
        this.dispatch(R.actions.shipmentContainers.setRemoveLoading(false));

        throw e;
      }
    }

    await new ShipmentContainersUseCase(this).updateContainersList(shipmentId);

    await new ShipmentContainersUseCase(this).updateCargoList(shipmentId);

    await new ShipmentContainersUseCase(this).updateContainerNumbers(shipmentId);

    await this.fetchHasHazmatInfo(shipmentId);

    await new ShipmentContainersUseCase(this).updateDeclarations(shipmentId);

    await new ShipmentContainersUseCase(this).updateShipmentShort(shipmentId);

    const containersList = R.selectors.shipmentContainers.getContainerList(this.store.getState());

    if (!containersList[0]) {
      return;
    }

    this.navigate(RouteNames.SHIPMENT_CONTAINER(shipmentId, containersList[0].id));

    this.dispatch(R.actions.shipmentContainers.setRemoveLoading(false));
    this.dispatch(R.actions.shipmentContainers.setLoading(false));

    message.success(i18n.t('Container removed'));
  }

  public toggleCargoToUsed = (cargo: CargoBaseDTM) => {
    const container = R.selectors.shipmentContainers.getSelectedContainer(this.store.getState());
    const changedCargoList = R.selectors.shipmentContainers.getChangedCargoList(this.store.getState());
    const changedCargo = !!changedCargoList.find((item) => item.baseId && cargo.baseId && item.baseId === cargo.baseId);
    const usedCargoData = container?.cargoItems.find((item) => item.cargoId === cargo.baseId);

    if (changedCargo) {
      this.dispatch(R.actions.shipmentContainers.removeCargoFromChangedList(cargo));

      return;
    }

    if (!usedCargoData) {
      const newContainerCargo = ContainerCargoDTM.fromPlain({
        ...cargo,
        id: uuidv4(),
        packagesNumberValue: '0',
        weightValue: '0',
        volumeValue: '0',
      });

      this.dispatch(R.actions.shipmentContainers.addCargoToChangedList(newContainerCargo));

      return;
    }

    const containerCargo = ContainerCargoDTM.fromPlain({
      ...cargo,
      id: usedCargoData.id,
      packagesNumberValue: usedCargoData.packagesNumber,
      weightValue: usedCargoData.weight,
      volumeValue: usedCargoData.volume,
    });

    this.dispatch(R.actions.shipmentContainers.addCargoToChangedList(containerCargo));
  }

  public updateContainer = async (shipmentId?: string, containerId?: string) => {
    if (!shipmentId) {
      console.error('ShipmentContainersController.updateContainer error: no shipmentId');

      return;
    }

    if (!containerId) {
      console.error('ShipmentContainersController.updateContainer error: no container');

      return;
    }

    this.dispatch(R.actions.shipmentContainers.setUpdateLoading(true));

    const selectedContainer = R.selectors.shipmentContainers.getSelectedContainer(this.store.getState());
    const changedCargoList = R.selectors.shipmentContainers.getChangedCargoList(this.store.getState());

    let isError = false;

    if (!selectedContainer) {
      console.error('ShipmentContainersController.updateContainer error: no selectedContainer');

      return;
    }

    if (!this.validateContainerReferenceField()) {
      isError = true;
    }

    changedCargoList.forEach((usedCargo) => {
      if (
        !usedCargo.packagesNumber || !usedCargo.weight
      ) {
        return;
      }

      const packagesNumberFieldError = this.validatePackagesNumberField(
        usedCargo.packagesNumberValue,
        usedCargo.packagesNumber,
      );
      const weightFieldError = this.validateWeightField(usedCargo.weightValue, usedCargo.weight);
      // const volumeFieldError = this.validateVolumeField(usedCargo.volumeValue, usedCargo.volume);
      const volumeFieldError = undefined;

      if (
        packagesNumberFieldError
        || weightFieldError
        || volumeFieldError
      ) {
        isError = true;

        const newUsedCargo = {
          ...usedCargo,
          packagesNumberValueError: packagesNumberFieldError,
          weightValueError: weightFieldError,
          volumeValueError: volumeFieldError,
        };

        this.dispatch(R.actions.shipmentContainers.updateChangedCargo(newUsedCargo));
      }
    });

    const seaworthyDocument = R.selectors.shipmentContainers.getSeaworthyCertificate(this.store.getState());

    if (selectedContainer && selectedContainer.ownContainer && (!seaworthyDocument || typeof seaworthyDocument.response === 'string')) {
      this.dispatch(R.actions.shipmentContainers.setSeaworthyCertificateError(true));

      isError = true;
    }

    const containerNumber = R.selectors.shipmentContainers.getSelectedContainerNumber(this.store.getState());
    if (selectedContainer && selectedContainer.ownContainer && !containerNumber) {
      this.dispatch(R.actions.shipmentContainers.setSelectedContainerNumberFieldError(new Error(i18n.t('basicErrors.REQUIRED_MESSAGE'))));

      isError = true;
    }

    const imoDeclaration = R.selectors.shipmentContainers.getIMODeclarationDocument(this.store.getState());
    // const hasHazmat = R.selectors.shipmentContainers.getHasHazmat(this.store.getState());

    // if (hasHazmat && (!imoDeclaration || (imoDeclaration && typeof imoDeclaration.response === 'string'))) {
    //   this.dispatch(R.actions.shipmentContainers.setIMODeclarationError(true));
    //
    //   isError = true;
    // }

    if (isError) {
      this.dispatch(R.actions.shipmentContainers.setUpdateLoading(false));

      return;
    }

    if (this.onValidContainerNumberManual()) {
      this.dispatch(R.actions.shipmentContainers.setUpdateLoading(false));

      message.error(i18n.t('shipmentContainerErrors.INVALID_CONTAINER_NUMBER'));

      return;
    }

    const cargoItems = changedCargoList.map((item) => {
      if (!item.baseId) {
        console.error(`ShipmentContainersController.updateContainer error: usedCargo with id ${item.id} has no baseId`);
      }

      return (ContainerCargoShortItemDTM.fromPlain({
        id: item.id,
        cargoId: item.baseId || '',
        packagesNumber: item.packagesNumberValue,
        weight: item.weightValue,
        volume: item.volumeValue,
      }));
    });

    const containerNumberValue = R.selectors.shipmentContainers.getSelectedContainerNumber(this.store.getState());
    const sealNumberValue = R.selectors.shipmentContainers.getSealNumber(this.store.getState());
    const referenceValue = R.selectors.shipmentContainers.getReference(this.store.getState()) || '';

    let references: ReferenceDTM[];

    if (selectedContainer.references.length > 0) {
      references = [...selectedContainer.references];
      if (referenceValue) {
        references[0].value = referenceValue;
      } else {
        references.shift();
      }
    } else {
      references = referenceValue
        ? [ReferenceDTM.fromPlain({
          id: uuidv4(),
          type: EShipmentContainerReferenceType.OTHER,
          value: referenceValue,
        })]
        : [];
    }

    const newContainer = ContainerDTM.fromPlain({
      ...selectedContainer,
      number: containerNumberValue || undefined,
      sealNumber: sealNumberValue || '',
      cargoItems,
      seaworthyCertificate: seaworthyDocument,
      references,
    });

    let updatedContainer: ContainerDTM | null = null;

    if (newContainer.isVirtual) {
      try {
        updatedContainer = await R.services.shipmentContainers.postContainer(shipmentId, newContainer);
      } catch (e) {
        this.dispatch(R.actions.shipmentContainers.setUpdateLoading(false));

        throw e;
      }
    } else {
      try {
        updatedContainer = await R.services.shipmentContainers.putContainer(shipmentId, newContainer);
      } catch (e) {
        this.dispatch(R.actions.shipmentContainers.setUpdateLoading(false));

        throw e;
      }
    }

    const shouldDeleteOldIMODeclaration = R.selectors.shipmentContainers.getShouldDeleteOldIMODeclaration(this.store.getState());
    const hasIMODeclarationChanged = R.selectors.shipmentContainers.getHasIMODeclarationChanged(this.store.getState());

    if (shouldDeleteOldIMODeclaration) {
      try {
        await R.services.shipmentDeclaration.deleteContainerDeclaration(+shipmentId, +containerId);
      } catch (e) {
        console.error(e);

        if (e instanceof Error) {
          message.error(e.message);
        }
      }
    }

    if (hasIMODeclarationChanged && imoDeclaration) {
      try {
        await R.services.shipmentDeclaration.createContainerDeclaration(+shipmentId, +containerId, DocumentType.IMO, imoDeclaration);
      } catch (e) {
        console.error(e);

        if (e instanceof Error) {
          message.error(e.message);
        }
      }
    }

    this.dispatch(R.actions.shipmentContainers.setUpdateLoading(false));

    this.dispatch(R.actions.shipmentContainers.clear());

    this.dispatch(R.actions.shipmentContainers.setLoading(true));

    await new ShipmentContainersUseCase(this).updateContainersList(shipmentId);

    await new ShipmentContainersUseCase(this).updateCargoList(shipmentId);

    await new ShipmentContainersUseCase(this).updateContainerNumbers(shipmentId);

    await this.fetchHasHazmatInfo(shipmentId);

    await new ShipmentContainersUseCase(this).updateDeclarations(shipmentId);

    await new ShipmentContainersUseCase(this).updateShipmentShort(shipmentId);

    if (!updatedContainer) {
      return;
    }

    this.navigate(RouteNames.SHIPMENT_CONTAINER(shipmentId, updatedContainer.id));

    const updatedSelectedContainer = R.selectors.shipmentContainers.getSelectedContainer(this.store.getState());
    if (!updatedSelectedContainer) {
      this.dispatch(R.actions.shipmentContainers.setLoading(false));

      console.error('ShipmentContainersController.firstDataUpload error - empty selected container');

      return;
    }

    if (updatedSelectedContainer.number) {
      this.dispatch(R.actions.shipmentContainers.setSelectedContainerNumber(updatedSelectedContainer.number));
    }

    this.dispatch(R.actions.shipmentContainers.setSelectedSealNumber(updatedSelectedContainer.sealNumber));

    new ShipmentContainersUseCase(this).updateChangedCargoList();

    this.dispatch(R.actions.shipmentContainers.setLoading(false));

    message.success(i18n.t('Container updated'));
  }

  public updateChangedCargoPackagesNumber = async (packagesNumber: string, cargoId: string) => {
    const changedCargoList = R.selectors.shipmentContainers.getChangedCargoList(this.store.getState());
    const usedCargo = changedCargoList.find((item) => item.baseId && item.baseId === cargoId);
    const selectedContainer = R.selectors.shipmentContainers.getSelectedContainer(this.store.getState());
    const selectedCargo = selectedContainer?.cargoItems.find((item) => (item.cargoId === cargoId));

    const totalPackagesNumber = Number(usedCargo?.packagesNumber) - Number(usedCargo?.loadSummary?.packagesNumber) + Number(selectedCargo?.packagesNumber || 0);

    if (!usedCargo) {
      return;
    }

    const newUsedCargo = {
      ...usedCargo,
      packagesNumberValue: ShipmentContainersController.validateItemsCounter(packagesNumber, +totalPackagesNumber),
      packagesNumberValueError: undefined,
    };

    this.dispatch(R.actions.shipmentContainers.updateChangedCargo(newUsedCargo));
  }

  public onBlurUsedCargoPackagesNumber = (cargoId: string) => {
    const changedCargoList = R.selectors.shipmentContainers.getChangedCargoList(this.store.getState());
    const usedCargo = changedCargoList.find((item) => item.baseId && item.baseId === cargoId);

    if (!usedCargo || !usedCargo.packagesNumber) {
      return;
    }

    const newUsedCargo = {
      ...usedCargo,
      packagesNumberValueError: this.validatePackagesNumberField(
        usedCargo.packagesNumberValue,
        usedCargo.packagesNumber,
      ),
    };

    this.dispatch(R.actions.shipmentContainers.updateChangedCargo(newUsedCargo));
  }

  public updateChangedCargoWeight = async (weight: string, cargoId: string) => {
    const changedCargoList = R.selectors.shipmentContainers.getChangedCargoList(this.store.getState());
    const usedCargo = changedCargoList.find((item) => item.baseId && item.baseId === cargoId);
    const selectedContainer = R.selectors.shipmentContainers.getSelectedContainer(this.store.getState());
    const selectedCargo = selectedContainer?.cargoItems.find((item) => (item.cargoId === cargoId));

    const totalWeight = Number(usedCargo?.weight) - Number(usedCargo?.loadSummary?.weight) + Number(selectedCargo?.weight || 0);

    if (!usedCargo) {
      return;
    }

    const newUsedCargo = {
      ...usedCargo,
      weightValue: ShipmentContainersController.validateItemsCounter(weight, +totalWeight),
      weightValueError: undefined,
    };

    this.dispatch(R.actions.shipmentContainers.updateChangedCargo(newUsedCargo));
  }

  public onBlurUsedCargoWeight = (cargoId: string) => {
    const changedCargoList = R.selectors.shipmentContainers.getChangedCargoList(this.store.getState());
    const usedCargo = changedCargoList.find((item) => item.baseId && item.baseId === cargoId);

    if (!usedCargo || !usedCargo.weight) {
      return;
    }

    const newUsedCargo = {
      ...usedCargo,
      weightValueError: this.validateWeightField(
        usedCargo.weightValue,
        usedCargo.weight,
      ),
    };

    this.dispatch(R.actions.shipmentContainers.updateChangedCargo(newUsedCargo));
  }

  public updateChangedCargoVolume = async (volume: string, cargoId: string) => {
    const changedCargoList = R.selectors.shipmentContainers.getChangedCargoList(this.store.getState());
    const usedCargo = changedCargoList.find((item) => item.baseId && item.baseId === cargoId);
    const selectedContainer = R.selectors.shipmentContainers.getSelectedContainer(this.store.getState());
    const selectedCargo = selectedContainer?.cargoItems.find((item) => (item.cargoId === cargoId));

    const totalVolume = Number(usedCargo?.volume) - Number(usedCargo?.loadSummary?.volume) + Number(selectedCargo?.volume || 0);

    if (!usedCargo) {
      return;
    }

    const newUsedCargo = {
      ...usedCargo,
      volumeValue: ShipmentContainersController.validateItemsCounter(volume, +totalVolume),
      volumeValueError: undefined,
    };

    this.dispatch(R.actions.shipmentContainers.updateChangedCargo(newUsedCargo));
  }

  public onBlurUsedCargoVolume = (cargoId: string) => {
    const changedCargoList = R.selectors.shipmentContainers.getChangedCargoList(this.store.getState());
    const usedCargo = changedCargoList.find((item) => item.baseId && item.baseId === cargoId);

    if (!usedCargo || !usedCargo.volume) {
      return;
    }

    const newUsedCargo = {
      ...usedCargo,
      weightValueError: this.validateVolumeField(
        usedCargo.volumeValue,
        usedCargo.volume,
      ),
    };

    this.dispatch(R.actions.shipmentContainers.updateChangedCargo(newUsedCargo));
  }

  public updateContainerNumberSelectVolume = (number: string) => {
    this.dispatch(R.actions.shipmentContainers.setSelectedContainerNumber(number));
  }

  public setContainerNumberManualError = (error?: string) => {
    this.dispatch(R.actions.shipmentContainers.setContainerNumberManualError(error));
  }

  public onChangeContainerNumberManual = (value: string) => {
    this.dispatch(R.actions.shipmentContainers.setContainerNumberManual(value));
    this.dispatch(R.actions.shipmentContainers.setContainerNumberManualError(undefined));
  }

  public onValidContainerNumberManual = () => {
    let isError = false;
    const getSelectedContainerNumber = R.selectors.shipmentContainers.getSelectedContainerNumber(this.store.getState());
    const getContainerNumberListForSelectedContainer = R.selectors.shipmentContainers.getContainerNumberListForSelectedContainer(this.store.getState());

    if (getContainerNumberListForSelectedContainer?.find((item) => item.isManual && item.number === getSelectedContainerNumber)
      && !regexContainerNumber.test(getSelectedContainerNumber || '')) {
      isError = true;
    }

    return isError;
  }

  public validateManualContainerNumber = () => {
    const manualContainerNumber = R.selectors.shipmentContainers.getContainerNumberManual(this.store.getState());

    const error = validateShipmentContainerNumber(manualContainerNumber);

    if (error) {
      this.dispatch(R.actions.shipmentContainers.setContainerNumberManualError(error.message));

      return;
    }

    this.dispatch(R.actions.shipmentContainers.setContainerNumberManualError(undefined));
  };

  public onAddContainerNumberManual = () => {
    const containerNumber = R.selectors.shipmentContainers.getContainerNumberList(this.store.getState());
    const getContainerNumberManual = R.selectors.shipmentContainers.getContainerNumberManual(this.store.getState());
    const selectedContainer = R.selectors.shipmentContainers.getSelectedContainer(this.store.getState());

    this.validateManualContainerNumber();

    const containerManualNumberError = R.selectors.shipmentContainers.getContainerNumberManualError(this.store.getState());

    if (!getContainerNumberManual || !selectedContainer?.type || containerManualNumberError) {
      return;
    }

    if (!containerNumber.find((item) => item.number === getContainerNumberManual)) {
      this.dispatch(R.actions.shipmentContainers.setContainerNumberList([
        ...containerNumber || [],
        ContainerNumberDTM.fromPlain({
          number: getContainerNumberManual,
          typeDescription: getContainerNumberManual,
          type: selectedContainer?.type,
          registeredAt: moment(Date.now()),
          isManual: true,
        }),
      ]));
    }
  }

  public updateContainerNumberInputVolume = (number: string) => {
    this.dispatch(R.actions.shipmentContainers.setSelectedContainerNumber(number));
  }

  public onBlurContainerNumberInputField = () => {
    const containerNumber = R.selectors.shipmentContainers.getSelectedContainerNumber(this.store.getState());

    const newError = shipmentContainerNumberValidate(containerNumber);
    if (newError) {
      this.dispatch(R.actions.shipmentContainers.setSelectedContainerNumberFieldError(newError));

      return;
    }

    this.dispatch(R.actions.shipmentContainers.removeSelectedContainerNumberFieldError());
  }

  public updateSealNumberVolume = (number: string) => {
    this.dispatch(R.actions.shipmentContainers.setSelectedSealNumber(number));
  }

  public updateContainerReference = (number: string) => {
    this.dispatch(R.actions.shipmentContainers.setSelectedReference(number));
  }

  public onBlurContainerReferenceField = () => {
    const containerReference = R.selectors.shipmentContainers.getReference(this.store.getState());

    const newError = shipmentContainerReferenceValidate(containerReference);
    if (newError) {
      this.dispatch(R.actions.shipmentContainers.setReferenceFieldError(newError));

      return;
    }

    this.dispatch(R.actions.shipmentContainers.removeReferenceFieldError());
  }

  public discardChangesForContainer = () => {
    const container = R.selectors.shipmentContainers.getSelectedContainer(this.store.getState());
    const changedCargoList = R.selectors.shipmentContainers.getChangedCargoList(this.store.getState());

    changedCargoList.forEach((changedCargoItem) => {
      const containerCargoItem = container?.cargoItems.find((item) => item.cargoId === changedCargoItem.baseId);

      if (!containerCargoItem) {
        this.dispatch(R.actions.shipmentContainers.removeCargoFromChangedList(changedCargoItem));

        return;
      }

      if (containerCargoItem) {
        const newUsedCargo = ContainerCargoDTM.fromPlain({
          ...changedCargoItem,
          packagesNumberValue: containerCargoItem.packagesNumber,
          weightValue: containerCargoItem.weight,
          volumeValue: containerCargoItem.volume,
        });

        this.dispatch(R.actions.shipmentContainers.updateChangedCargo(newUsedCargo));
      }
    });

    if (container?.number) {
      this.dispatch(R.actions.shipmentContainers.setSelectedContainerNumber(container.number));
    } else {
      this.dispatch(R.actions.shipmentContainers.removeSelectedContainerNumber());
    }

    if (container) {
      this.dispatch(R.actions.shipmentContainers.setSeaworthyCertificate(container.seaworthyCertificate));
    }

    const selectedContainerIMODeclaration = R.selectors.shipmentContainers.getSelectedContainerIMODeclaration(this.store.getState());

    this.dispatch(R.actions.shipmentContainers.setIMODeclaration(selectedContainerIMODeclaration));

    this.dispatch(R.actions.shipmentContainers.setIMODeclarationError(false));
    this.dispatch(R.actions.shipmentContainers.setSeaworthyCertificateError(false));

    this.dispatch(R.actions.shipmentContainers.removeSelectedContainerNumberFieldError());

    if (container?.sealNumber) {
      this.dispatch(R.actions.shipmentContainers.setSelectedSealNumber(container.sealNumber));
    } else {
      this.dispatch(R.actions.shipmentContainers.setSelectedSealNumber(''));
    }

    if (container?.references[0]) {
      this.dispatch(R.actions.shipmentContainers.setSelectedReference(container?.references[0].value));
    } else {
      this.dispatch(R.actions.shipmentContainers.setSelectedReference(''));
    }

    new ShipmentContainersUseCase(this).updateChangedCargoList();
  }

  // common functionality
  public firstDataUpload = async (shipmentId?: string, containerId?: string) => {
    if (!shipmentId) {
      console.error('no shipment id for ShipmentContainersController.firstDataUpload call');

      return;
    }

    this.dispatch(R.actions.shipmentContainers.setLoading(true));

    await new ShipmentContainersUseCase(this).updateContainersList(shipmentId);

    await new ShipmentContainersUseCase(this).updateCargoList(shipmentId);

    await new ShipmentContainersUseCase(this).updateContainerNumbers(shipmentId);

    await this.fetchHasHazmatInfo(shipmentId);

    await new ShipmentContainersUseCase(this).updateDeclarations(shipmentId);

    const containers = R.selectors.shipmentContainers.getContainerList(this.store.getState());

    const selectedContainer = containers.find((container) => container.id === containerId);

    if (
      !containerId
      || (containerId && containers[0] && !selectedContainer)
    ) {
      if (!containers[0] || !containers[0].id) {
        return;
      }

      this.navigate(RouteNames.SHIPMENT_CONTAINER(shipmentId, containers[0].id));

      this.dispatch(R.actions.shipmentContainers.setLoading(false));

      return;
    }

    if (!selectedContainer) {
      console.error('ShipmentContainersController.firstDataUpload error: no selected container in containers list');

      this.dispatch(R.actions.shipmentContainers.setLoading(false));

      return;
    }

    this.navigate(RouteNames.SHIPMENT_CONTAINER(shipmentId, selectedContainer.id));

    this.dispatch(R.actions.shipmentContainers.setLoading(false));
  }

  public clearNewContainerState = () => {
    this.dispatch(R.actions.shipmentNewContainer.clear());
  }

  public clearFormDataState = () => {
    this.dispatch(R.actions.shipmentContainers.clearFormData());
  }

  public clearPageState = () => {
    this.dispatch(R.actions.shipmentContainers.clear());
    apiWorker.abortAllRequests();
  }

  public fetchHasHazmatInfo = async (shipmentId: string) => {
    const cargos = await R.services.cargo.getCargos(+shipmentId);

    const hasHazmat = cargos.some(({ isHazmat }) => isHazmat);

    this.dispatch(R.actions.shipmentContainers.setHasHazmat(hasHazmat));
  };

  private validatePackagesNumberField = (
    changedValue: string, availableValue: string,
  ) => {
    if (+changedValue < 1) {
      return new Error('Packages number field error: less then 1 package');
    }

    if (+changedValue > +availableValue) {
      return new Error('Packages number field error: more then max value');
    }

    return undefined;
  }

  private validateContainerReferenceField = () => {
    const containerReference = R.selectors.shipmentContainers.getReference(this.store.getState());

    const newError = shipmentContainerReferenceValidate(containerReference);
    if (newError) {
      this.dispatch(R.actions.shipmentContainers.setReferenceFieldError(newError));

      return false;
    }

    return true;
  }

  private validateWeightField = (changedValue: string, availableValue: string) => {
    if (+changedValue < 1) {
      return new Error('Weight field error: less then 1');
    }

    if (+changedValue > +availableValue) {
      return new Error('Weight field error: more then max value');
    }

    return undefined;
  }

  private validateVolumeField = (changedValue: string, availableValue: string | undefined) => {
    if (availableValue === undefined) {
      return new Error('Volume field error: no volume limit'); // what to do with it?
    }

    if (+changedValue < 1) {
      return new Error('Volume field error: less then 1');
    }

    if (+changedValue > +availableValue) {
      return new Error('Volume field error: more then max value');
    }

    return undefined;
  }

  private static validateItemsCounter(count: string, totalCount: number) {
    if (count === '') {
      return '';
    }

    if (+count < 0) {
      return String(0);
    }

    if (+count > totalCount) {
      return String(totalCount);
    }

    return String(parseInt(count, 10));
  }
}
