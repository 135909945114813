import React, {
  FC,
  useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined';

import {
  Option, OptGroup,
} from 'app-wrapper/view/components';

import { useNotFoundContent } from 'monetary/hooks';
import { FreightFromLocationStateDTM, FreightSelectFieldDTM, IFreightSelectFieldDTM } from 'monetary/models/dtm';
import { IDefaultFieldErrors } from 'monetary/models/errors';

import {
  AddressAvailable,
  AddressAvailableIcon,
  AddressAvailableText,
  AddressAvailableWrap,
  AddressBlockSelect,
  AddressItemIcon,
  AddressItemWrap,
} from './AddressBlock.styled';

export interface IAddressBlockComponentProps {
  isAllDisabled?: boolean
  isIncludeRelatedPort?: boolean
  onChangeDoorAutocomplete: (value: string) => void
  doorAutocompleteStatus: string
  doorAutocompleteOptions: {
    label: string
    value: string
    key: string
  }[]
  toPort: boolean
  onChangeAddress: (value: IFreightSelectFieldDTM) => void
  onFocusAddress: () => void
  onBlurAddress: () => void
  onClearAddress: () => void
  getRFQPortAutocomplete: (value: string) => void
  origin?: FreightFromLocationStateDTM
  locationPortStatus: string
  onKeyPressAddress: (key: string) => void
  errors: {
    isPort?: IDefaultFieldErrors
    location?: IDefaultFieldErrors
    datePort?: {
      error?: IDefaultFieldErrors
    }
    dateDoor?: {
      error?: IDefaultFieldErrors
    }
  }
  isSubmitVisited?: boolean
  placeholder?: string
  dataClass?: string
  incotermsTrade?: string
  options: FreightSelectFieldDTM[]
  disabledOptions: FreightSelectFieldDTM[]
}

const debounceTime = 300;
const debounceTimeGoogle = debounceTime + 600;

export const AddressBlockSelectComponent: FC<IAddressBlockComponentProps> = ((props) => {
  const { t } = useTranslation();
  const {
    isAllDisabled,
    onChangeDoorAutocomplete,
    doorAutocompleteOptions,
    doorAutocompleteStatus,
    onChangeAddress,
    onFocusAddress,
    onBlurAddress,
    onClearAddress,
    origin,
    errors,
    onKeyPressAddress,
    isSubmitVisited,
    toPort,
    getRFQPortAutocomplete,
    locationPortStatus,
    placeholder,
    dataClass,
    options,
    disabledOptions,
  } = props;

  const debounceAddressDoor = useMemo(() => debounce((newValue) => {
    onChangeDoorAutocomplete(newValue);
  }, debounceTimeGoogle), [onChangeDoorAutocomplete]);
  const throttledAddressDoor = useMemo(() => throttle((newValue) => {
    debounceAddressDoor(newValue);
  }, 40), [debounceAddressDoor]);

  const notFoundDoorContent = useNotFoundContent(doorAutocompleteStatus, !!doorAutocompleteOptions.length);

  const onClearAddressHandler = useCallback(
    () => {
      onClearAddress();
    },
    [onClearAddress],
  );

  const onChangeDoorAddressHandler = useCallback(
    (value) => {
      if (value) {
        onChangeAddress({
          code: value,
          description: '',
        });
      }
    },
    [onChangeAddress],
  );

  const debounceAddress = useMemo(() => debounce((newValue) => {
    getRFQPortAutocomplete(newValue);
  }, debounceTime), [getRFQPortAutocomplete]);
  const throttledAddress = useMemo(() => throttle((newValue) => {
    debounceAddress(newValue);
  }, 40), [debounceAddress]);

  const onSearchPortAddressHandler = useCallback(
    (value) => {
      throttledAddress(value?.trim());
    },
    [throttledAddress],
  );

  const notFoundContent = useNotFoundContent(locationPortStatus, !!origin?.locationValues?.length);

  const onKeyPressPortAddressHandler = useCallback((key: React.KeyboardEvent<HTMLDivElement>) => {
    onKeyPressAddress(key.key);
  }, [onKeyPressAddress]);

  const onChangeAddressHandler = useCallback(
    (value: string, _option: unknown) => {
      if (value) {
        const option = _option as { location: FreightSelectFieldDTM, label: string };
        onChangeAddress({
          code: value,
          description: option.label,
          timezoneId: option.location.timezoneId,
          forbidden: option.location.forbidden,
        });
      }
    },
    [onChangeAddress],
  );

  const isErrorLocation = useMemo(() => !!errors.location?.message
    && (errors.location?.isVisited || isSubmitVisited), [errors.location?.message, errors.location?.isVisited, isSubmitVisited]);

  const selectProps = toPort
    ? {
      placeholder: `${t('unlocoPlaceholder')} *`,
      onSearch: onSearchPortAddressHandler,
      notFoundContent,
      onKeyDown: onKeyPressPortAddressHandler,
      onChange: onChangeAddressHandler,
    } : {
      placeholder,
      onKeyDown: onKeyPressPortAddressHandler,
      onSearch: throttledAddressDoor,
      notFoundContent: notFoundDoorContent,
      onChange: onChangeDoorAddressHandler,
    };

  const notForbiddenOptions = useMemo(
    () => origin?.locationValues?.filter((value) => !value.forbidden) || [], [origin?.locationValues],
  );
  const forbiddenOptions = useMemo(
    () => origin?.locationValues?.filter((value) => value.forbidden) || [], [origin?.locationValues],
  );

  return toPort ? (
    <AddressBlockSelect
      isError={isErrorLocation}
      data-class={dataClass}
      value={origin?.location?.code || null}
      showSearch
      allowClear={!!origin?.location?.code}
      autoClearSearchValue={false}
      disabled={isAllDisabled}
      onClear={onClearAddressHandler}
      onFocus={onFocusAddress}
      onBlur={onBlurAddress}
      showArrow={false}
      filterOption={false}
      {...selectProps}
    >
      {options.map((locations, index) => (
        <Option key={`origin_locationValues_${locations.code || index + 1}`} value={locations.code || ''} location={locations}>{locations.description}</Option>
      ))}
      {disabledOptions.length > 0
        ? (
          <OptGroup
            disabled
            label={t('Currently not supported countries')}
          >
            {disabledOptions.map(({ code, description, timezoneId }) => (
              <Option key={code || ''} disabled value={code || ''} timezoneId={timezoneId}>{description}</Option>
            ))}
          </OptGroup>
        )
        : null}
    </AddressBlockSelect>
  ) : (
    <AddressBlockSelect
      value={origin?.location?.code || null}
      data-class="originAddressCode"
      isError={isErrorLocation}
      showSearch
      allowClear={!!origin?.location?.code}
      autoClearSearchValue={false}
      disabled={isAllDisabled}
      onFocus={onFocusAddress}
      onBlur={onBlurAddress}
      onSearch={throttledAddressDoor}
      onClear={onClearAddressHandler}
      placeholder={placeholder}
      notFoundContent={notFoundDoorContent}
      showArrow={false}
      filterOption={false}
      onChange={onChangeDoorAddressHandler}
      onKeyDown={onKeyPressPortAddressHandler}
    >
      {notForbiddenOptions.map(({ code, description, timezoneId }, index) => (
        <Option key={`origin_locationValues_${code || index + 1}`} value={code || ''} timezoneId={timezoneId}>{description}</Option>
      ))}
      {forbiddenOptions.map(({ code, description, timezoneId }, index) => (
        <OptGroup key={`origin_locationValues_${code || index + 1}`}>
          <Option value={code || ''} timezoneId={timezoneId}>{description}</Option>
        </OptGroup>
      ))}
      {!doorAutocompleteOptions.length
        ? (
          <Option key={213123} disabled value={0}>
            <AddressAvailableWrap>
              <AddressAvailable>
                <AddressAvailableIcon>
                  <EnvironmentOutlined />
                </AddressAvailableIcon>
                <AddressAvailableText>
                  {t('Service is available in the USA only')}
                </AddressAvailableText>
              </AddressAvailable>
            </AddressAvailableWrap>
          </Option>
        )
        : doorAutocompleteOptions.map(({ label, key, value }, index) => (
          <Option key={`origin_locationValues_${key || index + 1}`} value={value || ''}>
            <AddressItemWrap>
              <AddressItemIcon>
                <EnvironmentOutlined />
              </AddressItemIcon>
              {label}
            </AddressItemWrap>
          </Option>
        ))}
    </AddressBlockSelect>
  );
});
