import React from 'react';

import { ShipmentEditContainerComponent } from 'shipment-operations/view/drawers/ShipmentEditContainer/ShipmentEditContainer.component';
import { ContainerDTM } from 'shipment-operations/models/dtm';
import { observer } from 'mobx-react-lite';
import { useCurrentController, useCurrentControllerStore } from 'app-wrapper/view/routes/components';
import {
  ShipmentEditContainerController,
} from 'shipment-operations/controllers/ShipmentEditContainerController/ShipmentEditContainer.controller';
import { ShipmentEditContainerStore } from 'shipment-operations/repository/mobxStores/ShipmentEditContainerStore';

export const ShipmentEditContainerContainer = observer(() => {
  const controller = useCurrentController<ShipmentEditContainerController>();
  const store = useCurrentControllerStore<ShipmentEditContainerStore>();

  return (
    <ShipmentEditContainerComponent
      isLoading={store.state.isLoading}
      isSubmitting={store.state.isSubmitting}
      container={store.state.container as ContainerDTM}
      onClose={controller.closeDrawer}
      onSave={controller.updateContainer}
    />
  );
});
