import React, {
  FC,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'app-wrapper/view/components';
import { dateFormatFromTo } from 'app-wrapper/utils';

import { AccountDrawer } from 'user-management/view/pages/Account/components';

import { AllRFRQuotes, IRFRQuotesItemProps } from 'monetary/view/components';
import { IAllRequestedQuotesComponentQuotesItemContentDTM, RFRQuotasDTM } from 'monetary/models/dtm/Quotas';

import {
  RFRListPageDiv,
} from './RFRListPage.styled';

interface IRFRListPageComponentProps {
  allQuotesState?: RFRQuotasDTM
  quotaStatus?: string
  isFavorite?: boolean
  isFavoriteDisabled?: boolean
  isLoadingAllRFR?: boolean
  isLoadingAllRFRNextPage?: boolean
  hasNextPage?: boolean
  onRunRFRQuotasPage: () => void
  onScrollNextInfinityPage: () => void
}

export const RFRListPageComponent: FC<IRFRListPageComponentProps> = (props) => {
  const {
    allQuotesState,
    quotaStatus,
    isFavorite,
    isFavoriteDisabled,
    isLoadingAllRFR,
    isLoadingAllRFRNextPage,
    hasNextPage,
    onRunRFRQuotasPage,
    onScrollNextInfinityPage,
  } = props;

  const { t } = useTranslation();
  const title = t('Rates Requests');

  useEffect(() => {
    onRunRFRQuotasPage();
  }, []);

  const allQuotesData = useMemo<IRFRQuotesItemProps[]>(
    (): IRFRQuotesItemProps[] => allQuotesState?.content?.reduce((itemQuotaPrev: IRFRQuotesItemProps[], itemQuota) => {
      const origin: IAllRequestedQuotesComponentQuotesItemContentDTM = {
        name: t('Origin'),
        type: '',
        address: itemQuota.params?.origin.getLocationName() || '',
        date: dateFormatFromTo(itemQuota.params?.fromDate || '', itemQuota.params?.toDate || '', true),
        timezone: 'ETD',
      };
      const destination = {
        name: t('Destination'),
        type: '',
        address: itemQuota.params?.destination.getLocationName() || '',
        date: '',
        timezone: '',
      };

      const containersType: string[] = [...itemQuota.params?.containers || []];

      const hsCodes: string[] = itemQuota.params?.commodities?.map((item) => item.code) || [];

      itemQuotaPrev.push({
        id: itemQuota.id,
        createQuotaDate: itemQuota.getCreateRequestDate(),
        type: '',
        origin,
        destination,
        container: {
          count: itemQuota.params?.containers?.length || 0,
          type: itemQuota.params?.loadType || '',
        },
        customer: {
          name: itemQuota?.customerName || t('N/A'),
          email: itemQuota?.createdBy,
        },
        status: itemQuota?.status,
        containersType,
        hsCodes,
        quotasCount: 0,
        favoriteQuotasCount: 0,
      });

      return itemQuotaPrev;
    }, []) || [], [allQuotesState, t],
  );

  const stepComponent = useMemo(() => (
    <AllRFRQuotes
      dataElements={allQuotesData}
      isFavorite={isFavorite}
      isFavoriteDisabled={isFavoriteDisabled}
      isLoad={!!isLoadingAllRFR}
      isLoadingAllRFRNextPage={!!isLoadingAllRFRNextPage}
      hasNextPage={!!hasNextPage}
      nextScrollOnHandler={onScrollNextInfinityPage}
      totalElements={allQuotesState?.totalElements || 0}
    />
  ), [allQuotesData,
    isLoadingAllRFR,
    hasNextPage,
    isLoadingAllRFRNextPage,
    isFavoriteDisabled,
    quotaStatus,
    allQuotesState?.totalElements, onScrollNextInfinityPage, isFavorite]);

  const pageHeader = useMemo(() => (
    <PageHeader
      title={title}
      ghost={false}
    />
  ), [title]);

  return (
    <RFRListPageDiv>
      <AccountDrawer />

      {pageHeader}
      {stepComponent}
    </RFRListPageDiv>
  );
};
