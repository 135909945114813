import { DateDtm } from 'app-wrapper/models/dtm';
import { action, computed } from 'mobx';
import { ControllerStore, IControllerState } from 'proto/BaseMobxStore';
import { InputStore } from 'proto/BaseMobxStore/InputStore';
import { DepartureDetailsStatusEnum } from 'shipment-operations/constants';
import { ContainerDTM } from 'shipment-operations/models/dtm';

interface FormInputs {
  number?: InputStore<string>
  carrier?: InputStore<string>
  emptyArrivalDate?: InputStore<DateDtm | undefined>
  fullDepartureDate?: InputStore<DateDtm | undefined>
  pickupReference: InputStore<string>
  dispatchOrder: InputStore<string>
  status?: InputStore<keyof typeof DepartureDetailsStatusEnum>;
}

interface IOverviewControllerState {
  containerId?: number
  container?: ContainerDTM
  isSubmitting: boolean
  form: FormInputs
}

const initialState: IControllerState<IOverviewControllerState> = {
  isLoading: true,
  containerId: undefined,
  container: undefined,
  isSubmitting: false,
  form: {
    number: new InputStore(),
    carrier: new InputStore(),
    emptyArrivalDate: new InputStore(),
    fullDepartureDate: new InputStore(),
    pickupReference: new InputStore(),
    dispatchOrder: new InputStore(),
    status: new InputStore(),
  },
};

export class ShipmentEditContainerStore extends ControllerStore<IControllerState<IOverviewControllerState>> {
  @action
  setContainerId(id: number) {
    this.state.containerId = id;
  }

  setSubmitting(isSubmitting: boolean) {
    this.state.isSubmitting = isSubmitting;
  }

  @action
  setContainer(container?: ContainerDTM) {
    this.state.container = container;
  }

  @action
  setForm(formInputs: FormInputs) {
    this.state.form = formInputs;
  }

  @computed
  get hasError() {
    return Object.values(this.state.form).some((input: InputStore<any>) => input.hasError);
  }

  get containerId() {
    return this.state.containerId;
  }
}

export const createShipmentEditContainerStore = (_initialState?: IControllerState<IOverviewControllerState>) => new ShipmentEditContainerStore(_initialState || initialState);
