import React from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';

import { ContainersDetailsComponent } from './ContainersDetails.component';

const ContainersDetailsContainer = () => {
  const emptyReleaseDate = useSelector(R.selectors.bookingWizard.getEmptyReleaseDate);
  const isContentUpdating = useSelector(R.selectors.bookingWizard.getIsContentUpdating);
  const containers = useSelector(R.selectors.bookingWizard.getContainers);
  const hasSOC = useSelector(R.selectors.bookingWizard.getHasSOC);
  const emptyReleaseDateError = useSelector(R.selectors.bookingWizard.getEmptyReleaseDateError);
  const containersErrors = useSelector(R.selectors.bookingWizard.getContainersErrors);

  return (
    <ContainersDetailsComponent
      isPending={isContentUpdating}
      containers={containers}
      date={emptyReleaseDate}
      onChange={UC.bookingWizard.setEmptyReleaseDate}
      hasSOC={hasSOC}
      error={emptyReleaseDateError}
      setContainerWeightById={UC.bookingWizard.setContainerWeightById}
      setContainerVolumeById={UC.bookingWizard.setContainerVolumeById}
      setContainerReferenceById={UC.bookingWizard.setContainerReferenceById}
      containersErrors={containersErrors}
    />
  );
};

export { ContainersDetailsContainer as ContainersDetails };
