import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from 'app-wrapper/view/components';

import {
  Layout,
  Title,
  Label,
  Content,
  Column,
  CompanySection,
  AddressSection,
  ContactSection,
  DetailsSection,
  Textarea,
} from './SupplierLayout.styled';

interface ISupplierLayoutProps {
  title: string;
  company?: ReactNode;
  address?: ReactNode;
  contact?: ReactNode;
  loadingDetails?: ReactNode;
}

export const SupplierLayout: FC<ISupplierLayoutProps> = ({
  title,
  company,
  address,
  contact,
  loadingDetails,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Layout>
        <Title>
          {title}
        </Title>

        <Divider style={{ margin: '16px 0' }} dashed />

        <Content>
          <Column>
            <CompanySection>
              {company}
            </CompanySection>

            <AddressSection>
              {address}
            </AddressSection>
          </Column>

          <Column>
            <ContactSection>
              {contact}
            </ContactSection>

            <DetailsSection>
              <Title>
                {t('Additional Details')}
              </Title>

              <Label>
                {t('Comment')}
              </Label>

              <Textarea />
            </DetailsSection>
          </Column>
        </Content>
      </Layout>

      {loadingDetails}
    </>
  );
};
