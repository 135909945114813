import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'antd/es/row';

import {
  Col,
  Input,
  Option,
} from 'app-wrapper/view/components';
import { US_COUNTRY_CODE } from 'app-wrapper/constants';
import states from 'app-wrapper/constants/states.json';
import { ICountryListDTM } from 'app-wrapper/models/dtm';
import { Label, LabelGrey, SectionTitle } from 'shipment-operations/view/components/BookingDrayageDrawer/components/PickupDeliveryStep/components/SupplierLayout/SupplierLayout.styled';
import { Select } from 'shipment-operations/view/components/BookingDrayageDrawer/components/PickupDeliveryStep/components/CompanySelect/CompanySelect.styled';

type State = {
  code: string,
  name: string
}

type States = Record<string, State[]>;

interface IAddressSectionProps {
  addressLine1?: string;
  setAddressLine1: (address: string) => void;
  addressLine2?: string;
  setAddressLine2: (address: string) => void;
  country?: string;
  setCountry: (country: string) => void;
  countryState?: string;
  setCountryState: (countryState: string) => void;
  city?: string;
  setCity: (city: string) => void;
  postalCode?: string;
  setPostalCode: (code: string) => void;
  isRequiredErrorVisible?: boolean;
  countries: ICountryListDTM[];
  isOriginPartner: boolean;
}

export const AddressSectionComponent: FC<IAddressSectionProps> = ({
  addressLine1,
  setAddressLine1,
  addressLine2,
  setAddressLine2,
  country,
  setCountry,
  countryState,
  setCountryState,
  city,
  setCity,
  postalCode,
  setPostalCode,
  isRequiredErrorVisible,
  countries,
  isOriginPartner,
}) => {
  const { t } = useTranslation();

  const statesOptions = country ? (states as States)[country] : [] as State[];

  return (
    <>
      <SectionTitle>
        {t('Address')}
      </SectionTitle>

      <Label>
        {t('Address Line 1 *')}
      </Label>

      <Input
        disabled={!isOriginPartner}
        value={addressLine1}
        onChange={({ target }) => setAddressLine1(target.value)}
        error={isRequiredErrorVisible && !addressLine1?.length}
      />

      <Label>
        {t('Address Line 2')}
      </Label>

      <Input
        value={addressLine2}
        onChange={({ target }) => setAddressLine2(target.value)}
      />

      <Row
        style={{
          marginTop: 16,
        }}
        gutter={[8, 16]}
      >
        <Col span={12}>
          <Label>{t('Country')} *</Label>

          <Select
            disabled={!isOriginPartner}
            value={country}
            onChange={setCountry}
            error={isRequiredErrorVisible && !country?.length}
          >
            {countries.map((item) => (
              <Option
                value={item.code}
                key={item.code}
              >
                {item.name}
              </Option>
            ))}
          </Select>
        </Col>

        <Col span={12}>
          <Label>
            {t('State ')}

            <LabelGrey>
              {t('US Required')}
            </LabelGrey>
          </Label>

          <Select
            allowClear
            disabled={!isOriginPartner}
            value={countryState}
            onChange={setCountryState}
            error={isRequiredErrorVisible && !countryState?.length && country === US_COUNTRY_CODE}
          >
            {statesOptions?.map((item) => (
              <Option
                value={item.code}
                key={item.code}
              >
                {item.name}
              </Option>
            ))}
          </Select>
        </Col>

        <Col span={12}>
          <Label>{t('City')} *</Label>

          <Input
            disabled={!isOriginPartner}
            value={city}
            onChange={({ target }) => setCity(target.value)}
            error={isRequiredErrorVisible && !city?.length}
          />
        </Col>

        <Col span={12}>
          <Label>{t('Postal Code')} *</Label>

          <Input
            disabled={!isOriginPartner}
            value={postalCode}
            onChange={({ target }) => setPostalCode(target.value)}
            error={isRequiredErrorVisible && !postalCode?.length}
          />
        </Col>
      </Row>
    </>
  );
};
