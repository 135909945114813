import React, {
  FC, memo, useCallback, useRef, useState,
} from 'react';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { useTranslation } from 'react-i18next';
import flatten from 'lodash/flatten';

import { Collapse, Panel } from 'app-wrapper/view/components/Collapse';
import { ExpandIcon } from 'app-wrapper/view/components/ExpandIcon';
import { CollapseOnChangeHandler } from 'app-wrapper/hooks';
import themesColors from 'app-wrapper/view/themes/themesColors';
import { Vector5Svg } from 'app-wrapper/view/icons';
import { Tooltip } from 'app-wrapper/view/components';
import { normalizationCost } from 'app-wrapper/utils';

import { IDetailBreakdownContainerDTM, IDetailBreakdownDTM, IDetailRoutingContainerStepDTM } from 'monetary/models/dtm';

import {
  BreakdownContentRowContainerItemSummary,
  BreakdownContentRowContainerItemSummaryWrapper,
  BreakdownContentRowItem,
  BreakdownContentRowItemContainerItem,
  BreakdownContentRowItemContainerItemCost,
  BreakdownContentRowItemContainerItemSvg,
  BreakdownContentRowItemContainerItemText,
  BreakdownContentRowItemContainerItemTextInfo,
  BreakdownContentRowItemContainerItemTotalCost,
  BreakdownContentRowItemContainerServicesItem,
  BreakdownContentRowItemContainerServicesItemSvg,
  BreakdownContentRowItemContainerServicesSubItemSvg,
  BreakdownContentRowItemHeader,
  BreakdownContentRowItemHeaderWrapper,
  BreakdownContentRowItemSubHeader,
  BreakdownContentRowItemSubHeaderCollect,
  BreakdownContentRowItemSummary,
  BreakdownContentRowItemSummaryNext,
  BreakdownContentRowItemWrapper,
} from './BreakdownItem.styled';
import { GetStepBreakdownItem } from './components';

const BreakdownItem: FC<IDetailBreakdownDTM> = ({
  fieldName, transportation, summary, fees, service, isLast, isCollect, paymentTerms,
}) => {
  const endVisible = useRef<HTMLElement>(null);
  const { t } = useTranslation();
  const [
    changeCollapseState, setChangeCollapseState,
  ] = useState<{ [key: string]: string[] }>({ [fieldName]: [] });

  const onExpandIcon = useCallback(
    ({ isActive }) => ExpandIcon({
      isActive,
      color: `${isActive ? themesColors.primaryBranding6 : themesColors.neutralBranding7}`,
    }),
    [],
  );

  const onCollapseChangeHandler = useCallback(
    (name: string) => CollapseOnChangeHandler(
      endVisible.current, () => {
        setChangeCollapseState((prev) => ({
          ...prev,
          [name]: prev?.[name]?.length ? [] : [name],
        }
        ));
      },
    ),
    [],
  );
  const getSummary = useCallback(
    (arr) => arr
      .reduce(
        (prevSummary: number, itemContainer: { summary: number }) => prevSummary
          + itemContainer.summary,
        0,
      )
      .toFixed(2),
    [],
  );

  const getSummaryContainer = useCallback((containers: IDetailBreakdownContainerDTM[]) => getSummary(
    flatten(containers?.map((itemContainer) => (itemContainer.steps))),
  ), [getSummary]);

  const getSummaryContainerService = useCallback((containers: IDetailBreakdownContainerDTM[], steps: IDetailRoutingContainerStepDTM[]) => getSummary(
    [
      ...flatten(containers?.map((itemContainer) => (itemContainer.steps))),
      ...steps,
    ],
  ), [getSummary]);

  const getCollectInformation = useCallback(() => {
    if (!isCollect) {
      return '';
    }

    if (fieldName?.toLocaleLowerCase() === 'origin') {
      return t('Shipper will be invoiced for charges occurred at Origin');
    }

    if (fieldName?.toLocaleLowerCase() === 'destination') {
      return t('Consignee will be invoiced for charges occurred at Destination');
    }

    return '';
  }, [fieldName, isCollect]);

  return (
    <BreakdownContentRowItemWrapper borderShow={!isLast}>

      <Collapse
        expandIcon={onExpandIcon}
        onChange={onCollapseChangeHandler(fieldName)}
        activeKey={changeCollapseState[fieldName]}
      >
        <Panel
          header={(
            <BreakdownContentRowItemHeaderWrapper>
              <BreakdownContentRowItemSubHeader>
                {paymentTerms}
              </BreakdownContentRowItemSubHeader>
              <BreakdownContentRowItemHeader
                isCollapse={!!changeCollapseState[fieldName].length}
              >
                {fieldName}

                <BreakdownContentRowItemSubHeaderCollect>
                  {getCollectInformation() && (
                    <Tooltip
                      title={getCollectInformation()}
                      placement="top"
                    >
                      <InfoCircleOutlined
                        className="FreightQuoteFormRequest__Row__Commodity__content_title_icon"
                      />
                    </Tooltip>
                  )}
                </BreakdownContentRowItemSubHeaderCollect>
              </BreakdownContentRowItemHeader>
            </BreakdownContentRowItemHeaderWrapper>
          )}
          key={fieldName}
          extra={(
            <BreakdownContentRowItemSummary isCollect={isCollect}>
              $ {normalizationCost(summary, { toFixed: 2, thousandthSeparatorComma: true })}
            </BreakdownContentRowItemSummary>
          )}
        >

          {!!transportation?.containers?.length
            && (
              <BreakdownContentRowItem>
                <Collapse
                  expandIcon={onExpandIcon}
                  onChange={onCollapseChangeHandler('Transportation')}
                  activeKey={changeCollapseState.Transportation}
                >
                  <Panel
                    header={t('Transportation')}
                    key="Transportation"
                    extra={(
                      <BreakdownContentRowItemSummaryNext isCollect={isCollect}>
                        $ {normalizationCost(getSummaryContainer(transportation?.containers), { toFixed: 2, thousandthSeparatorComma: true })}
                      </BreakdownContentRowItemSummaryNext>
                    )}
                  >
                    {transportation?.containers.map((item, index) => (
                      <Collapse
                        key={`Transportation_${item.name}_${index + 1}`}
                        expandIcon={onExpandIcon}
                        onChange={onCollapseChangeHandler(`Transportation_${item.name}_${index}`)}
                        activeKey={changeCollapseState[`Transportation_${item.name}_${index}`]}
                      >
                        <Panel
                          header={item.name}
                          key={`Transportation_${item.name}_${index + 0}`}
                          extra={(
                            <BreakdownContentRowContainerItemSummaryWrapper>
                              <BreakdownContentRowItemContainerItemCost>
                                {item.steps[0].totalUnits
                                  ? (
                                    <>$ {
                                      normalizationCost(
                                        getSummary(item.steps) / Number(item.steps[0].totalUnits),
                                        { toFixed: 2, thousandthSeparatorComma: true },
                                      ) || ''
                                    } x {item.steps[0].totalUnits} {t('unit(s)')}
                                    </>
                                  )
                                  : ''}
                              </BreakdownContentRowItemContainerItemCost>
                              <BreakdownContentRowContainerItemSummary isCollect={isCollect}>
                                $ {normalizationCost(getSummary(item.steps), { toFixed: 2, thousandthSeparatorComma: true })}
                              </BreakdownContentRowContainerItemSummary>
                            </BreakdownContentRowContainerItemSummaryWrapper>
                          )}
                        >
                          {item.steps.map((itemStep, indexStep) => (
                            <BreakdownContentRowItemContainerItem
                              key={`Transportation_container_${itemStep.name}_${indexStep + index}`}
                            >
                              <BreakdownContentRowItemContainerItemSvg>
                                <Vector5Svg />
                              </BreakdownContentRowItemContainerItemSvg>
                              <BreakdownContentRowItemContainerItemText>
                                {itemStep?.name?.toLowerCase() || ''}
                                {!!itemStep.infoSteps?.length && (
                                  <BreakdownContentRowItemContainerItemTextInfo>
                                    <Tooltip
                                      maxWidth="300px"
                                      title={(
                                        <>
                                          {itemStep.infoSteps.map((itemStepsTooltip, indexStepsTooltip) => (
                                            <>
                                              {indexStepsTooltip > 0 && (<br />)}
                                              {itemStepsTooltip}
                                            </>
                                          ))}
                                        </>
                                      )}
                                      placement="top"
                                    >
                                      <InfoCircleOutlined
                                        className="FreightQuoteFormRequest__Row__Commodity__content_title_icon"
                                      />
                                    </Tooltip>
                                  </BreakdownContentRowItemContainerItemTextInfo>
                                )}
                              </BreakdownContentRowItemContainerItemText>
                              <BreakdownContentRowItemContainerItemCost>
                                {!itemStep?.isIncludedBaseRate && <>$ {normalizationCost(itemStep?.cost || 0, { toFixed: 2, thousandthSeparatorComma: true })} x {itemStep.totalUnits} {t('unit(s)')}</>}
                              </BreakdownContentRowItemContainerItemCost>
                              <BreakdownContentRowItemContainerItemTotalCost
                                isCollect={isCollect}
                                isPrimary={itemStep.isIncludedBaseRate}
                              >
                                {itemStep?.isIncludedBaseRate
                                  ? t('Included in Base Rate')
                                  : <>$ {normalizationCost(itemStep?.summary || 0, { toFixed: 2, thousandthSeparatorComma: true })}</>}
                              </BreakdownContentRowItemContainerItemTotalCost>
                            </BreakdownContentRowItemContainerItem>
                          ))}
                        </Panel>
                      </Collapse>
                    ))}

                  </Panel>
                </Collapse>
              </BreakdownContentRowItem>
            )}

          <GetStepBreakdownItem
            fieldName={fieldName}
            nameComponent="Fees"
            dataComponent={fees}
            isCollect={isCollect}
          />

          {!!(service?.containers?.length || service?.other?.steps?.length)
            && (
              <BreakdownContentRowItem>
                <Collapse
                  expandIcon={onExpandIcon}
                  onChange={onCollapseChangeHandler('Services')}
                  activeKey={changeCollapseState.Services}
                >
                  <Panel
                    header={t('Services')}
                    key="Services"
                    extra={(
                      <BreakdownContentRowItemSummaryNext isCollect={isCollect}>
                        $ {normalizationCost(getSummaryContainerService(service?.containers || [], service.other?.steps || []), { toFixed: 2, thousandthSeparatorComma: true })}
                      </BreakdownContentRowItemSummaryNext>
                    )}
                  >
                    {service?.containers?.map((item, index) => (
                      <Collapse
                        key={`Services_${item.name}_${index + 1}`}
                        expandIcon={onExpandIcon}
                        onChange={onCollapseChangeHandler(`Services_${item.name}_${index}`)}
                        activeKey={changeCollapseState[`Services_${item.name}_${index}`]}
                      >
                        <Panel
                          header={item.name}
                          key={`Services_${item.name}_${index + 0}`}
                          extra={(
                            <BreakdownContentRowContainerItemSummaryWrapper>
                              <BreakdownContentRowItemContainerItemCost>
                                $ {
                                  normalizationCost(
                                    getSummary(item?.steps) / item?.steps?.[0]?.totalUnits,
                                    { toFixed: 2, thousandthSeparatorComma: true },
                                  ) || ''
                                } x {item?.steps?.[0]?.totalUnits} {t('unit(s)')}
                              </BreakdownContentRowItemContainerItemCost>
                              <BreakdownContentRowContainerItemSummary isCollect={isCollect}>
                                $ {normalizationCost(getSummary(item?.steps), { toFixed: 2, thousandthSeparatorComma: true })}
                              </BreakdownContentRowContainerItemSummary>
                            </BreakdownContentRowContainerItemSummaryWrapper>
                          )}
                        >
                          {item?.steps.map((itemStep, indexStep) => (
                            <BreakdownContentRowItemContainerItem
                              key={`Services_container_${itemStep?.name}_${indexStep + index}`}
                            >
                              <BreakdownContentRowItemContainerServicesSubItemSvg>
                                <Vector5Svg />
                              </BreakdownContentRowItemContainerServicesSubItemSvg>
                              <BreakdownContentRowItemContainerItemText>
                                {itemStep?.name?.toLowerCase() || ''}
                                {!!itemStep?.infoSteps?.length && (
                                  <BreakdownContentRowItemContainerItemTextInfo>
                                    <Tooltip
                                      title={(
                                        <>
                                          {itemStep?.infoSteps.map((itemStepsTooltip, indexStepsTooltip) => (
                                            <>
                                              {indexStepsTooltip > 0 && (<br />)}
                                              {itemStepsTooltip}
                                            </>
                                          ))}
                                        </>
                                      )}
                                      placement="top"
                                    >
                                      <InfoCircleOutlined
                                        className="FreightQuoteFormRequest__Row__Commodity__content_title_icon"
                                      />
                                    </Tooltip>
                                  </BreakdownContentRowItemContainerItemTextInfo>
                                )}
                              </BreakdownContentRowItemContainerItemText>
                              <BreakdownContentRowItemContainerItemCost>
                                $ {normalizationCost(itemStep?.totalCost || 0, { toFixed: 2, thousandthSeparatorComma: true })} x {itemStep?.totalUnits} {t('unit(s)')}
                              </BreakdownContentRowItemContainerItemCost>
                              <BreakdownContentRowItemContainerItemTotalCost isCollect={isCollect}>
                                $ {normalizationCost(itemStep?.summary || 0, { toFixed: 2, thousandthSeparatorComma: true })}
                              </BreakdownContentRowItemContainerItemTotalCost>
                            </BreakdownContentRowItemContainerItem>
                          ))}
                        </Panel>
                      </Collapse>
                    ))}

                    {service?.other?.steps.map((itemStep, indexStep) => (
                      <BreakdownContentRowItemContainerServicesItem
                        key={`Services_container_${itemStep?.name}_${indexStep + 0}`}
                      >
                        <BreakdownContentRowItemContainerServicesItemSvg>
                          <Vector5Svg />
                        </BreakdownContentRowItemContainerServicesItemSvg>
                        <BreakdownContentRowItemContainerItemText>
                          {itemStep?.name || ''}
                          {!!itemStep?.infoSteps?.length && (
                            <BreakdownContentRowItemContainerItemTextInfo>
                              <Tooltip
                                title={(
                                  <>
                                    {itemStep?.infoSteps.map((itemStepsTooltip, indexStepsTooltip) => (
                                      <>
                                        {indexStepsTooltip > 0 && (<br />)}
                                        {itemStepsTooltip}
                                      </>
                                    ))}
                                  </>
                                )}
                                placement="top"
                              >
                                <InfoCircleOutlined
                                  className="FreightQuoteFormRequest__Row__Commodity__content_title_icon"
                                />
                              </Tooltip>
                            </BreakdownContentRowItemContainerItemTextInfo>
                          )}
                        </BreakdownContentRowItemContainerItemText>
                        <BreakdownContentRowItemContainerItemCost>
                          {itemStep.descriptionText
                            ? itemStep.descriptionText
                            : `$ ${normalizationCost(itemStep?.cost || 0, { toFixed: 2, thousandthSeparatorComma: true })} x ${itemStep.numberOfUnits || 0} ${t('unit(s)')}`}
                        </BreakdownContentRowItemContainerItemCost>
                        <BreakdownContentRowItemContainerItemTotalCost isCollect={isCollect}>
                          $ {normalizationCost(itemStep?.summary || 0, { toFixed: 2, thousandthSeparatorComma: true })}
                        </BreakdownContentRowItemContainerItemTotalCost>
                      </BreakdownContentRowItemContainerServicesItem>
                    ))}

                  </Panel>
                </Collapse>
              </BreakdownContentRowItem>
            )}

        </Panel>
      </Collapse>
    </BreakdownContentRowItemWrapper>
  );
};

const CashedBreakdownItem = memo(BreakdownItem);

export {
  CashedBreakdownItem as BreakdownItem,
};
