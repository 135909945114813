import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { EShippingPartyTypes, ShippingPartyRoutes, TradeType } from 'shipment-operations/constants';
import {
  AddressDTM,
  CompanyDTM,
  ContactDTM,
  ShippingPartyDTM,
  ShippingPartyReference,
  ShippingPartiesPermissions,
  ShippingPartyPermissionsDTM, ShippingPartyAddressDTM,
} from 'shipment-operations/models/dtm';
import { IShippingPartiesState } from 'shipment-operations/models/states';

import { initialFormState } from './createInitialShippingPartyState';

export const initialState: IShippingPartiesState = {
  isLoading: false,
  isShippingPartyListLoading: true,
  isFormDataLoading: false,
  isForceCustomer: false,
  selectedForm: EShippingPartyTypes.HOUSE_SHIPPER,
  currentFormState: initialFormState(),
  changedFormState: initialFormState(),
  errorsFormState: {
    [EShippingPartyTypes.SHIPPER]: {},
    [EShippingPartyTypes.CONSIGNEE]: {},
    [EShippingPartyTypes.NOTIFY_PARTY]: {},
    [EShippingPartyTypes.CUSTOMER]: {},
    [EShippingPartyTypes.HOUSE_SHIPPER]: {},
    [EShippingPartyTypes.HOUSE_CONSIGNEE]: {},
    [EShippingPartyTypes.HOUSE_NOTIFY_PARTY]: {},
    [EShippingPartyTypes.EXPORT_BROKER]: {},
    [EShippingPartyTypes.IMPORT_BROKER]: {},
    [EShippingPartyTypes.SECOND_NOTIFY_PARTY]: {},
    [EShippingPartyTypes.FORWARDER_AGENT]: {},
    [EShippingPartyTypes.DELIVERY_AGENT]: {},
    [EShippingPartyTypes.ACCOUNT_HOLDER]: {},
    [EShippingPartyTypes.ULTIMATE_CUSTOMER]: {},
    [EShippingPartyTypes.OCEAN_CARRIER]: {},
    [EShippingPartyTypes.BOOKING_AGENT]: {},
    [EShippingPartyTypes.ORIGIN_PARTNER_AGENT]: {},
    [EShippingPartyTypes.DESTINATION_PARTNER_AGENT]: {},
    [EShippingPartyTypes.CARGO_RECEIVER]: {},
    [EShippingPartyTypes.CARGO_SUPPLIER]: {},
  },
  companyList: [],
  activeTab: ShippingPartyRoutes.HOUSE_BILL_OF_LADING,
  isSelectCompanyDrawerOpened: false,
  isSelectCompanyDrawerLoading: false,
  isSelectCompanyDrawerInContactMode: false,
  isSelectCompanyDrawerInAddressMode: false,
  addressLine1: undefined,
  taxId: undefined,
  city: '',
  state: '',
  postalCode: '',
  country: '',
  fullName: '',
  email: '',
  phone: '',
  additionalPhone: '',
  isRequiredErrorVisible: false,
  emailError: undefined,
  tradeType: undefined,
  ownCompaniesList: [],
  shippingPartiesPermissions: ShippingPartyPermissionsDTM.getDefaultPermissions(),
};

export const shippingPartiesStore = createSlice({
  name: 'shippingParties',
  initialState,
  reducers: {
    setActiveTab: (state, { payload }: PayloadAction<ShippingPartyRoutes>) => {
      state.activeTab = ShippingPartyRoutes[payload];
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoading: payload,
    }),
    setShippingPartyListLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isShippingPartyListLoading = payload;
    },
    setFormDataLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isFormDataLoading = payload;
    },
    setShippingPartiesPermissions: (state, { payload }: PayloadAction<ShippingPartiesPermissions>) => ({
      ...state,
      shippingPartiesPermissions: payload,
    }),
    setSelectedForm: (state, { payload }: PayloadAction<EShippingPartyTypes>) => {
      state.selectedForm = payload;
    },
    setCurrentShipperFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.SHIPPER] = payload;
      state.changedFormState[EShippingPartyTypes.SHIPPER] = payload;
    },
    setCurrentConsigneeFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.CONSIGNEE] = payload;
      state.changedFormState[EShippingPartyTypes.CONSIGNEE] = payload;
    },
    setCurrentNotifyPartyFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.NOTIFY_PARTY] = payload;
      state.changedFormState[EShippingPartyTypes.NOTIFY_PARTY] = payload;
    },
    setCurrentCustomerData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.CUSTOMER] = payload;
      state.changedFormState[EShippingPartyTypes.CUSTOMER] = payload;
    },
    setCurrentHouseShipperFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.HOUSE_SHIPPER] = payload;
      state.changedFormState[EShippingPartyTypes.HOUSE_SHIPPER] = payload;
    },
    setCurrentHouseConsigneeFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.HOUSE_CONSIGNEE] = payload;
      state.changedFormState[EShippingPartyTypes.HOUSE_CONSIGNEE] = payload;
    },
    setTradeType: (state, { payload }: PayloadAction<TradeType | undefined>) => ({
      ...state,
      tradeType: payload,
    }),
    setCurrentHouseNotifyPartyFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.HOUSE_NOTIFY_PARTY] = payload;
      state.changedFormState[EShippingPartyTypes.HOUSE_NOTIFY_PARTY] = payload;
    },
    setCurrentExportBrokerFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.EXPORT_BROKER] = payload;
      state.changedFormState[EShippingPartyTypes.EXPORT_BROKER] = payload;
    },
    setCurrentImportBrokerFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.IMPORT_BROKER] = payload;
      state.changedFormState[EShippingPartyTypes.IMPORT_BROKER] = payload;
    },
    setCurrentSecondNotifyPartyFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.SECOND_NOTIFY_PARTY] = payload;
      state.changedFormState[EShippingPartyTypes.SECOND_NOTIFY_PARTY] = payload;
    },
    setCurrentForwarderAgentFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.FORWARDER_AGENT] = payload;
      state.changedFormState[EShippingPartyTypes.FORWARDER_AGENT] = payload;
    },
    setCurrentDeliveryAgentFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.DELIVERY_AGENT] = payload;
      state.changedFormState[EShippingPartyTypes.DELIVERY_AGENT] = payload;
    },
    setCurrentAccountHolderFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.ACCOUNT_HOLDER] = payload;
      state.changedFormState[EShippingPartyTypes.ACCOUNT_HOLDER] = payload;
    },
    setCurrentUltimateCustomerFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.ULTIMATE_CUSTOMER] = payload;
      state.changedFormState[EShippingPartyTypes.ULTIMATE_CUSTOMER] = payload;
    },
    setCurrentOceanCarrierFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.OCEAN_CARRIER] = payload;
      state.changedFormState[EShippingPartyTypes.OCEAN_CARRIER] = payload;
    },
    setCurrentBookingAgentFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.BOOKING_AGENT] = payload;
      state.changedFormState[EShippingPartyTypes.BOOKING_AGENT] = payload;
    },
    setCurrentCargoSupplierFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.CARGO_SUPPLIER] = payload;
      state.changedFormState[EShippingPartyTypes.CARGO_SUPPLIER] = payload;
    },
    setCurrentCargoReceiverFormData: (state, { payload }: PayloadAction<ShippingPartyDTM>) => {
      state.currentFormState[EShippingPartyTypes.CARGO_RECEIVER] = payload;
      state.changedFormState[EShippingPartyTypes.CARGO_RECEIVER] = payload;
    },
    setCompany: (state, { payload }: PayloadAction<CompanyDTM>) => ({
      ...state,
      changedFormState: {
        ...state.changedFormState,
        [state.selectedForm]: {
          ...state.changedFormState[state.selectedForm],
          company: payload,
        },
      },
    }),
    setIsSelectCompanyDrawerOpened: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isSelectCompanyDrawerOpened: payload,
    }),
    setIsSelectCompanyDrawerInContactMode: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isSelectCompanyDrawerInContactMode: payload,
    }),
    setIsSelectCompanyDrawerInAddressMode: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isSelectCompanyDrawerInAddressMode: payload,
    }),
    setIsSelectCompanyDrawerLoading: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isSelectCompanyDrawerLoading: payload,
    }),
    setEmailError: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      emailError: payload,
    }),
    setCompanyErrorForSelectedForm: (state, { payload }: PayloadAction<Error>) => {
      const { selectedForm } = state;

      state.errorsFormState[selectedForm].company = payload;
    },
    clearCompanyErrorForSelectedForm: (state) => {
      const { selectedForm } = state;

      state.errorsFormState[selectedForm].company = undefined;
    },
    setAddress: (state, { payload }: PayloadAction<ShippingPartyAddressDTM | undefined>) => ({
      ...state,
      changedFormState: {
        ...state.changedFormState,
        [state.selectedForm]: {
          ...state.changedFormState[state.selectedForm],
          address: payload,
        },
      },
    }),
    setAddressErrorForSelectedForm: (state, { payload }: PayloadAction<Error>) => {
      const { selectedForm } = state;

      state.errorsFormState[selectedForm].address = payload;
    },
    clearAddressErrorForSelectedForm: (state) => {
      const { selectedForm } = state;

      state.errorsFormState[selectedForm].address = undefined;
    },
    setContact: (state, { payload }: PayloadAction<ContactDTM | undefined>) => ({
      ...state,
      changedFormState: {
        ...state.changedFormState,
        [state.selectedForm]: {
          ...state.changedFormState[state.selectedForm],
          contact: payload,
        },
      },
    }),
    setContactErrorForSelectedForm: (state, { payload }: PayloadAction<Error>) => {
      const { selectedForm } = state;

      state.errorsFormState[selectedForm].contact = payload;
    },
    clearContactErrorForSelectedForm: (state) => {
      const { selectedForm } = state;

      state.errorsFormState[selectedForm].contact = undefined;
    },
    clearErrorsForSelectedForm: (state) => {
      const { selectedForm } = state;

      state.errorsFormState[selectedForm] = initialState.errorsFormState[selectedForm];
    },
    addNewReferences: (state, { payload }: PayloadAction<ShippingPartyReference>) => {
      const { selectedForm } = state;

      const newReferencesList = state.changedFormState[selectedForm].references;
      newReferencesList.push(payload);

      state.changedFormState[selectedForm].references = newReferencesList;

      // if (payload.value === '') {
      //   const currentReferencesList = state.currentFormState[selectedForm].references;
      //   currentReferencesList.push(payload);
      //
      //   state.currentFormState[selectedForm].references = currentReferencesList;
      // }
    },
    setIsRequiredErrorVisible: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isRequiredErrorVisible: payload,
    }),
    updateReferences: (state, { payload }: PayloadAction<ShippingPartyReference>) => {
      const { selectedForm } = state;

      const updatedRef = state.changedFormState[selectedForm].references.map((ref) => {
        if (ref.id === payload.id) {
          return payload;
        }

        return ref;
      });

      return ({
        ...state,
        changedFormState: {
          ...state.changedFormState,
          [selectedForm]: {
            ...state.changedFormState[selectedForm],
            references: updatedRef,
          },
        },
      });
    },
    removeReferences: (state, { payload }: PayloadAction<ShippingPartyReference>) => {
      const { selectedForm } = state;

      state.changedFormState[selectedForm].references = state.changedFormState[selectedForm].references.filter(
        (ref) => ref.id !== payload.id,
      );

      if (payload.value === '') {
        state.currentFormState[selectedForm].references = state.currentFormState[selectedForm].references.filter(
          (ref) => ref.id !== payload.id,
        );
      }
    },
    toggleIsSameAsMyOrganisation: (state) => {
      const { selectedForm } = state;
      state.changedFormState[selectedForm].isSameAsMyOrganisation = !state.changedFormState[selectedForm].isSameAsMyOrganisation;
    },
    setIsForceCustomerBlock: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isForceCustomer: payload,
    }),
    setChangeFormIsAgreeNraShow: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      changedFormState: {
        ...state.changedFormState,
        [state.selectedForm]: {
          ...state.changedFormState[state.selectedForm],
          isAgreeNraShow: payload,
        },
      },
    }),
    setChangeFormIsAgreeNraError: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      changedFormState: {
        ...state.changedFormState,
        [state.selectedForm]: {
          ...state.changedFormState[state.selectedForm],
          isAgreeNraError: payload,
        },
      },
    }),
    setChangeFormIsAgreeNra: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      changedFormState: {
        ...state.changedFormState,
        [state.selectedForm]: {
          ...state.changedFormState[state.selectedForm],
          isAgreeNra: payload,
        },
      },
    }),
    setCompanyList: (state, { payload }: PayloadAction<CompanyDTM[]>) => {
      state.companyList = payload;
    },
    setAddressList: (state, { payload }: PayloadAction<AddressDTM[]>) => {
      const { selectedForm } = state;

      state.currentFormState[selectedForm].addressList = payload;
      state.changedFormState[selectedForm].addressList = payload;
    },
    clearAddressDataChanges: (state) => {
      state.changedFormState[state.selectedForm].address = state.currentFormState[state.selectedForm].address;
    },
    setAddressDataToDefault: (state) => ({
      ...state,
      changedFormState: {
        ...state.changedFormState,
        [state.selectedForm]: {
          ...state.changedFormState[state.selectedForm],
          address: initialState.changedFormState[state.selectedForm].address,
          addressList: initialState.changedFormState[state.selectedForm].addressList,
        },
      },
    }),
    setContactsList: (state, { payload }: PayloadAction<ContactDTM[]>) => {
      const { selectedForm } = state;

      state.currentFormState[selectedForm].contactList = payload;
      state.changedFormState[selectedForm].contactList = payload;
    },
    setCurrentFormStateContactsList: (state, { payload }: PayloadAction<boolean>) => {
      const { selectedForm } = state;

      state.currentFormState[selectedForm].isHasCompanyBook = payload;
    },
    clearContactsDataChanges: (state) => {
      state.changedFormState[state.selectedForm].contact = state.currentFormState[state.selectedForm].contact;
    },
    setContactsDataToDefault: (state) => ({
      ...state,
      changedFormState: {
        ...state.changedFormState,
        [state.selectedForm]: {
          ...state.changedFormState[state.selectedForm],
          contact: initialState.changedFormState[state.selectedForm].contact,
          contactList: initialState.changedFormState[state.selectedForm].contactList,
        },
      },
    }),
    setAddressLine1: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      addressLine1: payload,
    }),
    setAddressLine2: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      addressLine2: payload,
    }),
    setTaxId: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      taxId: payload,
    }),
    setCity: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      city: payload,
    }),
    setState: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      state: payload,
    }),
    setPostalCode: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      postalCode: payload,
    }),
    setCountry: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      country: payload,
    }),
    setFullName: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      fullName: payload,
    }),
    setEmail: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      email: payload,
    }),
    setPhone: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      phone: payload,
    }),
    setAdditionalPhone: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      additionalPhone: payload,
    }),
    revertSelectedFormData: (state) => ({
      ...state,
      changedFormState: {
        ...state.changedFormState,
        [state.selectedForm]: {
          ...state.currentFormState[state.selectedForm],
        },
      },
    }),
    clearSelectedFormData: (state) => {
      state.changedFormState[state.selectedForm] = initialState.changedFormState[state.selectedForm];
      state.currentFormState[state.selectedForm] = initialState.currentFormState[state.selectedForm];
    },
    removeShippingPartyByRole: (state, { payload }: PayloadAction<EShippingPartyTypes>) => {
      state.changedFormState[payload] = initialState.changedFormState[payload];
      state.currentFormState[payload] = initialState.currentFormState[payload];
    },
    revertAllChangesAndErrors: (state) => {
      state.changedFormState[EShippingPartyTypes.SHIPPER] = state.currentFormState[EShippingPartyTypes.SHIPPER];
      state.changedFormState[EShippingPartyTypes.CONSIGNEE] = state.currentFormState[EShippingPartyTypes.CONSIGNEE];
      state.changedFormState[EShippingPartyTypes.NOTIFY_PARTY] = state.currentFormState[EShippingPartyTypes.NOTIFY_PARTY];
      state.changedFormState[EShippingPartyTypes.CUSTOMER] = state.currentFormState[EShippingPartyTypes.CUSTOMER];
      state.changedFormState[EShippingPartyTypes.HOUSE_SHIPPER] = state.currentFormState[EShippingPartyTypes.HOUSE_SHIPPER];
      state.changedFormState[EShippingPartyTypes.HOUSE_CONSIGNEE] = state.currentFormState[EShippingPartyTypes.HOUSE_CONSIGNEE];
      state.changedFormState[EShippingPartyTypes.HOUSE_NOTIFY_PARTY] = state.currentFormState[EShippingPartyTypes.HOUSE_NOTIFY_PARTY];
      state.changedFormState[EShippingPartyTypes.EXPORT_BROKER] = state.currentFormState[EShippingPartyTypes.EXPORT_BROKER];
      state.changedFormState[EShippingPartyTypes.IMPORT_BROKER] = state.currentFormState[EShippingPartyTypes.IMPORT_BROKER];
      state.changedFormState[EShippingPartyTypes.SECOND_NOTIFY_PARTY] = state.currentFormState[EShippingPartyTypes.SECOND_NOTIFY_PARTY];
      state.changedFormState[EShippingPartyTypes.FORWARDER_AGENT] = state.currentFormState[EShippingPartyTypes.FORWARDER_AGENT];
      state.changedFormState[EShippingPartyTypes.DELIVERY_AGENT] = state.currentFormState[EShippingPartyTypes.DELIVERY_AGENT];
      state.changedFormState[EShippingPartyTypes.ACCOUNT_HOLDER] = state.currentFormState[EShippingPartyTypes.ACCOUNT_HOLDER];
      state.changedFormState[EShippingPartyTypes.ULTIMATE_CUSTOMER] = state.currentFormState[EShippingPartyTypes.ULTIMATE_CUSTOMER];
      state.changedFormState[EShippingPartyTypes.OCEAN_CARRIER] = state.currentFormState[EShippingPartyTypes.OCEAN_CARRIER];
      state.changedFormState[EShippingPartyTypes.BOOKING_AGENT] = state.currentFormState[EShippingPartyTypes.BOOKING_AGENT];
      state.changedFormState[EShippingPartyTypes.ORIGIN_PARTNER_AGENT] = state.currentFormState[EShippingPartyTypes.ORIGIN_PARTNER_AGENT];
      state.changedFormState[EShippingPartyTypes.DESTINATION_PARTNER_AGENT] = state.currentFormState[EShippingPartyTypes.DESTINATION_PARTNER_AGENT];

      state.errorsFormState[EShippingPartyTypes.SHIPPER] = initialState.errorsFormState[EShippingPartyTypes.SHIPPER];
      state.errorsFormState[EShippingPartyTypes.CONSIGNEE] = initialState.errorsFormState[EShippingPartyTypes.CONSIGNEE];
      state.errorsFormState[EShippingPartyTypes.NOTIFY_PARTY] = initialState.errorsFormState[EShippingPartyTypes.NOTIFY_PARTY];
      state.errorsFormState[EShippingPartyTypes.CUSTOMER] = initialState.errorsFormState[EShippingPartyTypes.CUSTOMER];
      state.errorsFormState[EShippingPartyTypes.HOUSE_SHIPPER] = initialState.errorsFormState[EShippingPartyTypes.HOUSE_SHIPPER];
      state.errorsFormState[EShippingPartyTypes.HOUSE_CONSIGNEE] = initialState.errorsFormState[EShippingPartyTypes.HOUSE_CONSIGNEE];
      state.errorsFormState[EShippingPartyTypes.HOUSE_NOTIFY_PARTY] = initialState.errorsFormState[EShippingPartyTypes.HOUSE_NOTIFY_PARTY];
      state.errorsFormState[EShippingPartyTypes.EXPORT_BROKER] = initialState.errorsFormState[EShippingPartyTypes.EXPORT_BROKER];
      state.errorsFormState[EShippingPartyTypes.IMPORT_BROKER] = initialState.errorsFormState[EShippingPartyTypes.IMPORT_BROKER];
      state.errorsFormState[EShippingPartyTypes.SECOND_NOTIFY_PARTY] = initialState.errorsFormState[EShippingPartyTypes.SECOND_NOTIFY_PARTY];
      state.errorsFormState[EShippingPartyTypes.FORWARDER_AGENT] = initialState.errorsFormState[EShippingPartyTypes.FORWARDER_AGENT];
      state.errorsFormState[EShippingPartyTypes.DELIVERY_AGENT] = initialState.errorsFormState[EShippingPartyTypes.DELIVERY_AGENT];
      state.errorsFormState[EShippingPartyTypes.ACCOUNT_HOLDER] = initialState.errorsFormState[EShippingPartyTypes.ACCOUNT_HOLDER];
      state.errorsFormState[EShippingPartyTypes.ULTIMATE_CUSTOMER] = initialState.errorsFormState[EShippingPartyTypes.ULTIMATE_CUSTOMER];
      state.errorsFormState[EShippingPartyTypes.OCEAN_CARRIER] = initialState.errorsFormState[EShippingPartyTypes.OCEAN_CARRIER];
      state.errorsFormState[EShippingPartyTypes.BOOKING_AGENT] = initialState.errorsFormState[EShippingPartyTypes.BOOKING_AGENT];
      state.errorsFormState[EShippingPartyTypes.ORIGIN_PARTNER_AGENT] = initialState.errorsFormState[EShippingPartyTypes.ORIGIN_PARTNER_AGENT];
      state.errorsFormState[EShippingPartyTypes.DESTINATION_PARTNER_AGENT] = initialState.errorsFormState[EShippingPartyTypes.DESTINATION_PARTNER_AGENT];
    },
    setOwnCompaniesList: (state, { payload }: PayloadAction<CompanyDTM[]>) => ({
      ...state,
      ownCompaniesList: payload,
    }),
    setUpdatedCompany: (state, { payload }: PayloadAction<CompanyDTM>) => {
      const companies = [...state.ownCompaniesList];
      const targetCompanyIndex = companies.findIndex(({ id }) => payload.id === id);

      if (targetCompanyIndex !== -1) {
        companies.splice(targetCompanyIndex, 1, payload);
      }

      state.companyList = companies;
      state.ownCompaniesList = companies;
    },
    clear: () => (initialState),
  },
});

export const shippingPartiesActions = shippingPartiesStore.actions;
export const shippingPartiesReducer = shippingPartiesStore.reducer;
