import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { colors } from 'app-wrapper/view/themes/themesColors';
import {
  EShipmentLoadingMethod,
  EShipmentReceivingMethod,
  LOADING_METHODS,
  RECEIVING_METHODS,
} from 'shipment-operations/constants';
import { DoubleRadioSelect } from 'app-wrapper/view/guideline';

import {
  Wrap,
  Title,
  Divider,
  Content,
} from './LoadingDetails.styled';

const WARNING_STYLES = {
  borderColor: colors.yellow60,
};

interface ILoadingDetailsProps {
  loadingMethod?: EShipmentLoadingMethod;
  receivingMethod?: EShipmentReceivingMethod;
  setLoadingMethod: (value: string) => void;
  setReceivingMethod: (value: string) => void;
  isWarningVisible: boolean;
  isRequiredErrorVisible: boolean;
  isOriginPartner: boolean;
}

export const LoadingDetailsComponent: FC<ILoadingDetailsProps> = ({
  loadingMethod,
  receivingMethod,
  setLoadingMethod,
  setReceivingMethod,
  isWarningVisible,
  isOriginPartner,
  isRequiredErrorVisible,
}) => {
  const { t } = useTranslation();

  if (!isOriginPartner) {
    return null;
  }

  return (
    <Wrap
      style={{
        ...(isWarningVisible && !loadingMethod ? WARNING_STYLES : {}),
      }}
    >
      <Title>
        {t('Loading Details')}
      </Title>

      <Divider dashed />

      <Content>
        <DoubleRadioSelect
          options={LOADING_METHODS}
          value={loadingMethod}
          setValue={setLoadingMethod}
          title={t('Loading Method')}
          error={isRequiredErrorVisible && !loadingMethod}
        />

        {loadingMethod === EShipmentLoadingMethod.LIVE_LOAD ? (
          <DoubleRadioSelect
            options={RECEIVING_METHODS}
            value={receivingMethod}
            setValue={setReceivingMethod}
            title={t('Receiving Method')}
            error={isRequiredErrorVisible && !receivingMethod}
          />
        ) : null}
      </Content>
    </Wrap>
  );
};
