import { BaseController, controller } from 'proto/BaseController';

import { EShipmentLoadingMethod, EShipmentReceivingMethod } from 'shipment-operations/constants';
import { R } from 'shipment-operations/repository';
import { UC as appUC } from 'app-wrapper/controllers';

@controller
export class EditLoadingControlsDrawerController extends BaseController {
  public setLoadingTime = (loadingTime: number) => {
    this.mobxStore.editLoadingControlsDrawer.setLoadingTime(loadingTime);
  };

  public setLoadingMethod = (value: string) => {
    const loadingMethod = value as EShipmentLoadingMethod;

    this.mobxStore.editLoadingControlsDrawer.setLoadingMethod(loadingMethod);

    if (loadingMethod === EShipmentLoadingMethod.DROP_AND_PICKUP) {
      this.mobxStore.editLoadingControlsDrawer.setReceivingMethod(undefined);
      this.mobxStore.editLoadingControlsDrawer.setLoadingTime(undefined);
    }
  };

  public setReceivingMethod = (value: string) => {
    const receivingMethod = value as EShipmentReceivingMethod;

    this.mobxStore.editLoadingControlsDrawer.setReceivingMethod(receivingMethod);
  };

  public saveLoadingControls = async (shipmentId: string) => {
    this.validate();

    const { isRequiredErrorVisible, loadingControls } = this.mobxStore.editLoadingControlsDrawer.state;

    if (isRequiredErrorVisible || !loadingControls) {
      return;
    }

    this.mobxStore.editLoadingControlsDrawer.setLoading(true);

    const updatedLoadingControls = await R.services.loadingControls.editLoadingControl(shipmentId, loadingControls);

    if (updatedLoadingControls) {
      this.mobxStore.shipmentLoadingControlsStore.updateItem(updatedLoadingControls);
    }

    appUC.drawer.closeDrawer();
    this.mobxStore.editLoadingControlsDrawer.setLoading(false);
  }

  private validate = () => {
    const { loadingControls } = this.mobxStore.editLoadingControlsDrawer.state;

    if (!loadingControls) {
      return;
    }

    const {
      loadingMethod,
      loadingTime,
      receivingMethod,
    } = loadingControls;
    let isErrorVisible = false;

    if (!loadingMethod) {
      isErrorVisible = true;
    }

    if (loadingMethod === EShipmentLoadingMethod.DROP_AND_PICKUP) {
      this.mobxStore.editLoadingControlsDrawer.setIsRequiredErrorVisible(false);

      return;
    }

    if (!receivingMethod || !loadingTime) {
      isErrorVisible = true;
    }

    this.mobxStore.editLoadingControlsDrawer.setIsRequiredErrorVisible(isErrorVisible);
  };
}
