import moment from 'moment';

import { BaseController, controller } from 'proto/BaseController';

import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';

import { R as shipmentR } from 'shipment-operations/repository';

import { R } from 'monetary/repository';
import { AdditionalDrawersUseCase } from 'monetary/usecases/AdditionalDrawers.useCase';
import { AdditionalServiceCheckedDTM } from 'monetary/models/dtm/Quotas';

const CALENDAR_DATE_FORMAT = 'YYYY-MM-DD';

@controller
export class AdditionalServicesController extends BaseController {
  public resetAdditionalServiceDrawer = async () => {
    new AdditionalDrawersUseCase(this).resetStateAddAdditionalServicesDrawer();
  }

  public openAdditionalServiceDrawer = async () => {
    new DrawersUseCase(this).openAddAdditionalServicesDrawer();
    const RFQForm = this.store.getState().FreightQuota.currentState.form;

    const services = await R.services.RFQServiceById.getAdditionalService({
      origin: !RFQForm?.origin?.isPort ? 'US' : RFQForm?.origin?.location?.code,
      destination: !RFQForm?.destination?.isPort ? 'US' : RFQForm?.destination?.location?.code,
      fromDate: moment(RFQForm?.origin?.datePort?.earliestDate).format(CALENDAR_DATE_FORMAT),
      toDate: !RFQForm?.origin?.isPort ? moment(RFQForm?.origin?.datePort?.earliestDate).format(CALENDAR_DATE_FORMAT) : moment(RFQForm?.origin?.datePort?.latestDate).format(CALENDAR_DATE_FORMAT),
    });

    const servicesChecked = this.mobxStore.additionalServicesDrawerStore?.state?.servicesChecked;

    const servicesFilter = services?.map((item) => {
      let newService = item;
      servicesChecked?.forEach((itemCheck) => {
        if (itemCheck.key === item.key && itemCheck.isChecked) {
          newService = {
            ...item,
            isChecked: true,
            countDocument: itemCheck.countDocument,
          };
        }
      });

      return newService;
    });

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServices(servicesFilter);

    if (!servicesChecked) {
      this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesChecked(services?.map((item) => ({ ...item })));
    }
  }

  public closeAdditionalServiceDrawer = async () => {
    const services = this.mobxStore.additionalServicesDrawerStore?.state?.services;

    const servicesChecked = this.mobxStore.additionalServicesDrawerStore?.state?.servicesChecked;

    const servicesFilter = services?.map((item) => {
      let newService = item;
      servicesChecked?.forEach((itemCheck) => {
        if (itemCheck.key === item.key) {
          newService = {
            ...item,
            isChecked: itemCheck.isChecked,
            countDocument: itemCheck.countDocument,
          };
        }
      });

      return newService;
    });

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServices(servicesFilter);

    new DrawersUseCase(this).closeDrawer();
  }

  public addAdditionalServiceDrawer = async () => {
    const services = this.mobxStore.additionalServicesDrawerStore?.state?.services;

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesChecked(services?.map((item) => ({ ...item })));

    new DrawersUseCase(this).closeDrawer();
  }

  public onChangeCheckedAdditionalServiceDrawerItemById = (id?: string, isChangeCheckedState?: boolean) => async (value: boolean) => {
    const services = this.mobxStore.additionalServicesDrawerStore?.state?.services;
    let servicesChecked = this.mobxStore.additionalServicesDrawerStore?.state?.servicesChecked;

    const newData = services?.map((item) => {
      if (item.customId === id) {
        return {
          ...item,
          isChecked: value,
        };
      }

      return item;
    });

    if (isChangeCheckedState) {
      servicesChecked = servicesChecked?.map((item) => {
        if (item.customId === id) {
          return {
            ...item,
            isChecked: value,
          };
        }

        return item;
      });

      this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesChecked(servicesChecked);
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateDisabledSubmit(false));

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServices(newData);
  }

  public onChangeCountAdditionalServiceDrawerItemById = (id?: string) => async (value: string) => {
    const services = this.mobxStore.additionalServicesDrawerStore?.state?.services;

    const newData = services?.map((item) => {
      if (item.customId === id) {
        return {
          ...item,
          countDocument: value.replace(/[^0-9]/g, '').slice(0, 2),
        };
      }

      return item;
    });

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateDisabledSubmit(false));

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServices(newData);
  }

  public openAdditionalServiceBookingDrawer = async () => {
    const RFQForm = this.mobxStore.additionalServicesDrawerStore?.state?.bookingRFQFormState;

    if (!RFQForm?.origin?.location?.code) {
      console.error('openAdditionalServiceBookingDrawer: don`t have origin code', RFQForm?.origin?.location?.code);
      return;
    }

    const services = await R.services.RFQServiceById.getAdditionalService({
      origin: RFQForm?.origin?.location?.code,
      destination: RFQForm?.destination?.location?.code,
      fromDate: moment(RFQForm?.origin?.datePort?.earliestDate).format(CALENDAR_DATE_FORMAT),
      toDate: moment(RFQForm?.origin?.datePort?.latestDate).format(CALENDAR_DATE_FORMAT),
    });

    const servicesChecked = this.mobxStore.additionalServicesDrawerStore?.state?.servicesBooking;

    const servicesFilter = services?.map((item) => {
      let newService = item;
      servicesChecked?.forEach((itemCheck) => {
        if (itemCheck.key === item.key && itemCheck.isChecked) {
          newService = {
            ...item,
            isChecked: true,
            countDocument: itemCheck.countDocument,
          };
        }
      });

      return newService;
    });

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBooking(servicesFilter);

    if (!servicesChecked) {
      this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBookingChecked(services?.map((item) => ({ ...item })));
    }

    new DrawersUseCase(this).openAddAdditionalServicesBookingDrawer();
  }

  public onUnCheckedAdditionalServiceBookingDrawerItemById = (id?: string) => async () => {
    const services = this.mobxStore.additionalServicesDrawerStore?.state?.servicesBooking;

    const newData = services?.map((item) => {
      if (item.customId === id) {
        return {
          ...item,
          isChecked: false,
        };
      }

      return item;
    });

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBooking(newData);
    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBookingChecked(newData);
  }

  public closeAdditionalServiceBookingDrawer = async () => {
    const services = this.mobxStore.additionalServicesDrawerStore?.state?.servicesBooking;

    const servicesChecked = this.mobxStore.additionalServicesDrawerStore?.state?.servicesBookingChecked;

    const servicesFilter = services?.map((item) => {
      let newService = item;
      servicesChecked?.forEach((itemCheck) => {
        if (itemCheck.key === item.key) {
          newService = {
            ...item,
            isChecked: itemCheck.isChecked,
            countDocument: itemCheck.countDocument,
          };
        }
      });

      return newService;
    });

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBooking(servicesFilter);

    new DrawersUseCase(this).closeDrawer();
  }

  public addAdditionalServiceBookingDrawer = async () => {
    const services = this.mobxStore.additionalServicesDrawerStore?.state?.servicesBooking;

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBookingChecked(services?.map((item) => ({ ...item })));

    new DrawersUseCase(this).closeDrawer();
  }

  public onChangeCheckedAdditionalServiceBookingDrawerItemById = (id?: string, isChangeCheckedState?: boolean) => async (value: boolean) => {
    const services = this.mobxStore.additionalServicesDrawerStore?.state?.servicesBooking;
    let servicesChecked = this.mobxStore.additionalServicesDrawerStore?.state?.servicesBookingChecked;

    const newData = services?.map((item) => {
      if (item.customId === id) {
        return {
          ...item,
          isChecked: value,
        };
      }

      return item;
    });

    if (isChangeCheckedState) {
      servicesChecked = servicesChecked?.map((item) => {
        if (item.customId === id) {
          return {
            ...item,
            isChecked: value,
          };
        }

        return item;
      });

      this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBookingChecked(servicesChecked);
    }

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBooking(newData);
  }

  public onChangeCountAdditionalServiceBookingDrawerItemById = (id?: string) => async (value: string) => {
    const services = this.mobxStore.additionalServicesDrawerStore?.state?.servicesBooking;

    const newData = services?.map((item) => {
      if (item.customId === id) {
        return {
          ...item,
          countDocument: value.replace(/[^0-9]/g, '').slice(0, 2),
        };
      }

      return item;
    });

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBooking(newData);
  }

  public setStartAdditionalServiceDrawer = async (rateService?: AdditionalServiceCheckedDTM[]) => {
    const RFQForm = this.store.getState().FreightQuota.currentState.form;

    const services = await R.services.RFQServiceById.getAdditionalService({
      origin: RFQForm?.origin?.location?.code,
      destination: RFQForm?.destination?.location?.code,
      fromDate: moment(RFQForm?.origin?.datePort?.earliestDate).format(CALENDAR_DATE_FORMAT),
      toDate: moment(RFQForm?.origin?.datePort?.latestDate).format(CALENDAR_DATE_FORMAT),
    });

    const servicesFilter = services?.map((item) => {
      let newService = item;
      rateService?.forEach((itemCheck) => {
        if (itemCheck.code === item.chargeCode.code) {
          newService = {
            ...item,
            isChecked: true,
            countDocument: `${itemCheck.quantity || 0}`,
          };
        }
      });

      return newService;
    });

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServices(servicesFilter);

    if (rateService?.length) {
      this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesChecked(servicesFilter?.map((item) => ({ ...item })));
      this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesGetQuote(servicesFilter?.map((item) => ({ ...item })));

      this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBooking(servicesFilter?.map((item) => ({ ...item })));
      this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBookingChecked(servicesFilter?.map((item) => ({ ...item })));
    }
  }

  public openShipmentAdditionalServicesDrawer = async (shipmentId: string) => {
    new DrawersUseCase(this).openAddAdditionalServicesShipmentDrawer();
    const RFQForm = this.mobxStore.additionalServicesDrawerStore?.state?.shipmentRFQFormState;
    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesShipment([]);

    const services = await R.services.RFQServiceById.getAdditionalService({
      origin: RFQForm?.origin?.location?.code,
      destination: RFQForm?.destination?.location?.code,
      fromDate: moment(RFQForm?.origin?.datePort?.earliestDate).format(CALENDAR_DATE_FORMAT),
      toDate: moment(RFQForm?.origin?.datePort?.latestDate).format(CALENDAR_DATE_FORMAT),
    });

    const servicesChecked = this.mobxStore.shipmentServices?.state?.services;

    const servicesFilter = services?.map((item) => {
      let newService = item;
      servicesChecked?.forEach((itemCheck) => {
        if (itemCheck.code === item.chargeCode.code && itemCheck.code) {
          newService = {
            ...item,
            isChecked: true,
            countDocument: `${itemCheck.quantity || 1}`,
          };
        }
      });

      return newService;
    });

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerShipmentId(shipmentId);
    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesShipment(servicesFilter);

    if (!servicesChecked) {
      this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesShipmentChecked(services?.map((item) => ({ ...item })));
    }
  }

  public addAdditionalServiceShipmentDrawer = async () => {
    const services = this.mobxStore.additionalServicesDrawerStore?.state?.servicesShipment;
    const shipmentId = this.mobxStore.additionalServicesDrawerStore?.state?.shipmentId;

    if (!shipmentId) {
      return;
    }

    this.mobxStore.additionalServicesDrawerStore.setIsLoading(true);
    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesShipmentChecked(services?.map((item) => ({ ...item })));

    await this.updateAdditionalServices();

    await new AdditionalDrawersUseCase(this).updateShipmentAdditionalPage(shipmentId);

    new DrawersUseCase(this).closeDrawer();
    this.mobxStore.additionalServicesDrawerStore.setIsLoading(false);
  }

  public closeAdditionalServiceShipmentDrawer = async () => {
    const services = this.mobxStore.additionalServicesDrawerStore?.state?.servicesShipment;

    const servicesChecked = this.mobxStore.additionalServicesDrawerStore?.state?.servicesShipmentChecked;

    const servicesFilter = services?.map((item) => {
      let newService = item;
      servicesChecked?.forEach((itemCheck) => {
        if (itemCheck.key === item.key) {
          newService = {
            ...item,
            isChecked: itemCheck.isChecked,
            countDocument: itemCheck.countDocument,
          };
        }
      });

      return newService;
    });

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesShipment(servicesFilter);

    new DrawersUseCase(this).closeDrawer();
  }

  public updateAdditionalServices = async () => {
    const { id: shipmentId } = shipmentR.selectors.shipment.getShipment(this.store.getState()) || {};
    const additionalServicesDrawerChecked = this.mobxStore?.additionalServicesDrawerStore?.getAddAdditionalServicesShipmentDrawerChecked;

    if (additionalServicesDrawerChecked) {
      const additionalServices: AdditionalServiceCheckedDTM[] = [];

      additionalServicesDrawerChecked.forEach((item) => {
        item.chargeCode.phases?.forEach((itemPhase) => {
          if (itemPhase === item.designation) {
            const newService = AdditionalServiceCheckedDTM.fromPlain({
              code: item.chargeCode.code,
              phase: itemPhase,
              quantity: item.countDocument ? Number(item.countDocument) : 1,
            });

            additionalServices.push(newService);
          }
        });
      });

      await shipmentR.services.shipmentAdditionalServices.postShipmentByIdAdditionalServices(`${shipmentId || ''}`, additionalServices);
    }
  }

  public onChangeCheckedAdditionalServiceShipmentDrawerItemById = (id?: string, isChangeCheckedState?: boolean) => async (value: boolean) => {
    const services = this.mobxStore.additionalServicesDrawerStore?.state?.servicesShipment;
    let servicesChecked = this.mobxStore.additionalServicesDrawerStore?.state?.servicesShipmentChecked;

    const newData = services?.map((item) => {
      if (item.customId === id) {
        return {
          ...item,
          isChecked: value,
        };
      }

      return item;
    });

    if (isChangeCheckedState) {
      servicesChecked = servicesChecked?.map((item) => {
        if (item.customId === id) {
          return {
            ...item,
            isChecked: value,
          };
        }

        return item;
      });

      this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesShipmentChecked(servicesChecked);
    }

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesShipment(newData);
  }

  public onChangeCountAdditionalServiceShipmentDrawerItemById = (id?: string) => async (value: string) => {
    const services = this.mobxStore.additionalServicesDrawerStore?.state?.servicesShipment;

    const newData = services?.map((item) => {
      if (item.customId === id) {
        return {
          ...item,
          countDocument: value.replace(/[^0-9]/g, '').slice(0, 2),
        };
      }

      return item;
    });

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesShipment(newData);
  }
}
