import { BaseController, controller } from 'proto/BaseController';
import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';
import { LoadingControlDTM } from 'shipment-operations/models/dtm';
import { EDrayageLoadingSide } from 'shipment-operations/constants';

@controller
export class DrawerController extends BaseController {
  public closeDrawer() {
    new DrawersUseCase(this).closeDrawer();
  }

  public closeDrawerById(id: string) {
    new DrawersUseCase(this).closeDrawerById(id);
  }

  public openRailBillingDrawer(shipmentId: string) {
    new DrawersUseCase(this).openRailBillingDrawer(shipmentId);
  }

  public openVGMTaskDrawer(shipmentId: string) {
    new DrawersUseCase(this).openVGMTaskDrawer(shipmentId);
  }

  public openEditSupplierScheduleDrawer(drayageSide: EDrayageLoadingSide, scheduleId?: number) {
    new DrawersUseCase(this).openEditSupplierScheduleDrawer(drayageSide, scheduleId);
  }

  public openDrayageLoadingControlsDrawer(loadingControls: LoadingControlDTM) {
    new DrawersUseCase(this).openDrayageLoadingControlsDrawer(loadingControls);
  }

  public openShipmentCostChangesDrawer(taskId: string) {
    new DrawersUseCase(this).openShipmentCostChangesDrawer(taskId);
  }

  public openSalesRepresentativeDrawer() {
    new DrawersUseCase(this).openSalesRepresentativeDrawer();
  }

  public openCreateContractDrawer() {
    new DrawersUseCase(this).openCreateContractDrawer();
  }

  public openEditContractDrawer() {
    new DrawersUseCase(this).openEditContractDrawer();
  }

  public openMaerskChanges() {
    new DrawersUseCase(this).openMaerskChanges();
  }

  public openBookingDrawer() {
    new DrawersUseCase(this).openBookingDrawer();
  }

  public openBookingDrayageDrawer(shipmentId?: string) {
    new DrawersUseCase(this).openBookingDrayageDrawer(shipmentId);
  }

  public openBookingConfirmation() {
    new DrawersUseCase(this).openBookingConfirmation();
  }

  public openShipmentMismatches() {
    new DrawersUseCase(this).openShipmentMismatches();
  }

  public openShipmentServicesDocumentUpload() {
    new DrawersUseCase(this).openShipmentServiceDocumentUpload();
  }

  public openShipmentChanges(withValidate = false) {
    new DrawersUseCase(this).openShipmentChanges(withValidate);
  }

  public openBLMismatches() {
    new DrawersUseCase(this).openBLMismatches();
  }

  public openAddRates(typeDrawer: string) {
    new DrawersUseCase(this).openAddRates(typeDrawer);
  }

  public openChargesCostChanges() {
    new DrawersUseCase(this).openChargesCostChangesDrawer();
  }
}
