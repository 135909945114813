import { DrawersUseCase } from 'app-wrapper/usecases/Drawers.useCase';
import { R as AuthR } from 'authentication/repository';
import { BaseController, controller } from 'proto/BaseController';
import { ShipmentTransportationExportInlandPickupOverviewStore } from 'shipment-operations/repository/mobxStores';
import { R } from 'shipment-operations/repository';

@controller
export class ShipmentTransportationExportInlandPickupOverviewController extends BaseController<ShipmentTransportationExportInlandPickupOverviewStore> {
  async onLoadedPage() {
    super.onLoadedPage();
    this.currentStore?.setLoading(true);

    this.mobxStore.shipmentLoadingControlsStore.clear();

    const [
      loadingControls,
      shipmentContainers,
      shipmentSchedules,
      paymentTerms,
      shippingParties,
    ] = await Promise.all([
      R.services.loadingControls.getLoadingControls(this.shipmentId()),
      R.services.shipmentContainers.getContainersList(this.shipmentId()),
      R.services.shipmentSchedulesService.getSchedules(this.shipmentId()),
      R.services.paymentTerms.getShipmentPaymentTerms(this.shipmentId()),
      R.services.shippingParties.getList(this.shipmentId()),
    ]);
    this.mobxStore.shipmentLoadingControlsStore.setItems(loadingControls);
    this.mobxStore.shipmentContainersStore.setItems(shipmentContainers);
    this.mobxStore.shipmentSchedulesStore.setItems(shipmentSchedules);
    this.mobxStore.shippingPartiesStore.setItems(shippingParties);

    const userData = AuthR.selectors.auth.getUser(this.store.getState());
    const shipmentPermissions = await this.repositories.shipmentPermissionsRepository.get(String(this.shipmentId()), paymentTerms, userData.isAdmin, true);

    if (!shipmentPermissions) {
      return;
    }

    this.dispatch(R.actions.shipment.setPermissions(shipmentPermissions));

    this.currentStore?.setLoading(false);
  }

  onClosePage() {
    super.onClosePage();
  }

  openEditContainers(id: number) {
    new DrawersUseCase(this).openEditShipmentTransportationExportInlandPickupOverviewContainerDrawer(id);
  }

  private shipmentId() {
    return (this.params as { shipmentId: string }).shipmentId;
  }
}
